<template>
    <div class="sidebar-menu">
        <ButtonLarge :variant="SECONDARY" :wide="true" class="open-btn" @click="open()">
            {{ $t('Menu') }}
        </ButtonLarge>
        <DialogWrapper v-if="isMobile" :show="isOpened">
            <DialogSide
                class="sidebar-menu-dialog"
                :show="isOpened"
                :label="$t('Menu')"
                :bottom-divider="false"
                @close="close()"
                @button-trailing-click="close()"
            >
                <template #icon-trailing>
                    <Close />
                </template>

                <div class="sidebar-dialog-content">
                    <slot />

                    <PWAInstallWrapper
                        #default="{
                            isOpen,
                            isInstallationPending,
                            install,
                            close: closePWAInstallBanner,
                        }"
                        class="pwa-install-banner"
                    >
                        <PWAInstallBanner
                            v-if="isOpen"
                            :is-installation-pending="isInstallationPending"
                            type="relative"
                            @install="install()"
                            @close="closePWAInstallBanner()"
                        />
                    </PWAInstallWrapper>
                </div>
            </DialogSide>
        </DialogWrapper>

        <slot v-if="!isMobile" />
    </div>
</template>

<script>
import { DialogSide } from '@modivo-ui/components/DialogSide/v1';
import { Close } from '@modivo-ui/icons/v2/navigation';
import { ButtonLarge, BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';

import PWAInstallWrapper from '@molecules/PWAInstallWrapper/PWAInstallWrapper';

import DialogWrapper from '@molecules/DialogWrapper/DialogWrapper';

export default {
    name: 'SidebarMenu',

    components: {
        DialogSide,
        Close,
        DialogWrapper,
        PWAInstallWrapper,
        ButtonLarge,
        PWAInstallBanner: () => ({
            component: import(
                /* webpackChunkName: "pwa-install" */
                '@molecules/PWAInstallBanner/PWAInstallBanner'
            ),
        }),
    },

    props: {
        isMobile: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            isOpened: false,
        };
    },

    watch: {
        isMobile(toValue, fromValue) {
            const changedFromMobileToDesktop = !!(fromValue && !toValue);

            if (changedFromMobileToDesktop && this.isOpened) {
                this.close();
            }
        },

        $route() {
            if (this.isOpened && this.isMobile) {
                this.close();
            }
        },
    },

    beforeCreate() {
        this.SECONDARY = BUTTON_LARGE_VARIANTS.SECONDARY;
    },

    methods: {
        close() {
            this.isOpened = false;
        },

        open() {
            this.isOpened = true;
        },
    },
};
</script>

<style lang="scss" scoped>
$modal-header-height: #{theme('customVariables.sideModal.headerHeight')};

.sidebar-menu {
    .sidebar-menu-dialog {
        :deep(.scrim),
        :deep(.dialog) {
            @apply z-ui-2;
        }
    }

    .sidebar-dialog-content {
        @apply p-ui-4;
    }

    .pwa-install-banner {
        @apply mt-3;
    }

    .open-btn {
        @apply mb-4;
    }

    &:deep(.panel-header) {
        @apply flex items-center w-full p-3 border-border border-b-1;
        height: $modal-header-height;

        .label {
            @apply w-full;
        }
    }
}

@screen lg {
    .sidebar-menu {
        .pwa-install-banner {
            @apply hidden;
        }

        &:deep(.open-btn) {
            @apply hidden;
        }

        &:deep(.panel-header) {
            @apply hidden;
        }
    }
}
</style>

import globalService from './modules/global';
import navigationService from './modules/navigation';
import cmsService from './modules/cms';
import productService from './modules/product';
import sellersService from './modules/sellers';
import cartService from './modules/cart-bff';
import customerService from './modules/customer';
import cmsBlocksService from './modules/cms-blocks';
import searchService from './modules/search';
import checkoutService from './modules/checkout';
import gdprService from './modules/gdpr';
import recommendationsService from './modules/recommendations';
import orderService from './modules/order';
import ordersService from './modules/orders';
import packageTrackingService from './modules/package-tracking';
import contactService from './modules/contact';
import newsletterService from './modules/newsletter';
import newsletterSmsService from './modules/newsletter-sms';
import validateService from './modules/validate';
import loyaltyClubService from './modules/loyalty-club';
import modivoClubService from './modules/modivo-club';
import consentsService from './modules/consents';
import authService from './modules/auth';
import avatarService from './modules/avatar';
import pocSearchService from './modules/search-service';
import paymentsApplePayService from './modules/payments/applePay';
import paymentsKlarnaService from './modules/payments/klarna';
import paymentsPayPalService from './modules/payments/payPal';
import paymentsPayuService from './modules/payments/payu';
import prePaidService from './modules/pre-paid';

export default (
    $errorHandler,
    megatronClient,
    httpClient,
    $configProvider,
    helpers,
    normalizeMegatronResponse,
    normalizePrePaidResponse
) => ({
    global: globalService(megatronClient),
    navigation: navigationService(megatronClient),
    cms: cmsService(megatronClient),
    product: productService({
        megatronClient,
        httpClient,
        normalizeMegatronResponse,
        $errorHandler,
        helpers,
        $configProvider,
    }),
    sellers: sellersService({
        megatronClient,
        httpClient,
        normalizeMegatronResponse,
        $errorHandler,
        helpers,
    }),
    cart: cartService(megatronClient),
    customer: customerService({
        megatronClient,
        normalizeMegatronResponse,
        $errorHandler,
        helpers,
    }),
    cmsBlocks: cmsBlocksService(megatronClient),
    search: searchService({
        megatronClient,
        httpClient,
        normalizeMegatronResponse,
        $errorHandler,
        $configProvider,
        helpers,
    }),
    checkout: checkoutService({
        megatronClient,
        normalizeMegatronResponse,
        $errorHandler,
    }),
    gdpr: gdprService(megatronClient, $errorHandler),
    order: orderService({ megatronClient, $errorHandler }),
    orders: ordersService({ megatronClient, $errorHandler }),
    packageTracking: packageTrackingService({ megatronClient, $errorHandler }),
    contact: contactService(megatronClient, $errorHandler),
    newsletter: newsletterService({ megatronClient, $errorHandler }),
    newsletterSms: newsletterSmsService({ megatronClient, $errorHandler }),
    validate: validateService(megatronClient),
    recommendations: recommendationsService({ megatronClient, $errorHandler, helpers }),
    loyaltyClub: loyaltyClubService(megatronClient),
    modivoClub: modivoClubService(httpClient),
    prePaid: prePaidService(httpClient, { normalizePrePaidResponse }),
    consents: consentsService(megatronClient),
    auth: authService(httpClient),
    avatar: avatarService(httpClient),
    searchService: pocSearchService({ httpClient, $errorHandler, $configProvider, helpers }),
    applePay: paymentsApplePayService(megatronClient, $errorHandler),
    klarna: paymentsKlarnaService(megatronClient, $errorHandler),
    payPal: paymentsPayPalService(megatronClient),
    payu: paymentsPayuService(megatronClient),
});

<template>
    <div class="footer-links-mobile">
        <CellFilterDetails
            :label="heading"
            :divider="true"
            class="cell-filter-wrapper"
            @click="changeState()"
        >
            <template #button>
                <ButtonIcon :variant="BUTTON_VARIANT">
                    <Icon :icon="!isExpand ? ChevronDown : ChevronUp" />
                </ButtonIcon>
            </template>
        </CellFilterDetails>

        <transition name="expand">
            <div
                class="content"
                :class="{
                    'is-expand': isExpand,
                }"
            >
                <ul class="links">
                    <slot />
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
import { CellFilterDetails } from '@modivo-ui/components/CellFilterDetails/v2';
import { Icon } from '@modivo-ui/components/Icon/v1';
import { ButtonIcon, BUTTON_ICON_VARIANTS } from '@modivo-ui/components/ButtonIcon/v1';

import { ChevronDown, ChevronUp } from '@modivo-ui/icons/v2/navigation';

export default {
    name: 'FooterLinksMobile',

    components: {
        CellFilterDetails,
        Icon,
        ButtonIcon,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        isExpandDefault: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isExpand: false,
        };
    },

    created() {
        this.isExpand = this.isExpandDefault;
    },

    beforeCreate() {
        this.ChevronDown = ChevronDown;
        this.ChevronUp = ChevronUp;
        this.BUTTON_VARIANT = BUTTON_ICON_VARIANTS.TERTIARY;
    },

    methods: {
        changeState() {
            this.isExpand = !this.isExpand;
            this.$emit('expand-change', this.isExpand);
        },
    },
};
</script>

<style lang="scss" scoped>
.footer-links-mobile {
    @apply flex flex-col w-auto;
    @apply bg-ds-container-secondary;

    .content {
        @apply hidden;

        &.is-expand {
            @apply block;
        }
    }

    .links {
        @apply flex flex-col;
    }

    &:deep(.cell-filter-details) {
        @apply bg-ds-container-secondary;
    }
}

.expand-enter-active,
.expand-leave-active {
    transition: auto 1s ease-in-out;
    overflow: hidden;
}

.expand-enter,
.expand-leave-to {
    height: 0;
}
</style>

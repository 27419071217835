import { GUEST_CART_ID_KEY, USER_CART_ID_KEY } from '@configs/storage';

import { MODULE_NAME as CHECKOUT_MODULE_NAME } from '@analytics-modules/checkout/meta';
import {
    CART_ITEM_ADD,
    CART_ITEM_REMOVE,
    REMOVE_FROM_CART,
} from '@analytics-modules/checkout/types/Events';
import { DEFAULT_LOCALE } from '@analytics-types/Analytics';

import {
    USER_BAD_INPUT,
    NO_SUCH_ENTITY,
    FORBIDDEN,
    PRODUCT_IS_OUT_OF_STOCK,
    PRODUCT_IS_LOW_STOCK,
} from '@types/GraphQLErrorCodes';
import { ERROR_ACTION_TAG_NAME } from '@types/Errors';
import { REMOVE_ITEM_FROM_CART } from '@types/CheckoutCartMetrics';

import DataLayerProduct from '@models/Analytics/DataLayerProduct';

import { isGiftCardTestEnabled } from '@cherokee-assets/gift-card-ab-test';
import { isPartnerStore, transformCartItemConfigurableOptions } from '@assets/cart';
import {
    startPerformanceMeasurement,
    finishPerformanceMeasurement,
    getPerformanceMeasurement,
} from '@assets/performance';
import { buildRemoveFromCartPayload } from '@assets/analytics-checkout-event';

import { WISHLIST_PAGE_SIZE } from '@configs/wishlist';

import { BAD_REQUEST } from '@cherokee-config/gift-card-errors';
import { types } from './mutations';
import { MODULE_NAME } from './meta';

const ADD_TO_CART_ERROR = 'Add to cart error.';

export const LOW_STOCK_ERROR = 'Low stock error.';
export const OUT_OF_STOCK_ERROR = 'Out of stock error.';

const emitCartEvent = (
    eventName,
    {
        app,
        rootGetters,
        cartItem,
        quantity,
        atcMultiOffer,
        fitAnalytics,
        pageType = '',
        actionFieldList = '',
    },
    elapsedTimeMs
) => {
    const currency = rootGetters['config/currency'];
    const totalCountGetter = rootGetters['product/getProductReviewTotalCount'];
    const averageRating = rootGetters['product/getProductReviewAverageRating'];

    const {
        product,
        price: { promotional: { amount: promotionalPrice } = {} } = {},
        prices: { final_item_price: finalItemPrice } = {},
        sku,
        skuVariant,
        offer_id: offerId,
    } = cartItem;

    const discountedPrice = promotionalPrice || finalItemPrice;

    const productReviewTotalCount = totalCountGetter(sku);

    app.$analytics.moduleEmit(CHECKOUT_MODULE_NAME, eventName, {
        currency,
        elapsedTimeMs,
        product: new DataLayerProduct({
            product,
            reviews: {
                totalCountAll: productReviewTotalCount,
                averageRatings: [{ value: averageRating }],
            },
            quantity,
            variantId: skuVariant,
            discountedPrice,
            offerId,
        }).build(),
        fitAnalytics,
        atcMultiOffer,
        pageType,
        actionFieldList,
    });
};

const emitAddToCartEvent = ({
    app,
    rootGetters,
    addToCartData,
    atcMultiOffer,
    pageType,
    actionFieldList,
    fitAnalytics,
}) => {
    const {
        data: { quantity, sku, offer_id: offerId },
    } = addToCartData;

    const cartItem = rootGetters['cart/cartItems'].find(
        item => item.skuVariant === sku && item.offer_id === offerId
    );

    emitCartEvent(CART_ITEM_ADD, {
        app,
        rootGetters,
        cartItem,
        quantity,
        atcMultiOffer,
        pageType,
        actionFieldList,
        fitAnalytics,
    });
};

const emitRemoveFromCartEvent = ({
    app,
    rootGetters,
    oldStoreCarts,
    cartItemIdString,
    qty = 0,
    elapsedTimeMs,
}) => {
    const cartItem = oldStoreCarts
        .flatMap(({ items }) => items)
        .find(item => item.id === cartItemIdString);

    const { quantity } = cartItem;

    emitCartEvent(
        CART_ITEM_REMOVE,
        {
            app,
            rootGetters,
            cartItem,
            quantity: qty || quantity,
        },
        elapsedTimeMs
    );
};

const emitRemoveFromCartGA4Event = ({
    app,
    state,
    rootGetters,
    oldStoreCarts,
    cartItemIdString,
    quantity = 0,
    elapsedTimeMs,
}) => {
    const currency = rootGetters['config/currency'];
    const totalCountGetter = rootGetters['product/getProductReviewTotalCount'];
    const selectedPaymentMethodCode = rootGetters['checkout/selectedPadPaymentMethod']?.code;

    const cartItem = oldStoreCarts
        .flatMap(({ items }) => items)
        .find(item => item.id === cartItemIdString);

    const {
        context: { route },
    } = app;

    const payload = buildRemoveFromCartPayload({
        currency,
        carts: oldStoreCarts,
        cartItem,
        quantity,
        productsReviewsTotalCount: totalCountGetter(cartItem.product.sku),
        elapsedTimeMs,
        selectedPaymentType: state.selectedPaymentType,
        selectedPaymentMethodCode,
        route,
    });

    app.$analytics.moduleEmit(CHECKOUT_MODULE_NAME, REMOVE_FROM_CART, payload);
};

export default {
    setCartId({ rootGetters, commit }, cartId) {
        const storageCartKey = rootGetters['customer/isLoggedIn']
            ? USER_CART_ID_KEY
            : GUEST_CART_ID_KEY;

        this.app.$storage.setItem(storageCartKey, {
            id: cartId,
        });

        commit(types.SET_CART_ID, cartId);
    },

    removeCartId({ commit }) {
        this.app.$storage.removeItem(GUEST_CART_ID_KEY);
        this.app.$storage.removeItem(USER_CART_ID_KEY);

        commit(types.SET_CART_ID, null);
    },

    setComplexDataProductItems({ commit }, complexProductDataItems) {
        commit(types.SET_COMPLEX_DATA_PRODUCT_ITEMS, complexProductDataItems);
    },

    removeComplexDataProductItems({ commit }, skusToRemove) {
        commit(types.REMOVE_COMPLEX_DATA_PRODUCT_ITEMS, skusToRemove);
    },

    async setCartData({ commit, dispatch, rootGetters, getters }, cartData) {
        const { pad, ...newCartData } = cartData || {};

        const { extCart } = newCartData;

        if (extCart) {
            try {
                const locale = rootGetters['config/locale'];
                const currency = rootGetters['config/currency'];
                const cartItems = extCart.store_carts.flatMap(({ items }) => items);

                const { selected_payment_method: selectedPaymentMethod } = extCart;

                if (selectedPaymentMethod) {
                    newCartData.selected_payment_method = selectedPaymentMethod;
                }

                const indexesToRemove = Object.keys(getters.complexProductDataItemsSkusMap).filter(
                    sku => !cartItems.some(({ product }) => product.sku === sku)
                );

                if (indexesToRemove.length) {
                    dispatch('removeComplexDataProductItems', indexesToRemove);
                }

                const allCartIndexes = cartItems.map(({ product: { sku } }) => sku);

                const indexesToFetch = allCartIndexes.filter(cartSku => {
                    const productComplexDataExists =
                        getters.complexProductDataItemsSkusMap[cartSku];

                    return !productComplexDataExists;
                });

                const needFetchComplexData = !!indexesToFetch.length;

                if (needFetchComplexData) {
                    const products = await this.app.$services.product.getByIndexes({
                        indexes: indexesToFetch,
                        limit: indexesToFetch.length,
                        locale,
                        selectLocales: [DEFAULT_LOCALE],
                        currency,
                    });

                    if (products?.items) {
                        dispatch('setComplexDataProductItems', products.items);
                    }
                }

                const productsDetailsNotFoundInSearch = [];

                // eslint-disable-next-line max-len
                newCartData.extCart.store_carts = cartData.extCart.store_carts.map(store => ({
                    ...store,
                    items: store.items.map(cartItemDetails => {
                        const productDetails =
                            getters.complexProductDataItemsSkusMap[cartItemDetails.product.sku] ||
                            null;

                        if (!productDetails) {
                            productsDetailsNotFoundInSearch.push(cartItemDetails.product.sku);
                        }

                        const { chosenSize } = transformCartItemConfigurableOptions(
                            cartItemDetails
                        );

                        return {
                            ...cartItemDetails,
                            chosenSize,
                            product: {
                                ...cartItemDetails.product,
                                ...productDetails,
                            },
                        };
                    }),
                }));

                if (productsDetailsNotFoundInSearch.length) {
                    this.app.$errorHandler.captureStoreError(
                        MODULE_NAME,
                        new Error('Products details not found in search'),
                        {
                            [ERROR_ACTION_TAG_NAME]: 'cart.productsDetailsNotFoundInSearch',
                        },
                        { products: productsDetailsNotFoundInSearch }
                    );
                }
            } catch (err) {
                this.app.$errorHandler.captureStoreError(MODULE_NAME, err, {
                    [ERROR_ACTION_TAG_NAME]: 'product.getByIndexes',
                });
            }
        }

        if (pad) {
            commit(types.SET_PAD_DATA, pad);
        }

        await dispatch('updateCartData', newCartData);

        const isGiftCardEnabled = isGiftCardTestEnabled({
            $abTests: this.app.$abTests,
            locale: rootGetters['config/locale'],
        });

        if (isGiftCardEnabled) {
            await dispatch('getGiftCards');
            await dispatch('handleGiftCardsOnCartDataSet', newCartData);
        }
    },

    async getGiftCards({ dispatch, getters, rootGetters }) {
        await dispatch('getAssignedGiftCards');

        const isModivoClubGoldMember = rootGetters['modivoClub/isModivoClubGoldMember'];

        if (isModivoClubGoldMember) {
            const isCashbackAvailable = rootGetters['modivoClub/isCashbackAvailable'];
            const isCashbackAssignedToCart = !!getters.cashbackCard;

            if (isCashbackAvailable && !isCashbackAssignedToCart) {
                await dispatch(
                    'assignCashbackToCart',
                    rootGetters['modivoClub/cashbackTotalFundsAmount']
                );
            }
        }
    },

    async assignCashbackToCart(
        { state, getters, rootGetters, rootState, dispatch },
        amountToCover
    ) {
        const { cartId } = state;
        const { cardNumber, balance } = rootState.modivoClub.cashbackCardData || {};

        if (!cardNumber || !balance) {
            return;
        }

        try {
            const { data, error, status } = await this.app.$services.prePaid.assignPrePaidToCart({
                cartId,
                cardNumber,
                isCashback: true,
                amountToCover,
                balanceTotal: balance,
                orderAmount: {
                    amount: getters.grandTotalNumber,
                    currency: rootGetters['config/currency'],
                },
            });

            if (!error) {
                const { prePaidCards, extraPaymentMethodId } = data;

                dispatch('setGiftCards', prePaidCards);
                dispatch('setAdditionalPaymentMethodId', extraPaymentMethodId);

                return { data };
            }

            if (status === BAD_REQUEST) {
                dispatch(
                    'messages/addErrorMessage',
                    {
                        text: this.app.i18n.tModivoClub('Adding cashback to cart failed'),
                    },
                    { root: true }
                );

                return;
            }
        } catch (error) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                error,
                {
                    [ERROR_ACTION_TAG_NAME]: 'cart.assignWalletToCard',
                },
                { cartId, cardNumber }
            );
        }
    },

    updateCartData({ commit }, cartData) {
        commit(types.SET_CART_DATA, cartData);
        commit(types.SET_IS_CART_DATA_VALID, true);
        commit(types.SET_IS_CART_DATA_LOADING, false);
    },

    async clearCart({ dispatch }) {
        await Promise.all([dispatch('removeCartId'), dispatch('updateCartData', null)]);
    },

    async handleCustomerCart({ state, dispatch }) {
        const guestCartId = state.cartId;

        const isEmptyCartCreated = await dispatch('createEmptyCart');

        if (!isEmptyCartCreated) {
            return;
        }

        const userCartId = state.cartId;

        if (guestCartId !== null && userCartId !== guestCartId) {
            await dispatch('mergeGuestCartWithCustomerCart', guestCartId);
        } else {
            await dispatch('getCart', {
                cartId: state.cartId,
            });
        }
    },

    async mergeGuestCartWithCustomerCart({ state, dispatch }, guestCartId) {
        const { cartId: customerCartId } = state;

        try {
            await dispatch(
                'setCartData',
                await this.app.$services.cart.mergeGuestCartWithCustomerCart(
                    guestCartId,
                    customerCartId
                )
            );
        } catch (err) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'cart.mergeGuestCartWithCustomerCart',
                },
                {
                    guestCartId,
                    customerCartId,
                }
            );

            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t('Merging cart failed'),
                },
                { root: true }
            );
        }
    },

    async getCart({ state, dispatch }, { cartId, withPaymentAndShippingMethods = false }) {
        try {
            const cartData = await this.app.$services.cart.getCart({
                cartId,
                withPaymentAndShippingMethods,
            });

            if (!cartData) {
                const isEmptyCartCreated = await dispatch('createEmptyCart');

                if (isEmptyCartCreated) {
                    const { cartId: newCartId } = state;
                    const newCartData = await this.app.$services.cart.getCart({
                        cartId: newCartId,
                        withPaymentAndShippingMethods,
                    });

                    if (!newCartData) {
                        throw new Error('Error fetching new cart.');
                    } else {
                        await dispatch('setCartData', newCartData);
                    }
                }
            } else {
                await dispatch('setCartData', cartData);
            }
        } catch (err) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'cart.getCart',
                },
                {
                    cartId,
                    withPaymentAndShippingMethods,
                }
            );

            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t('Fetching cart failed'),
                },
                { root: true }
            );
        }
    },

    syncCartId({ rootGetters, commit, state }) {
        const { cartId: stateCartId } = state;
        const storageCartKey = rootGetters['customer/isLoggedIn']
            ? USER_CART_ID_KEY
            : GUEST_CART_ID_KEY;
        const { id: storageCartId = null } = this.$storage.getItem(storageCartKey) || {};

        try {
            if (storageCartId && (!stateCartId || stateCartId !== storageCartId)) {
                commit(types.SET_CART_ID, storageCartId);
            }
        } catch (err) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'cart.syncCartId',
                },
                {
                    storageCartId,
                    stateCartId,
                }
            );
        }
    },

    async createEmptyCart({ commit, dispatch }) {
        try {
            const emptyCartId = await this.app.$services.cart.createEmptyCart();

            dispatch('setCartId', emptyCartId);

            commit(types.SET_IS_CART_DATA_LOADING, false);

            return true;
        } catch (err) {
            this.app.$errorHandler.captureStoreError(MODULE_NAME, err, {
                [ERROR_ACTION_TAG_NAME]: 'cart.createEmptyCart',
            });

            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t('Creating empty cart error occurred'),
                },
                { root: true }
            );

            return false;
        }
    },

    async addProductToCart(
        { rootGetters, dispatch },
        {
            productName,
            addToCartData,
            chosenSize,
            atcMultiOffer,
            pageType,
            actionFieldList,
            fitAnalytics,
        }
    ) {
        const isLoggedIn = rootGetters['customer/isLoggedIn'];
        const storageCartKey = isLoggedIn ? USER_CART_ID_KEY : GUEST_CART_ID_KEY;
        const cart = this.$storage.getItem(storageCartKey);

        if (!cart) {
            const isEmptyCartCreated = await dispatch('createEmptyCart');

            if (!isEmptyCartCreated) {
                return;
            }
        }

        const {
            data: { sku },
            parent_sku: parentSku,
        } = addToCartData;

        try {
            const result = await dispatch('addMarketplaceProductToCart', addToCartData);

            if (result) {
                emitAddToCartEvent({
                    app: this.app,
                    rootGetters,
                    addToCartData,
                    atcMultiOffer,
                    pageType,
                    actionFieldList,
                    fitAnalytics,
                });

                if (!isLoggedIn) {
                    dispatch('customer/setInitLoginTooltip', true, {
                        root: true,
                    });
                }
            }

            return sku;
        } catch (err) {
            const { message: errorMessage } = err;

            if (![ADD_TO_CART_ERROR, OUT_OF_STOCK_ERROR, LOW_STOCK_ERROR].includes(errorMessage)) {
                this.app.$errorHandler.captureStoreError(
                    MODULE_NAME,
                    err,
                    {
                        [ERROR_ACTION_TAG_NAME]: 'addToCart',
                    },
                    {
                        addToCartData,
                        chosenSize,
                    }
                );
            }

            if (![OUT_OF_STOCK_ERROR, LOW_STOCK_ERROR].includes(errorMessage)) {
                dispatch(
                    'messages/addErrorMessage',
                    {
                        text: this.app.i18n.t('Adding {productName} to cart failed', {
                            productName,
                        }),
                    },
                    { root: true }
                );
            } else {
                dispatch(
                    'modal/openAddProductToCartErrorModal',
                    {
                        error: errorMessage,
                        sku: parentSku,
                    },
                    {
                        root: true,
                    }
                );
            }
        }

        return false;
    },

    async addMarketplaceProductToCart({ state, dispatch }, addToCartData) {
        dispatch('syncCartId');

        let result = null;

        try {
            result = await this.app.$services.cart.addMarketplaceProductsToCart({
                cartId: state.cartId,
                cartItems: [addToCartData],
            });
        } catch (error) {
            const errorCode = this.app.$graphqlErrorsHelper.getErrorCodes(error);

            if (errorCode === FORBIDDEN) {
                await dispatch('customer/handleExpiredSession', null, {
                    root: true,
                });

                return false;
            }

            if (errorCode === PRODUCT_IS_OUT_OF_STOCK) {
                throw new Error(OUT_OF_STOCK_ERROR);
            } else if (errorCode === PRODUCT_IS_LOW_STOCK) {
                throw new Error(LOW_STOCK_ERROR);
            }
        }

        if (result === null) {
            throw new Error(ADD_TO_CART_ERROR);
        }

        const { cart: cartData = null } = result;

        await dispatch('setCartData', cartData);

        return true;
    },

    async removeItemFromCart({ rootGetters, state, getters, dispatch }, cartItemIdString) {
        startPerformanceMeasurement(REMOVE_ITEM_FROM_CART);
        const { app } = this;
        const { cartId } = state;
        const oldStoreCarts = [...getters.carts];

        try {
            const cartItemId = Number.parseInt(cartItemIdString, 10);

            const { cart: cartData } = await app.$services.cart.removeItemFromCart({
                cartId,
                cartItemId,
            });

            await dispatch('setCartData', cartData);

            dispatch(
                'messages/addSuccessMessage',
                {
                    text: app.i18n.t('Product removed successfully from cart'),
                },
                { root: true }
            );

            finishPerformanceMeasurement(REMOVE_ITEM_FROM_CART);

            const elapsedTimeMs = getPerformanceMeasurement(REMOVE_ITEM_FROM_CART);

            emitRemoveFromCartEvent({
                app,
                rootGetters,
                oldStoreCarts,
                cartItemIdString,
                elapsedTimeMs,
            });

            emitRemoveFromCartGA4Event({
                app,
                state,
                rootGetters,
                oldStoreCarts,
                cartItemIdString,
                elapsedTimeMs,
            });
        } catch (err) {
            const errorCode = app.$graphqlErrorsHelper.getErrorCodes(err);

            if (errorCode === NO_SUCH_ENTITY) {
                try {
                    dispatch('getCart', { cartId });

                    dispatch(
                        'messages/addSuccessMessage',
                        {
                            text: app.i18n.t('Product removed successfully from cart'),
                        },
                        { root: true }
                    );

                    return true;
                } catch (error) {
                    throw error.message;
                }
            } else if (errorCode === FORBIDDEN) {
                await dispatch('customer/handleExpiredSession', null, {
                    root: true,
                });

                return false;
            }

            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'cart.removeItemFromCart',
                },
                {
                    cartItemIdString,
                }
            );

            dispatch(
                'messages/addErrorMessage',
                {
                    text: app.i18n.t('Removing item from cart error occurred'),
                },
                { root: true }
            );
        }
    },

    async updateCartItem({ rootGetters, getters, state, dispatch }, { id, qty }) {
        const { app } = this;
        const oldStoreCarts = [...getters.carts];

        try {
            const cartItemId = Number.parseInt(id, 10);
            const cartItem = getters.cartItems.find(item => item.id === id);

            const {
                skuVariant,
                product: { sku },
                quantity: oldQuantity,
            } = cartItem;

            if (qty < oldQuantity) {
                startPerformanceMeasurement(REMOVE_ITEM_FROM_CART);
            }

            const cartData = await this.app.$services.cart.updateCartItem({
                cartId: state.cartId,
                cartItems: [{ cart_item_id: cartItemId, quantity: qty }],
            });

            await dispatch('setCartData', cartData);

            dispatch(
                'messages/addSuccessMessage',
                {
                    text: this.app.i18n.t('Product successfully updated'),
                },
                { root: true }
            );

            const quantityDiff = Math.abs(qty - oldQuantity);

            if (qty > oldQuantity) {
                const addToCartData = {
                    parent_sku: sku,
                    data: {
                        sku: skuVariant,
                        quantity: quantityDiff,
                        offer_id: cartItem.offer_id,
                    },
                };

                emitAddToCartEvent({
                    app,
                    rootGetters,
                    addToCartData,
                });
            } else if (qty < oldQuantity) {
                finishPerformanceMeasurement(REMOVE_ITEM_FROM_CART);

                const elapsedTimeMs = getPerformanceMeasurement(REMOVE_ITEM_FROM_CART);

                emitRemoveFromCartEvent({
                    app,
                    rootGetters,
                    oldStoreCarts,
                    cartItemIdString: id,
                    qty: quantityDiff,
                });

                emitRemoveFromCartGA4Event({
                    app,
                    state,
                    rootGetters,
                    oldStoreCarts,
                    cartItemIdString: id,
                    quantity: quantityDiff,
                    elapsedTimeMs,
                });
            }
        } catch (err) {
            const errorCode = this.app.$graphqlErrorsHelper.getErrorCodes(err);

            if (errorCode === FORBIDDEN) {
                await dispatch('customer/handleExpiredSession', null, {
                    root: true,
                });

                return;
            }

            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'cart.updateCartItem',
                },
                {
                    id,
                    qty,
                }
            );

            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t('Updating product in cart error occured'),
                },
                { root: true }
            );
        }
    },

    async changeCartItem(
        { rootGetters, state, getters, dispatch },
        { id, parentSku, sku, qty, chosenSize: newChosenSize, sellerId }
    ) {
        const { app } = this;
        let latestCartData = { ...state.cartData };
        const oldStoreCarts = [...getters.carts];

        try {
            const cartItemId = Number.parseInt(id, 10);

            const { cart } = await this.app.$services.cart.removeItemFromCart({
                cartId: state.cartId,
                cartItemId,
            });

            emitRemoveFromCartEvent({
                app,
                rootGetters,
                oldStoreCarts,
                cartItemIdString: id,
            });

            emitRemoveFromCartGA4Event({
                app,
                state,
                rootGetters,
                oldStoreCarts,
                cartItemIdString: id,
            });

            latestCartData = cart;
        } catch (err) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'cart.removeItemFromCart',
                },
                {
                    id,
                    parentSku,
                    sku,
                    qty,
                    newChosenSize,
                    sellerId,
                }
            );
        }

        const addToCartData = {
            parent_sku: parentSku,
            data: {
                sku,
                quantity: qty,
                offer_id: newChosenSize.offerId,
                store_id: sellerId,
            },
        };

        try {
            await dispatch('addMarketplaceProductToCart', addToCartData);

            dispatch(
                'messages/addSuccessMessage',
                {
                    text: this.app.i18n.t('Product successfully updated'),
                },
                { root: true }
            );

            emitAddToCartEvent({
                app,
                rootGetters,
                addToCartData,
            });
        } catch (err) {
            if (err.message !== ADD_TO_CART_ERROR) {
                this.app.$errorHandler.captureStoreError(
                    MODULE_NAME,
                    err,
                    {
                        [ERROR_ACTION_TAG_NAME]: 'addMarketplaceProductToCart',
                    },
                    {
                        addToCartData,
                    }
                );
            }

            await dispatch('setCartData', latestCartData);

            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t('Updating product in cart error occured'),
                },
                { root: true }
            );
        }
    },

    showLoader({ commit }, text = '') {
        commit(types.ADD_LOADER, text);
    },

    hideLoader({ commit }) {
        commit(types.REMOVE_LOADER);
    },

    clearLoader({ commit }) {
        commit(types.CLEAR_LOADER);
    },

    async applyCouponToCartWithCouponInfo({ state, dispatch }, couponCode) {
        try {
            const appliedCouponData = await this.app.$services.cart.applyCouponToCartWithCouponInfo(
                state.cartId,
                couponCode
            );

            if (appliedCouponData?.cart) {
                await dispatch('setCartData', appliedCouponData.cart);

                return true;
            }

            if (appliedCouponData?.coupon_application_info) {
                await dispatch('setInvalidCouponData', appliedCouponData.coupon_application_info);
            }
        } catch (err) {
            const errorCode = this.app.$graphqlErrorsHelper.getErrorCodes(err);

            if (errorCode === USER_BAD_INPUT) {
                dispatch(
                    'messages/addErrorMessage',
                    {
                        text: this.app.i18n.t(
                            'The coupon code is not valid. Verify the code and try again'
                        ),
                    },
                    { root: true }
                );

                return false;
            }

            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'cart.applyCouponToCartWithCouponInfo',
                },
                {
                    couponCode,
                }
            );

            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t('Setting coupon code on cart error occurred'),
                },
                { root: true }
            );
        }

        return false;
    },

    async setInvalidCouponData({ commit }, info) {
        const { message, coupon_info: couponInfo } = info;
        const {
            already_used: alreadyUsed,
            expiration_date: expirationDate,
            rule_conditions: ruleConditions,
            general_rejection_reason: generalRejectionInfo,
        } = couponInfo;

        const invalidCouponData = {
            message,
            alreadyUsed,
            expirationDate,
            ruleConditions,
            generalRejectionInfo,
        };

        commit(types.SET_INVALID_COUPON_DATA, invalidCouponData);
    },

    removeInvalidCouponData({ commit }) {
        commit(types.SET_INVALID_COUPON_DATA, {});
    },

    async removeCouponFromCart({ state, dispatch }) {
        try {
            const cartData = await this.app.$services.cart.removeCouponFromCart(state.cartId);

            await dispatch('setCartData', cartData);

            return true;
        } catch (err) {
            this.app.$errorHandler.captureStoreError(MODULE_NAME, err, {
                [ERROR_ACTION_TAG_NAME]: 'cart.removeCouponFromCart',
            });

            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t('Removing coupon code from cart error occurred'),
                },
                { root: true }
            );
        }

        return false;
    },

    addToCartPending({ commit }, isAddToCartPending) {
        commit(types.SET_IS_ADD_TO_CART_PENDING, isAddToCartPending);
    },

    async addToCartAsync(
        { dispatch, getters, state },
        {
            product,
            chosenSize,
            chosenSizeLabel,
            offerId,
            pageType,
            actionFieldList,
            atcMultiOffer = false,
            fitAnalytics = false,
        }
    ) {
        let success = false;

        dispatch('addToCartPending', true);

        if (!getters.complexProductDataItemsSkusMap[product.id]) {
            dispatch('setComplexDataProductItems', [...state.complexProductDataItems, product]);
        }

        try {
            const { name: productName, variants, sku } = product;
            const chosenVariant = variants[chosenSize];
            const offer = offerId ? chosenVariant?.offers.find(({ id }) => id === offerId) : null;
            const { id: sellerId, internal: isInternalOffer } = offer?.store || {};

            const addToCartData = {
                parent_sku: sku,
                data: {
                    sku: chosenVariant.id,
                    quantity: 1,
                    offer_id: offerId,
                    store_id: sellerId,
                },
            };

            const addedSku = await dispatch('addProductToCart', {
                productName,
                addToCartData,
                chosenSize,
                atcMultiOffer,
                pageType,
                actionFieldList,
                fitAnalytics,
            });

            if (addedSku) {
                success = true;

                const addedCartItem = getters.transformedCartItems.find(
                    ({ skuVariant, offerId: transformedOfferId }) =>
                        skuVariant === addedSku &&
                        (!transformedOfferId || !offerId || transformedOfferId === offerId)
                );

                if (addedCartItem) {
                    addedCartItem.quantity = addToCartData.data.quantity;

                    let sellerName = null;

                    if (isInternalOffer === false) {
                        const seller = await this.app.$services.sellers.getById(sellerId);

                        sellerName = seller?.name;
                    }

                    dispatch(
                        'modal/openAddProductToCartModal',
                        {
                            addedCartItem,
                            offerId,
                            sellerName,
                            isInternal: isInternalOffer,
                        },
                        {
                            root: true,
                        }
                    );
                }
            }
        } catch (err) {
            this.app.$errorHandler.captureError(
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'addToCart',
                },
                {
                    product,
                    chosenSize,
                    chosenSizeLabel,
                }
            );
        }

        dispatch('addToCartPending', false);

        return success;
    },

    async getWishlistProducts(
        { rootGetters, commit, dispatch },
        { pageToLoad = 1, pageSize = WISHLIST_PAGE_SIZE }
    ) {
        const isLoggedIn = rootGetters['customer/isLoggedIn'];
        const locale = rootGetters['config/locale'];
        const currency = rootGetters['config/currency'];
        let wishlist = [];

        if (isLoggedIn) {
            try {
                const { customerWishlist = [] } =
                    (await this.$services.customer.getCustomerWishlist(pageSize, pageToLoad)) || {};

                customerWishlist.sort(
                    ({ added_at: addedAt1 }, { added_at: addedAt2 }) =>
                        new Date(addedAt2) - new Date(addedAt1)
                );

                wishlist = customerWishlist.map(item => item.product.sku);

                dispatch('customer/setWishlist', wishlist, {
                    root: true,
                });
            } catch (err) {
                this.$errorHandler.captureError(err, {
                    [ERROR_ACTION_TAG_NAME]: 'loadWishlistSkus',
                });
            }
        } else {
            const wishlistFromLocalStorage = this.app.$localWishlist.get();

            wishlist = wishlistFromLocalStorage.slice(
                (pageToLoad - 1) * pageSize,
                pageToLoad * pageSize
            );
        }

        if (wishlist.length) {
            const { items = [] } = await this.$services.product.getByIndexes({
                indexes: wishlist,
                limit: WISHLIST_PAGE_SIZE,
                page: 1,
                locale,
                selectLocales: [DEFAULT_LOCALE],
                currency,
            });

            commit(types.SET_WISHLIST_PRODUCTS, items);
        }
    },

    clearWishlistProducts({ commit }) {
        commit(types.SET_WISHLIST_PRODUCTS, []);
    },

    async changeGiftCardsEnabledState({ dispatch, state }, cards) {
        const { cartId } = state;

        try {
            const cardsStates = cards.map(({ cardNumber, enabled }) => ({
                cardNumber,
                enabled,
            }));

            const { error, data } = await this.$services.prePaid.changeCardVisibility({
                cartId,
                prePaidCards: cardsStates,
            });

            if (error) {
                throw new Error(error, { cause: { error } });
            }

            const { prePaidCards, extraPaymentMethodId } = data;

            dispatch('setGiftCards', prePaidCards);
            dispatch('setAdditionalPaymentMethodId', extraPaymentMethodId);

            return true;
        } catch {
            return false;
        }
    },

    async deactivateAllGiftCards({ state, getters, commit, dispatch }) {
        if (!getters.isAnyGiftCardActive) {
            return true;
        }

        const currentGiftCards = state.giftCards;

        const newGiftCardsStates = state.giftCards.map(({ cardNumber }) => ({
            cardNumber,
            enabled: false,
        }));

        const result = await dispatch('changeGiftCardsEnabledState', newGiftCardsStates);

        if (!result) {
            return false;
        }

        commit(types.SET_STORED_GIFT_CARDS, currentGiftCards);

        dispatch(
            'messages/addInfoMessage',
            {
                text: this.app.i18n.t('The gift card has been removed'),
            },
            { root: true }
        );

        return true;
    },

    async activateStoredGiftCards({ state, getters, commit, dispatch }) {
        if (!getters.areAnyGiftCardsStored) {
            return true;
        }

        const newGiftCardsStates = state.giftCards.map(giftCard => {
            const storedGiftCard = state.storedGiftCards.find(
                ({ cardNumber }) => cardNumber === giftCard.cardNumber
            );

            return {
                ...giftCard,
                enabled: storedGiftCard?.enabled || giftCard.enabled,
            };
        });

        const result = await dispatch('changeGiftCardsEnabledState', newGiftCardsStates);

        if (!result) {
            return false;
        }

        commit(types.SET_STORED_GIFT_CARDS, []);

        dispatch(
            'messages/addSuccessMessage',
            {
                text: this.app.i18n.t('Gift card added to order'),
            },
            { root: true }
        );

        return true;
    },

    async handleGiftCardActivation({ dispatch }, newIsPartnerStore) {
        if (!newIsPartnerStore) {
            return dispatch('activateStoredGiftCards');
        }

        return dispatch('deactivateAllGiftCards');
    },

    async handleGiftCardsOnCartDataSet({ state, dispatch }, newCartData) {
        const currentIsPartnerStore = isPartnerStore(state.cartData);
        const newIsPartnerStore = isPartnerStore(newCartData);

        if (!currentIsPartnerStore && !newIsPartnerStore) {
            return;
        }

        await dispatch('handleGiftCardActivation', newIsPartnerStore);
    },

    async getAssignedGiftCards({ dispatch, state, getters }) {
        const { cartId } = state;

        try {
            const { data, error } = await this.app.$services.prePaid.getAssignedPrePaidCards({
                cartId,
            });

            if (error) {
                throw new Error(error, { cause: error });
            }

            const { orderAmount, prePaidCards, extraPaymentMethodId } = data;

            if (getters.grandTotalNumber !== orderAmount.amount) {
                await dispatch('updateOrderAmount');

                return;
            }

            dispatch('setGiftCards', prePaidCards);
            dispatch('setAdditionalPaymentMethodId', extraPaymentMethodId);
        } catch (err) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'cart.getAssignedGiftCards',
                },
                { cartId }
            );
        }
    },

    async updateOrderAmount({ state, getters, rootGetters, dispatch }) {
        const { cartId } = state;

        try {
            const { data, error } = await this.app.$services.prePaid.updateOrderAmount({
                cartId,
                orderAmount: {
                    amount: getters.grandTotalNumber,
                    currency: rootGetters['config/currency'],
                },
            });

            if (error) {
                throw new Error(error, { cause: error });
            }

            const { prePaidCards, extraPaymentMethodId } = data;

            dispatch('setGiftCards', prePaidCards);
            dispatch('setAdditionalPaymentMethodId', extraPaymentMethodId);
        } catch (err) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'cart.updateOrderAmount',
                },
                { cartId }
            );
        }
    },

    async addGiftCard({ state, getters, rootGetters, dispatch }, { cardNumber, cardPin }) {
        const { cartId } = state;

        try {
            const { data, error } = await this.app.$services.prePaid.assignPrePaidToCart({
                cartId,
                cardNumber,
                cardPin,
                orderAmount: {
                    amount: getters.grandTotalNumber,
                    currency: rootGetters['config/currency'],
                },
            });

            if (!error) {
                const { prePaidCards, extraPaymentMethodId } = data;

                dispatch('setGiftCards', prePaidCards);
                dispatch('setAdditionalPaymentMethodId', extraPaymentMethodId);

                return { data };
            }

            return { error };
        } catch (error) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                error,
                {
                    [ERROR_ACTION_TAG_NAME]: 'cart.addGiftCard',
                },
                { cartId, cardNumber }
            );
        }
    },

    async removeGiftCard({ state, dispatch }, { cardNumber }) {
        const { cartId } = state;

        try {
            const { error } = await this.app.$services.prePaid.detachPrePaidFromCart({
                cartId,
                cardNumber,
            });

            if (error) {
                throw new Error(error, { cause: error });
            }

            const giftCards = state.giftCards.filter(card => card.cardNumber !== cardNumber);

            dispatch('setGiftCards', giftCards);

            if (giftCards.length === 0) {
                dispatch('setAdditionalPaymentMethodId', '');
            }
        } catch (err) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'cart.removeGiftCard',
                },
                { cartId, cardNumber }
            );
        }
    },

    async resetAdditionalPaymentMethod({ state, dispatch }) {
        const { cartId } = state;
        const { error } = this.app.$services.prePaid.extraPaymentMethodUnSet({ cartId });

        if (!error) {
            dispatch('setAdditionalPaymentMethodId', '');
        }

        return !error;
    },

    setAdditionalPaymentMethodId({ commit }, paymentMethodId) {
        commit(types.SET_ADDITIONAL_PAYMENT_METHOD_ID, paymentMethodId);
    },

    setGiftCards({ commit }, giftCards) {
        commit(types.SET_GIFT_CARDS, giftCards);
    },

    async resetPaymentMethodOnCart({ state, dispatch }) {
        try {
            const cartData = await this.$services.cart.resetPaymentMethodOnCart(state.cartId);

            await dispatch('setCartData', cartData);

            return true;
        } catch (err) {
            this.app.$errorHandler.captureStoreError(MODULE_NAME, err, {
                [ERROR_ACTION_TAG_NAME]: 'cart.resetPaymentMethodOnCart',
            });

            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t(
                        // eslint-disable-next-line max-len
                        'Resetting payment method on cart error occurred. Please select payment method.'
                    ),
                },
                { root: true }
            );
        }

        return false;
    },

    setStoredGiftCards({ commit }, storedGiftCards) {
        commit(types.SET_STORED_GIFT_CARDS, storedGiftCards);
    },

    clearGiftCards({ dispatch }) {
        dispatch('setGiftCards', []);
        dispatch('setStoredGiftCards', []);
        dispatch('setAdditionalPaymentMethodId', '');
    },
};

import { getAssignedPrePaidCards } from '@normalizers/prepaid/getAssignedPrePaidCards';
import { assignPrePaidToCart } from '@normalizers/prepaid/assignPrePaidToCart';
import { detachPrePaidFromCart } from '@normalizers/prepaid/detachPrePaidFromCart';
import { updateOrderAmount } from '@normalizers/prepaid/updateOrderAmount';
import { changeCardVisibility } from '@normalizers/prepaid/changeCardVisibility';
import { extractPaymentMethodUnSet } from '@normalizers/prepaid/extractPaymentMethodUnSet';
import { extractPaymentMethodSet } from '@normalizers/prepaid/extractPaymentMethodSet';

export const prePaidNormalizersRegistry = [
    getAssignedPrePaidCards,
    assignPrePaidToCart,
    detachPrePaidFromCart,
    updateOrderAmount,
    changeCardVisibility,
    extractPaymentMethodUnSet,
    extractPaymentMethodSet,
].reduce((config, { operationName, normalizer }) => {
    config[operationName] = normalizer;

    return config;
}, {});

export const IMAGE_ONE_PIXEL_PLACEHOLDER =
    'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

export const IMAGE_TYPE_THUMBNAIL = 'thumbnail';
const IMAGE_TYPE_THUMBNAIL_WIDTH = 75;
const IMAGE_TYPE_THUMBNAIL_HEIGHT = 100;

export const IMAGE_TYPE_CART = 'cart';
const IMAGE_TYPE_CART_WIDTH = 180;
const IMAGE_TYPE_CART_HEIGHT = 240;

export const IMAGE_TYPE_PRODUCT = 'product';
const IMAGE_TYPE_PRODUCT_WIDTH = 560;
const IMAGE_TYPE_PRODUCT_HEIGHT = 746;

export const IMAGE_TYPE_PRODUCT_CARD = 'productcard';
const IMAGE_TYPE_PRODUCT_CARD_WIDTH = 280;
const IMAGE_TYPE_PRODUCT_CARD_HEIGHT = 373;

export const IMAGE_TYPE_PRODUCT_CARD_2X = 'product';
const IMAGE_TYPE_PRODUCT_CARD_2X_WIDTH = 560;
const IMAGE_TYPE_PRODUCT_CARD_2X_HEIGHT = 746;

export const IMAGE_TYPE_ZOOM_1536w_2048h = 'zoomapp';
const IMAGE_TYPE_ZOOM_1536w_2048h_WIDTH = 1536;
const IMAGE_TYPE_ZOOM_1536w_2048h_HEIGHT = 2048;

export const IMAGE_TYPE_PRODUCT_CARD_STYLIZATION = 'productcard_stylization';
const IMAGE_TYPE_PRODUCT_CARD_STYLIZATION_WIDTH = 326;
const IMAGE_TYPE_PRODUCT_CARD_STYLIZATION_HEIGHT = 434;

export const IMAGE_TYPE_BRAND = 'brand';
const IMAGE_TYPE_BRAND_WIDTH = 184;
const IMAGE_TYPE_BRAND_HEIGHT = 88;

export const IMAGE_TYPE_LOYALTY_CLUB_672w_378h = 'loyalty_club_672w_378h';
const IMAGE_TYPE_LOYALTY_CLUB_672w_378h_WIDTH = 672;
const IMAGE_TYPE_LOYALTY_CLUB_672w_378h_HEIGHT = 378;

export const IMAGE_TYPE_LOYALTY_CLUB_AVATAR_200w_200h = 'loyalty_club_avatar_200w_200h';
const IMAGE_TYPE_LOYALTY_CLUB_AVATAR_200w_200h_WIDTH = 200;
const IMAGE_TYPE_LOYALTY_CLUB_AVATAR_200w_200h_HEIGHT = 200;

export const IMAGE_TYPE_ICON = 'icon';
const IMAGE_TYPE_ICON_WIDTH = 'auto';
const IMAGE_TYPE_ICON_HEIGHT = 25;

export const IMAGE_TYPE_SVG = 'svg';
const IMAGE_TYPE_SVG_WIDTH = '';
const IMAGE_TYPE_SVG_HEIGHT = 25;

export const IMAGE_TYPE_MARKETING_IMAGE_TILE_225w_300h = 'marketing_image_tile_225w_300h';
const IMAGE_TYPE_MARKETING_IMAGE_TILE_225w_300h_WIDTH = 225;
const IMAGE_TYPE_MARKETING_IMAGE_TILE_225w_300h_HEIGHT = 300;

export const IMAGE_TYPE_MARKETPLACE_FAMILIAR_BRANDS_200w_100h =
    'marketplace_familiar_brands_200w_100h';
const IMAGE_TYPE_MARKETPLACE_FAMILIAR_BRANDS_200w_100h_WIDTH = 200;
const IMAGE_TYPE_MARKETPLACE_FAMILIAR_BRANDS_200w_100h_HEIGHT = 100;

export const IMAGE_TYPE_MARKETING_BANNER_VIDEO_PLACEHOLDER_640w_360h =
    'marketing_banner_video_placeholder_640w_360h';
const IMAGE_TYPE_MARKETING_BANNER_VIDEO_PLACEHOLDER_640w_360h_WIDTH = 640;
const IMAGE_TYPE_MARKETING_BANNER_VIDEO_PLACEHOLDER_640w_360h_HEIGHT = 360;

export const IMAGE_TYPE_MARKETING_BANNER_1532w_450h = 'marketing_banner_1532w_450h';
const IMAGE_TYPE_MARKETING_BANNER_1532w_450h_WIDTH = 1532;
const IMAGE_TYPE_MARKETING_BANNER_1532w_450h_HEIGHT = 450;

export const IMAGE_TYPE_MARKETING_BANNER_1152w_450h = 'marketing_banner_1152w_450h';
const IMAGE_TYPE_MARKETING_BANNER_1152w_450h_WIDTH = 1152;
const IMAGE_TYPE_MARKETING_BANNER_1152w_450h_HEIGHT = 450;

export const IMAGE_TYPE_MARKETING_BANNER_767w_450h = 'marketing_banner_767w_450h';
const IMAGE_TYPE_MARKETING_BANNER_767w_450h_WIDTH = 767;
const IMAGE_TYPE_MARKETING_BANNER_767w_450h_HEIGHT = 450;

export const IMAGE_TYPE_MARKETING_BANNER_360w_450h = 'marketing_banner_360w_450h';
const IMAGE_TYPE_MARKETING_BANNER_360w_450h_WIDTH = 360;
const IMAGE_TYPE_MARKETING_BANNER_360w_450h_HEIGHT = 450;

export const IMAGE_TYPE_MARKETING_BANNER_450w_450h = 'marketing_banner_450w_450h';
const IMAGE_TYPE_MARKETING_BANNER_450w_450h_WIDTH = 450;
const IMAGE_TYPE_MARKETING_BANNER_450w_450h_HEIGHT = 450;

export const IMAGE_TYPE_MARKETING_BANNER_768w_432h = 'marketing_banner_768w_432h';
const IMAGE_TYPE_MARKETING_BANNER_768w_432h_WIDTH = 768;
const IMAGE_TYPE_MARKETING_BANNER_768w_432h_HEIGHT = 432;

export const IMAGE_TYPE_MARKETING_CARD_492w_656h = 'marketing_card_492w_656h';
const IMAGE_TYPE_MARKETING_CARD_492w_656h_WIDTH = 492;
const IMAGE_TYPE_MARKETING_CARD_492w_656h_HEIGHT = 656;

export const IMAGE_TYPE_MARKETING_CARD_396w_528h = 'marketing_card_396w_528h';
const IMAGE_TYPE_MARKETING_CARD_396w_528h_WIDTH = 396;
const IMAGE_TYPE_MARKETING_CARD_396w_528h_HEIGHT = 528;

export const IMAGE_TYPE_MARKETING_CARD_243w_324h = 'marketing_card_243w_324h';
const IMAGE_TYPE_MARKETING_CARD_243w_324h_WIDTH = 243;
const IMAGE_TYPE_MARKETING_CARD_243w_324h_HEIGHT = 324;

export const IMAGE_TYPE_MARKETING_CELEBRITY_492w_656h = 'marketing_celebrity_492w_656h';
const IMAGE_TYPE_MARKETING_CELEBRITY_492w_656h_WIDTH = 492;
const IMAGE_TYPE_MARKETING_CELEBRITY_492w_656h_HEIGHT = 656;

export const IMAGE_TYPE_MARKETING_CELEBRITY_324w_432h = 'marketing_celebrity_324w_432h';
const IMAGE_TYPE_MARKETING_CELEBRITY_324w_432h_WIDTH = 324;
const IMAGE_TYPE_MARKETING_CELEBRITY_324w_432h_HEIGHT = 432;

export const IMAGE_TYPE_MARKETING_CELEBRITY_785w_785h = 'marketing_celebrity_785w_785h';
const IMAGE_TYPE_MARKETING_CELEBRITY_785w_785h_WIDTH = 785;
const IMAGE_TYPE_MARKETING_CELEBRITY_785w_785h_HEIGHT = 785;

export const IMAGE_TYPE_MARKETING_AVATAR_32w_32h = 'marketing_avatar_32w_32h';
const IMAGE_TYPE_MARKETING_AVATAR_32w_32h_WIDTH = 32;
const IMAGE_TYPE_MARKETING_AVATAR_32w_32h_HEIGHT = 32;

export const IMAGE_TYPE_MARKETING_AVATAR_48w_48h = 'marketing_avatar_48w_48h';
const IMAGE_TYPE_MARKETING_AVATAR_48w_48h_WIDTH = 48;
const IMAGE_TYPE_MARKETING_AVATAR_48w_48h_HEIGHT = 48;

export const IMAGE_TYPE_MARKETING_BRAND_115w_55h = 'marketing_brand_115w_55h';
const IMAGE_TYPE_MARKETING_BRAND_115w_55h_WIDTH = 115;
const IMAGE_TYPE_MARKETING_BRAND_115w_55h_HEIGHT = 55;

export const IMAGE_TYPE_MARKETING_BLOG_328w_328h = 'marketing_blog_328w_328h';
const IMAGE_TYPE_MARKETING_BLOG_328w_328h_WIDTH = 328;
const IMAGE_TYPE_MARKETING_BLOG_328w_328h_HEIGHT = 328;

export const IMAGE_TYPE_MARKETING_BLOG_492w_492h = 'marketing_blog_492w_492h';
const IMAGE_TYPE_MARKETING_BLOG_492w_492h_WIDTH = 492;
const IMAGE_TYPE_MARKETING_BLOG_492w_492h_HEIGHT = 492;

export const IMAGE_TYPE_MARKETING_BLOG_492w_656h = 'marketing_blog_492w_656h';
const IMAGE_TYPE_MARKETING_BLOG_492w_656h_WIDTH = 492;
const IMAGE_TYPE_MARKETING_BLOG_492w_656h_HEIGHT = 656;

export const IMAGE_TYPE_MARKETING_BLOG_786w_786h = 'marketing_blog_786w_786h';
const IMAGE_TYPE_MARKETING_BLOG_786w_786h_WIDTH = 786;
const IMAGE_TYPE_MARKETING_BLOG_786w_786h_HEIGHT = 786;

export const IMAGE_TYPE_MARKETING_BLOG_786w_1048h = 'marketing_blog_786w_1048h';
const IMAGE_TYPE_MARKETING_BLOG_786w_1048h_WIDTH = 786;
const IMAGE_TYPE_MARKETING_BLOG_786w_1048h_HEIGHT = 1048;

export const IMAGE_TYPE_MARKETING_INFLUENCER_243w_324h = 'marketing_influencer_243w_324h';
const IMAGE_TYPE_MARKETING_INFLUENCER_243w_324h_WIDTH = 243;
const IMAGE_TYPE_MARKETING_INFLUENCER_243w_324h_HEIGHT = 324;

export const IMAGE_TYPE_MARKETING_INFLUENCER_318w_424h = 'marketing_influencer_318w_424h';
const IMAGE_TYPE_MARKETING_INFLUENCER_318w_424h_WIDTH = 318;
const IMAGE_TYPE_MARKETING_INFLUENCER_318w_424h_HEIGHT = 424;

export const IMAGE_TYPE_MARKETING_INFLUENCER_513w_684h = 'marketing_influencer_513w_684h';
const IMAGE_TYPE_MARKETING_INFLUENCER_513w_684h_WIDTH = 513;
const IMAGE_TYPE_MARKETING_INFLUENCER_513w_684h_HEIGHT = 684;

export const IMAGE_TYPE_HOMEPAGE_356w_400h = 'marketing_homepage_356w_400h';
const IMAGE_TYPE_HOMEPAGE_356w_400h_WIDTH = 356;
const IMAGE_TYPE_HOMEPAGE_356w_400h_HEIGHT = 400;

export const IMAGE_TYPE_HOMEPAGE_640w_360h = 'marketing_homepage_640w_360h';
const IMAGE_TYPE_HOMEPAGE_640w_360h_WIDTH = 640;
const IMAGE_TYPE_HOMEPAGE_640w_360h_HEIGHT = 360;

export const IMAGE_TYPE_MARKETING_CATEGORY_318w_424h = 'marketing_category_318w_424h';
const IMAGE_TYPE_MARKETING_CATEGORY_318w_424h_WIDTH = 318;
const IMAGE_TYPE_MARKETING_CATEGORY_318w_424h_HEIGHT = 424;

export const IMAGE_TYPE_MARKETING_MFC_HERO_BANNER_766w_638h = 'marketing_mfc_hero_banner_766w_638h';
const IMAGE_TYPE_MARKETING_MFC_HERO_BANNER_766w_638h_WIDTH = 766;
const IMAGE_TYPE_MARKETING_MFC_HERO_BANNER_766w_638h_HEIGHT = 638;

export const IMAGE_TYPE_MARKETING_HERO_570w_600h = 'marketing_hero_570w_600h';
const IMAGE_TYPE_MARKETING_HERO_570w_600h_WIDTH = 570;
const IMAGE_TYPE_MARKETING_HERO_570w_600h_HEIGHT = 600;

export const IMAGE_TYPE_MARKETPLACE_COOPERATE_WITH_US_300w_300h =
    'marketplace_cooperate_with_us_300w_300h';
const IMAGE_TYPE_MARKETPLACE_COOPERATE_WITH_US_300w_300h_WIDTH = 300;
const IMAGE_TYPE_MARKETPLACE_COOPERATE_WITH_US_300w_300h_HEIGHT = 300;

export const IMAGE_TYPE_MARKETING_PROMO_CATEGORY_300w_300h = 'marketing_promo_category_300w_300h';
const IMAGE_TYPE_MARKETING_PROMO_CATEGORY_300w_300h_WIDTH = 300;
const IMAGE_TYPE_MARKETING_PROMO_CATEGORY_300w_300h_HEIGHT = 300;

export const IMAGE_TYPE_MARKETING_PROMO_CATEGORY_300w_616h = 'marketing_promo_category_300w_616h';
const IMAGE_TYPE_MARKETING_PROMO_CATEGORY_300w_616h_WIDTH = 300;
const IMAGE_TYPE_MARKETING_PROMO_CATEGORY_300w_616h_HEIGHT = 616;

export const IMAGE_TYPE_MAGENTO = 'mag';
const IMAGE_TYPE_MAGENTO_WIDTH = null;
const IMAGE_TYPE_MAGENTO_HEIGHT = null;

export const IMAGE_TYPE_MAGENTO_NAVIGATION = 'mag_navigation';
const IMAGE_TYPE_MAGENTO_NAVIGATION_WIDTH = 40;
const IMAGE_TYPE_MAGENTO_NAVIGATION_HEIGHT = 40;

export const MAGENTO_IMAGE_TYPES = {
    [IMAGE_TYPE_MAGENTO]: {
        name: IMAGE_TYPE_MAGENTO,
        width: IMAGE_TYPE_MAGENTO_WIDTH,
        height: IMAGE_TYPE_MAGENTO_HEIGHT,
    },

    [IMAGE_TYPE_MAGENTO_NAVIGATION]: {
        name: IMAGE_TYPE_MAGENTO_NAVIGATION,
        width: IMAGE_TYPE_MAGENTO_NAVIGATION_WIDTH,
        height: IMAGE_TYPE_MAGENTO_NAVIGATION_HEIGHT,
    },
};

export const PRODUCT_IMAGE_TYPES = {
    [IMAGE_TYPE_THUMBNAIL]: {
        name: IMAGE_TYPE_THUMBNAIL,
        width: IMAGE_TYPE_THUMBNAIL_WIDTH,
        height: IMAGE_TYPE_THUMBNAIL_HEIGHT,
    },
    [IMAGE_TYPE_CART]: {
        name: IMAGE_TYPE_CART,
        width: IMAGE_TYPE_CART_WIDTH,
        height: IMAGE_TYPE_CART_HEIGHT,
    },
    [IMAGE_TYPE_PRODUCT]: {
        name: IMAGE_TYPE_PRODUCT,
        width: IMAGE_TYPE_PRODUCT_WIDTH,
        height: IMAGE_TYPE_PRODUCT_HEIGHT,
    },
    [IMAGE_TYPE_PRODUCT_CARD]: {
        name: IMAGE_TYPE_PRODUCT_CARD,
        width: IMAGE_TYPE_PRODUCT_CARD_WIDTH,
        height: IMAGE_TYPE_PRODUCT_CARD_HEIGHT,
    },
    [IMAGE_TYPE_PRODUCT_CARD_2X]: {
        name: IMAGE_TYPE_PRODUCT_CARD_2X,
        width: IMAGE_TYPE_PRODUCT_CARD_2X_WIDTH,
        height: IMAGE_TYPE_PRODUCT_CARD_2X_HEIGHT,
    },
    [IMAGE_TYPE_ZOOM_1536w_2048h]: {
        name: IMAGE_TYPE_ZOOM_1536w_2048h,
        width: IMAGE_TYPE_ZOOM_1536w_2048h_WIDTH,
        height: IMAGE_TYPE_ZOOM_1536w_2048h_HEIGHT,
    },
    [IMAGE_TYPE_PRODUCT_CARD_STYLIZATION]: {
        name: IMAGE_TYPE_PRODUCT_CARD_STYLIZATION,
        width: IMAGE_TYPE_PRODUCT_CARD_STYLIZATION_WIDTH,
        height: IMAGE_TYPE_PRODUCT_CARD_STYLIZATION_HEIGHT,
    },
};

export const MARKETING_BLOG_IMAGE_TYPES = {
    [IMAGE_TYPE_MARKETING_BLOG_328w_328h]: {
        name: IMAGE_TYPE_MARKETING_BLOG_328w_328h,
        width: IMAGE_TYPE_MARKETING_BLOG_328w_328h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BLOG_328w_328h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_BLOG_492w_492h]: {
        name: IMAGE_TYPE_MARKETING_BLOG_492w_492h,
        width: IMAGE_TYPE_MARKETING_BLOG_492w_492h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BLOG_492w_492h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_BLOG_492w_656h]: {
        name: IMAGE_TYPE_MARKETING_BLOG_492w_656h,
        width: IMAGE_TYPE_MARKETING_BLOG_492w_656h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BLOG_492w_656h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_BLOG_786w_786h]: {
        name: IMAGE_TYPE_MARKETING_BLOG_786w_786h,
        width: IMAGE_TYPE_MARKETING_BLOG_786w_786h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BLOG_786w_786h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_BLOG_786w_1048h]: {
        name: IMAGE_TYPE_MARKETING_BLOG_786w_1048h,
        width: IMAGE_TYPE_MARKETING_BLOG_786w_1048h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BLOG_786w_1048h_HEIGHT,
    },
};

// @todo MOD-2147 - usunąć wsparcie dla tego typu
export const OLD_MARKETING_BANNER_IMAGE_TYPES = {
    [IMAGE_TYPE_MARKETING_BANNER_1532w_450h]: {
        name: IMAGE_TYPE_MARKETING_BANNER_1532w_450h,
        width: IMAGE_TYPE_MARKETING_BANNER_1532w_450h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BANNER_1532w_450h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_BANNER_1152w_450h]: {
        name: IMAGE_TYPE_MARKETING_BANNER_1152w_450h,
        width: IMAGE_TYPE_MARKETING_BANNER_1152w_450h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BANNER_1152w_450h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_BANNER_767w_450h]: {
        name: IMAGE_TYPE_MARKETING_BANNER_767w_450h,
        width: IMAGE_TYPE_MARKETING_BANNER_767w_450h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BANNER_767w_450h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_BANNER_360w_450h]: {
        name: IMAGE_TYPE_MARKETING_BANNER_360w_450h,
        width: IMAGE_TYPE_MARKETING_BANNER_360w_450h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BANNER_360w_450h_HEIGHT,
    },
};

export const MARKETING_BANNER_IMAGE_TYPES = {
    [IMAGE_TYPE_MARKETING_BANNER_VIDEO_PLACEHOLDER_640w_360h]: {
        name: IMAGE_TYPE_MARKETING_BANNER_VIDEO_PLACEHOLDER_640w_360h,
        width: IMAGE_TYPE_MARKETING_BANNER_VIDEO_PLACEHOLDER_640w_360h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BANNER_VIDEO_PLACEHOLDER_640w_360h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_BANNER_1532w_450h]: {
        name: IMAGE_TYPE_MARKETING_BANNER_1532w_450h,
        width: IMAGE_TYPE_MARKETING_BANNER_1532w_450h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BANNER_1532w_450h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_BANNER_1152w_450h]: {
        name: IMAGE_TYPE_MARKETING_BANNER_1152w_450h,
        width: IMAGE_TYPE_MARKETING_BANNER_1152w_450h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BANNER_1152w_450h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_BANNER_767w_450h]: {
        name: IMAGE_TYPE_MARKETING_BANNER_767w_450h,
        width: IMAGE_TYPE_MARKETING_BANNER_767w_450h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BANNER_767w_450h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_BANNER_360w_450h]: {
        name: IMAGE_TYPE_MARKETING_BANNER_360w_450h,
        width: IMAGE_TYPE_MARKETING_BANNER_360w_450h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BANNER_360w_450h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_BANNER_450w_450h]: {
        name: IMAGE_TYPE_MARKETING_BANNER_450w_450h,
        width: IMAGE_TYPE_MARKETING_BANNER_450w_450h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BANNER_450w_450h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_BANNER_768w_432h]: {
        name: IMAGE_TYPE_MARKETING_BANNER_768w_432h,
        width: IMAGE_TYPE_MARKETING_BANNER_768w_432h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BANNER_768w_432h_HEIGHT,
    },
};

export const MARKETING_CARD_IMAGE_TYPES = {
    [IMAGE_TYPE_MARKETING_CARD_492w_656h]: {
        name: IMAGE_TYPE_MARKETING_CARD_492w_656h,
        width: IMAGE_TYPE_MARKETING_CARD_492w_656h_WIDTH,
        height: IMAGE_TYPE_MARKETING_CARD_492w_656h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_CARD_396w_528h]: {
        name: IMAGE_TYPE_MARKETING_CARD_396w_528h,
        width: IMAGE_TYPE_MARKETING_CARD_396w_528h_WIDTH,
        height: IMAGE_TYPE_MARKETING_CARD_396w_528h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_CARD_243w_324h]: {
        name: IMAGE_TYPE_MARKETING_CARD_243w_324h,
        width: IMAGE_TYPE_MARKETING_CARD_243w_324h_WIDTH,
        height: IMAGE_TYPE_MARKETING_CARD_243w_324h_HEIGHT,
    },
};

export const MARKETING_CELEBRITY_IMAGE_TYPES = {
    [IMAGE_TYPE_MARKETING_CELEBRITY_492w_656h]: {
        name: IMAGE_TYPE_MARKETING_CELEBRITY_492w_656h,
        width: IMAGE_TYPE_MARKETING_CELEBRITY_492w_656h_WIDTH,
        height: IMAGE_TYPE_MARKETING_CELEBRITY_492w_656h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_CELEBRITY_324w_432h]: {
        name: IMAGE_TYPE_MARKETING_CELEBRITY_324w_432h,
        width: IMAGE_TYPE_MARKETING_CELEBRITY_324w_432h_WIDTH,
        height: IMAGE_TYPE_MARKETING_CELEBRITY_324w_432h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_CELEBRITY_785w_785h]: {
        name: IMAGE_TYPE_MARKETING_CELEBRITY_785w_785h,
        width: IMAGE_TYPE_MARKETING_CELEBRITY_785w_785h_WIDTH,
        height: IMAGE_TYPE_MARKETING_CELEBRITY_785w_785h_HEIGHT,
    },
};

export const MARKETING_INFLUENCER_IMAGE_TYPES = {
    [IMAGE_TYPE_MARKETING_INFLUENCER_243w_324h]: {
        name: IMAGE_TYPE_MARKETING_INFLUENCER_243w_324h,
        width: IMAGE_TYPE_MARKETING_INFLUENCER_243w_324h_WIDTH,
        height: IMAGE_TYPE_MARKETING_INFLUENCER_243w_324h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_INFLUENCER_318w_424h]: {
        name: IMAGE_TYPE_MARKETING_INFLUENCER_318w_424h,
        width: IMAGE_TYPE_MARKETING_INFLUENCER_318w_424h_WIDTH,
        height: IMAGE_TYPE_MARKETING_INFLUENCER_318w_424h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_INFLUENCER_513w_684h]: {
        name: IMAGE_TYPE_MARKETING_INFLUENCER_513w_684h,
        width: IMAGE_TYPE_MARKETING_INFLUENCER_513w_684h_WIDTH,
        height: IMAGE_TYPE_MARKETING_INFLUENCER_513w_684h_HEIGHT,
    },
};

export const MARKETING_IMAGE_TYPES = {
    [IMAGE_TYPE_MARKETING_AVATAR_32w_32h]: {
        name: IMAGE_TYPE_MARKETING_AVATAR_32w_32h,
        width: IMAGE_TYPE_MARKETING_AVATAR_32w_32h_WIDTH,
        height: IMAGE_TYPE_MARKETING_AVATAR_32w_32h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_AVATAR_48w_48h]: {
        name: IMAGE_TYPE_MARKETING_AVATAR_48w_48h,
        width: IMAGE_TYPE_MARKETING_AVATAR_48w_48h_WIDTH,
        height: IMAGE_TYPE_MARKETING_AVATAR_48w_48h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_BRAND_115w_55h]: {
        name: IMAGE_TYPE_MARKETING_BRAND_115w_55h,
        width: IMAGE_TYPE_MARKETING_BRAND_115w_55h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BRAND_115w_55h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_CATEGORY_318w_424h]: {
        name: IMAGE_TYPE_MARKETING_CATEGORY_318w_424h,
        width: IMAGE_TYPE_MARKETING_CATEGORY_318w_424h_WIDTH,
        height: IMAGE_TYPE_MARKETING_CATEGORY_318w_424h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_HERO_570w_600h]: {
        name: IMAGE_TYPE_MARKETING_HERO_570w_600h,
        width: IMAGE_TYPE_MARKETING_HERO_570w_600h_WIDTH,
        height: IMAGE_TYPE_MARKETING_HERO_570w_600h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_PROMO_CATEGORY_300w_300h]: {
        name: IMAGE_TYPE_MARKETING_PROMO_CATEGORY_300w_300h,
        width: IMAGE_TYPE_MARKETING_PROMO_CATEGORY_300w_300h_WIDTH,
        height: IMAGE_TYPE_MARKETING_PROMO_CATEGORY_300w_300h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_PROMO_CATEGORY_300w_616h]: {
        name: IMAGE_TYPE_MARKETING_PROMO_CATEGORY_300w_616h,
        width: IMAGE_TYPE_MARKETING_PROMO_CATEGORY_300w_616h_WIDTH,
        height: IMAGE_TYPE_MARKETING_PROMO_CATEGORY_300w_616h_HEIGHT,
    },
};

export const BRAND_IMAGE_TYPES = {
    [IMAGE_TYPE_BRAND]: {
        name: IMAGE_TYPE_BRAND,
        width: IMAGE_TYPE_BRAND_WIDTH,
        height: IMAGE_TYPE_BRAND_HEIGHT,
    },
};

export const ICON_IMAGE_TYPES = {
    [IMAGE_TYPE_ICON]: {
        name: IMAGE_TYPE_ICON,
        width: IMAGE_TYPE_ICON_WIDTH,
        height: IMAGE_TYPE_ICON_HEIGHT,
    },
};

export const SVG_IMAGE_TYPES = {
    [IMAGE_TYPE_SVG]: {
        name: IMAGE_TYPE_SVG,
        width: IMAGE_TYPE_SVG_WIDTH,
        height: IMAGE_TYPE_SVG_HEIGHT,
    },
};

export const LOYALTY_CLUB_IMAGE_TYPES = {
    [IMAGE_TYPE_LOYALTY_CLUB_672w_378h]: {
        name: IMAGE_TYPE_LOYALTY_CLUB_672w_378h,
        width: IMAGE_TYPE_LOYALTY_CLUB_672w_378h_WIDTH,
        height: IMAGE_TYPE_LOYALTY_CLUB_672w_378h_HEIGHT,
    },
    [IMAGE_TYPE_LOYALTY_CLUB_AVATAR_200w_200h]: {
        name: IMAGE_TYPE_LOYALTY_CLUB_AVATAR_200w_200h,
        width: IMAGE_TYPE_LOYALTY_CLUB_AVATAR_200w_200h_WIDTH,
        height: IMAGE_TYPE_LOYALTY_CLUB_AVATAR_200w_200h_HEIGHT,
    },
};

export const MARKETING_HOMEPAGE_IMAGE_TYPES = {
    [IMAGE_TYPE_HOMEPAGE_356w_400h]: {
        name: IMAGE_TYPE_HOMEPAGE_356w_400h,
        width: IMAGE_TYPE_HOMEPAGE_356w_400h_WIDTH,
        height: IMAGE_TYPE_HOMEPAGE_356w_400h_HEIGHT,
    },
    [IMAGE_TYPE_HOMEPAGE_640w_360h]: {
        name: IMAGE_TYPE_HOMEPAGE_640w_360h,
        width: IMAGE_TYPE_HOMEPAGE_640w_360h_WIDTH,
        height: IMAGE_TYPE_HOMEPAGE_640w_360h_HEIGHT,
    },
};

export const MARKETING_MFC_HERO_BANNER_IMAGE_TYPES = {
    [IMAGE_TYPE_MARKETING_MFC_HERO_BANNER_766w_638h]: {
        name: IMAGE_TYPE_MARKETING_MFC_HERO_BANNER_766w_638h,
        width: IMAGE_TYPE_MARKETING_MFC_HERO_BANNER_766w_638h_WIDTH,
        height: IMAGE_TYPE_MARKETING_MFC_HERO_BANNER_766w_638h_HEIGHT,
    },
};

export const MARKETING_IMAGE_TILE_IMAGE_TYPES = {
    [IMAGE_TYPE_MARKETING_IMAGE_TILE_225w_300h]: {
        name: IMAGE_TYPE_MARKETING_IMAGE_TILE_225w_300h,
        width: IMAGE_TYPE_MARKETING_IMAGE_TILE_225w_300h_WIDTH,
        height: IMAGE_TYPE_MARKETING_IMAGE_TILE_225w_300h_HEIGHT,
    },
};

export const MARKETPLACE_COOPERATE_WITH_US_TYPES = {
    [IMAGE_TYPE_MARKETPLACE_COOPERATE_WITH_US_300w_300h]: {
        name: IMAGE_TYPE_MARKETPLACE_COOPERATE_WITH_US_300w_300h,
        width: IMAGE_TYPE_MARKETPLACE_COOPERATE_WITH_US_300w_300h_WIDTH,
        height: IMAGE_TYPE_MARKETPLACE_COOPERATE_WITH_US_300w_300h_HEIGHT,
    },
};

export const MARKETPLACE_FAMILIAR_BRANDS_TYPES = {
    [IMAGE_TYPE_MARKETPLACE_FAMILIAR_BRANDS_200w_100h]: {
        name: IMAGE_TYPE_MARKETPLACE_FAMILIAR_BRANDS_200w_100h,
        width: IMAGE_TYPE_MARKETPLACE_FAMILIAR_BRANDS_200w_100h_WIDTH,
        height: IMAGE_TYPE_MARKETPLACE_FAMILIAR_BRANDS_200w_100h_HEIGHT,
    },
};

export const IMAGE_TYPES = {
    ...PRODUCT_IMAGE_TYPES,
    ...BRAND_IMAGE_TYPES,
    ...ICON_IMAGE_TYPES,
    ...SVG_IMAGE_TYPES,
    ...MARKETING_BANNER_IMAGE_TYPES,
    ...MARKETING_BLOG_IMAGE_TYPES,
    ...MARKETING_CARD_IMAGE_TYPES,
    ...MARKETING_CELEBRITY_IMAGE_TYPES,
    ...MARKETING_INFLUENCER_IMAGE_TYPES,
    ...MARKETING_IMAGE_TYPES,
    ...LOYALTY_CLUB_IMAGE_TYPES,
    ...MAGENTO_IMAGE_TYPES,
    ...MARKETING_HOMEPAGE_IMAGE_TYPES,
    ...MARKETING_MFC_HERO_BANNER_IMAGE_TYPES,
    ...MARKETING_IMAGE_TILE_IMAGE_TYPES,
    ...MARKETPLACE_FAMILIAR_BRANDS_TYPES,
    ...MAGENTO_IMAGE_TYPES,
    ...MARKETPLACE_COOPERATE_WITH_US_TYPES,
};

export const IMAGE_FORMAT_WEBP_EXTENSION = 'webp';
export const IMAGE_FORMAT_WEBP_MIME_TYPE = 'image/webp';
export const IMAGE_FORMAT_JPG_EXTENSION = 'jpg';
export const IMAGE_FORMAT_JPG_MIME_TYPE = 'image/jpeg';
export const IMAGE_FORMAT_SVG_EXTENSION = 'svg';
export const IMAGE_FORMAT_SVG_MIME_TYPE = 'image/svg+xml';

export const IMAGE_FORMAT_WEBP = {
    extension: IMAGE_FORMAT_WEBP_EXTENSION,
    mimeType: IMAGE_FORMAT_WEBP_MIME_TYPE,
};

export const IMAGE_FORMAT_JPG = {
    extension: IMAGE_FORMAT_JPG_EXTENSION,
    mimeType: IMAGE_FORMAT_JPG_MIME_TYPE,
};

export const IMAGE_FORMAT_SVG = {
    extension: IMAGE_FORMAT_SVG_EXTENSION,
    mimeType: IMAGE_FORMAT_SVG_MIME_TYPE,
};

export const DEFAULT_IMAGE_FORMAT = IMAGE_FORMAT_JPG;

export const ADDITIONAL_IMAGE_FORMATS = [IMAGE_FORMAT_WEBP];
export const SOURCE_IMAGE_FORMATS = [IMAGE_FORMAT_WEBP, IMAGE_FORMAT_JPG];

export const IMAGE_PLACEHOLDERS = {
    [IMAGE_TYPE_THUMBNAIL]: {
        url: '',
        width: IMAGE_TYPE_THUMBNAIL_WIDTH,
        height: IMAGE_TYPE_THUMBNAIL_HEIGHT,
        alt: '',
    },
    [IMAGE_TYPE_CART]: {
        url: '',
        width: IMAGE_TYPE_CART_WIDTH,
        height: IMAGE_TYPE_CART_HEIGHT,
        alt: '',
    },
    [IMAGE_TYPE_PRODUCT]: {
        url: '',
        width: IMAGE_TYPE_PRODUCT_WIDTH,
        height: IMAGE_TYPE_PRODUCT_HEIGHT,
        alt: '',
    },
    [IMAGE_TYPE_PRODUCT_CARD]: {
        url: '',
        width: IMAGE_TYPE_PRODUCT_CARD_WIDTH,
        height: IMAGE_TYPE_PRODUCT_CARD_HEIGHT,
        alt: '',
    },
    [IMAGE_TYPE_PRODUCT_CARD_2X]: {
        url: '',
        width: IMAGE_TYPE_PRODUCT_CARD_2X_WIDTH,
        height: IMAGE_TYPE_PRODUCT_CARD_2X_HEIGHT,
        alt: '',
    },
    [IMAGE_TYPE_ZOOM_1536w_2048h]: {
        url: '',
        width: IMAGE_TYPE_ZOOM_1536w_2048h_WIDTH,
        height: IMAGE_TYPE_ZOOM_1536w_2048h_HEIGHT,
        alt: '',
    },
    [IMAGE_TYPE_PRODUCT_CARD_STYLIZATION]: {
        url: '',
        width: IMAGE_TYPE_PRODUCT_CARD_STYLIZATION_WIDTH,
        height: IMAGE_TYPE_PRODUCT_CARD_STYLIZATION_HEIGHT,
        alt: '',
    },
    [IMAGE_TYPE_BRAND]: {
        url: '',
        width: IMAGE_TYPE_BRAND_WIDTH,
        height: IMAGE_TYPE_BRAND_HEIGHT,
        alt: '',
    },
    [IMAGE_TYPE_LOYALTY_CLUB_672w_378h]: {
        url: '',
        width: IMAGE_TYPE_LOYALTY_CLUB_672w_378h_WIDTH,
        height: IMAGE_TYPE_LOYALTY_CLUB_672w_378h_HEIGHT,
        alt: '',
    },
    [IMAGE_TYPE_LOYALTY_CLUB_AVATAR_200w_200h]: {
        url: '',
        width: IMAGE_TYPE_LOYALTY_CLUB_AVATAR_200w_200h_WIDTH,
        height: IMAGE_TYPE_LOYALTY_CLUB_AVATAR_200w_200h_HEIGHT,
        alt: '',
    },
    [IMAGE_TYPE_MARKETING_CARD_492w_656h]: {
        url: '',
        width: IMAGE_TYPE_MARKETING_CARD_492w_656h_WIDTH,
        height: IMAGE_TYPE_MARKETING_CARD_492w_656h_HEIGHT,
        alt: '',
    },
    [IMAGE_TYPE_MARKETING_CELEBRITY_492w_656h]: {
        url: '',
        width: IMAGE_TYPE_MARKETING_CELEBRITY_492w_656h_WIDTH,
        height: IMAGE_TYPE_MARKETING_CELEBRITY_492w_656h_HEIGHT,
        alt: '',
    },
    [IMAGE_TYPE_MARKETING_CELEBRITY_785w_785h]: {
        url: '',
        width: IMAGE_TYPE_MARKETING_CELEBRITY_785w_785h,
        height: IMAGE_TYPE_MARKETING_CELEBRITY_785w_785h,
        alt: '',
    },
    [IMAGE_TYPE_MARKETING_BLOG_786w_1048h]: {
        url: '',
        width: IMAGE_TYPE_MARKETING_BLOG_786w_1048h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BLOG_786w_1048h_HEIGHT,
        alt: '',
    },
    [IMAGE_TYPE_MARKETING_BLOG_786w_786h]: {
        url: '',
        width: IMAGE_TYPE_MARKETING_BLOG_786w_786h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BLOG_786w_786h_HEIGHT,
        alt: '',
    },
    [IMAGE_TYPE_MARKETING_BANNER_768w_432h]: {
        url: '',
        width: IMAGE_TYPE_MARKETING_BANNER_768w_432h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BANNER_768w_432h_HEIGHT,
        alt: '',
    },
    [IMAGE_TYPE_HOMEPAGE_356w_400h]: {
        url: '',
        width: IMAGE_TYPE_HOMEPAGE_356w_400h_WIDTH,
        height: IMAGE_TYPE_HOMEPAGE_356w_400h_HEIGHT,
        alt: '',
    },
    [IMAGE_TYPE_HOMEPAGE_640w_360h]: {
        url: '',
        width: IMAGE_TYPE_HOMEPAGE_640w_360h_WIDTH,
        height: IMAGE_TYPE_HOMEPAGE_640w_360h_HEIGHT,
        alt: '',
    },
    [IMAGE_TYPE_MARKETING_CATEGORY_318w_424h]: {
        url: '',
        width: IMAGE_TYPE_MARKETING_CATEGORY_318w_424h_WIDTH,
        height: IMAGE_TYPE_MARKETING_CATEGORY_318w_424h_HEIGHT,
        alt: '',
    },
    [IMAGE_TYPE_MARKETING_MFC_HERO_BANNER_766w_638h]: {
        url: '',
        width: IMAGE_TYPE_MARKETING_MFC_HERO_BANNER_766w_638h_WIDTH,
        height: IMAGE_TYPE_MARKETING_MFC_HERO_BANNER_766w_638h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_IMAGE_TILE_225w_300h]: {
        url: '',
        width: IMAGE_TYPE_MARKETING_IMAGE_TILE_225w_300h_WIDTH,
        height: IMAGE_TYPE_MARKETING_IMAGE_TILE_225w_300h_HEIGHT,
        alt: '',
    },
    [IMAGE_TYPE_MARKETPLACE_FAMILIAR_BRANDS_200w_100h]: {
        url: '',
        width: IMAGE_TYPE_MARKETPLACE_FAMILIAR_BRANDS_200w_100h_WIDTH,
        height: IMAGE_TYPE_MARKETPLACE_FAMILIAR_BRANDS_200w_100h_HEIGHT,
        alt: '',
    },
    [IMAGE_TYPE_MARKETING_HERO_570w_600h]: {
        url: '',
        width: IMAGE_TYPE_MARKETING_HERO_570w_600h_WIDTH,
        height: IMAGE_TYPE_MARKETING_HERO_570w_600h_HEIGHT,
        alt: '',
    },
    [IMAGE_TYPE_MARKETPLACE_COOPERATE_WITH_US_300w_300h]: {
        url: '',
        width: IMAGE_TYPE_MARKETPLACE_COOPERATE_WITH_US_300w_300h_WIDTH,
        height: IMAGE_TYPE_MARKETPLACE_COOPERATE_WITH_US_300w_300h_HEIGHT,
        alt: '',
    },
    [IMAGE_TYPE_MARKETING_PROMO_CATEGORY_300w_300h]: {
        url: '',
        width: IMAGE_TYPE_MARKETING_PROMO_CATEGORY_300w_300h_WIDTH,
        height: IMAGE_TYPE_MARKETING_PROMO_CATEGORY_300w_300h_HEIGHT,
        alt: '',
    },
    [IMAGE_TYPE_MARKETING_PROMO_CATEGORY_300w_616h]: {
        url: '',
        width: IMAGE_TYPE_MARKETING_PROMO_CATEGORY_300w_616h_WIDTH,
        height: IMAGE_TYPE_MARKETING_PROMO_CATEGORY_300w_616h_HEIGHT,
        alt: '',
    },
};

import {
    STORAGE_PREFIX,
    STORAGE_EXPIRES_KEY,
    STORAGE_EXPIRES_DATA_KEY,
    STORAGE_TYPES,
    STORAGE_TYPE_DEFAULT,
    STORAGE_TYPE_LOCAL,
    STORAGE_TYPE_SESSION,
} from '@configs/storage';

function chooseStorage(type) {
    return Object.values(STORAGE_TYPES).includes(type) ? type : STORAGE_TYPE_DEFAULT;
}

function checkIfBrowserStorageIsSupported() {
    let canUseStorage = true;

    try {
        const key = `${STORAGE_PREFIX}TEST`;

        window.localStorage.setItem(key, 'test');

        const testItem = window.localStorage.getItem(key);

        if (testItem) {
            window.localStorage.removeItem(key);
        }
    } catch {
        canUseStorage = false;
    }

    return canUseStorage;
}

export const isStorageSupported = checkIfBrowserStorageIsSupported();

const isItemExpired = expires => {
    const current = new Date().getTime();

    return current > expires;
};

const createKeyWithPrefix = ({ key, brand }) => `__${brand.toUpperCase()}__${key}`;

const storageFactory = ({ errorHandler, brand, storageType = STORAGE_TYPE_DEFAULT }) => ({
    /**
     * @param {string} key
     * @param {Object} [options]
     * @param {boolean} [options.withPrefix=true]
     */
    getItem(key, options) {
        if (!isStorageSupported) {
            return null;
        }

        const { withPrefix = true } = options || {};

        const storageKey = withPrefix ? createKeyWithPrefix({ key, brand }) : key;
        const storageWindow = window[chooseStorage(storageType)];

        try {
            const item = storageWindow.getItem(storageKey);
            const parsed = JSON.parse(item);

            if (parsed && parsed instanceof Object) {
                const { [STORAGE_EXPIRES_KEY]: expires } = parsed;

                if (expires && !Number.isNaN(expires)) {
                    const expired = isItemExpired(expires);

                    if (expired) {
                        storageWindow.removeItem(storageKey);

                        return null;
                    }
                }
            }

            return parsed;
        } catch (error) {
            errorHandler.captureError(
                error,
                {},
                {
                    key,
                    storageType,
                }
            );

            return null;
        }
    },

    /**
     * @param {string} key
     * @param {*} value
     * @param {Object} [options]
     * @param {number} [options.expires=null]
     * @param {boolean} [options.withPrefix=true]
     * @param {boolean} [options.withStringify=true]
     */
    setItem(key, value, options) {
        if (!isStorageSupported) {
            return;
        }

        const { expires = null, withPrefix = true, withStringify = true } = options || {};

        const storageKey = withPrefix ? createKeyWithPrefix({ key, brand }) : key;
        const storageWindow = window[chooseStorage(storageType)];

        try {
            if (expires && !Number.isNaN(Number(expires))) {
                storageWindow.setItem(
                    storageKey,
                    JSON.stringify({
                        [STORAGE_EXPIRES_KEY]: expires,
                        [STORAGE_EXPIRES_DATA_KEY]: value,
                    })
                );
            } else {
                const valueToSave = withStringify ? JSON.stringify(value) : value;

                storageWindow.setItem(storageKey, valueToSave);
            }

            return value;
        } catch (error) {
            errorHandler.captureError(
                error,
                {},
                {
                    key,
                    storageType,
                }
            );

            return null;
        }
    },

    /**
     * @param {string} key
     * @param {Object} [options]
     * @param {boolean} [options.withPrefix=true]
     */
    removeItem(key, options) {
        if (!isStorageSupported) {
            return;
        }

        const { withPrefix = true } = options || {};

        const storageKey = withPrefix ? createKeyWithPrefix({ key, brand }) : key;
        const itemExist = this.getItem(key, { storageType, withPrefix }) !== null;

        if (itemExist) {
            try {
                window[chooseStorage(storageType)].removeItem(storageKey);
            } catch (error) {
                errorHandler.captureError(
                    error,
                    {},
                    {
                        key,
                        storageType,
                    }
                );
            }
        }
    },
});

export const createStorage = ({ errorHandler, brand }) => {
    const localStorage = storageFactory({ errorHandler, brand, storageType: STORAGE_TYPE_LOCAL });
    const sessionStorage = storageFactory({
        errorHandler,
        brand,
        storageType: STORAGE_TYPE_SESSION,
    });

    const getStorage = storageType =>
        storageType === STORAGE_TYPE_SESSION ? sessionStorage : localStorage;

    const storage = {
        getItem(key, storageType = STORAGE_TYPE_DEFAULT, withPrefix = true) {
            const storageObj = getStorage(storageType);

            return storageObj.getItem(key, { withPrefix });
        },

        setItem(
            key,
            value,
            expires = null,
            storageType = STORAGE_TYPE_DEFAULT,
            withPrefix = true,
            withStringify = true
        ) {
            const storageObj = getStorage(storageType);

            return storageObj.setItem(key, value, {
                expires,
                withPrefix,
                withStringify,
            });
        },

        removeItem(key, storageType = STORAGE_TYPE_DEFAULT, withPrefix = true) {
            const storageObj = getStorage(storageType);

            return storageObj.removeItem(key, { withPrefix });
        },
    };

    return {
        storage,
        localStorage,
        sessionStorage,
    };
};

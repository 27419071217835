import createAsyncServices from '@eobuwie/nuxt-async-services';

import asyncServicesConfig from '@async-services';

import createServices from '@services/create';

import { getMegatronNormalizer } from '@normalizers/megatron-normalizers';
import { getPrePaidNormalizer } from '@normalizers/prepaid-normalizers';

export default ({ store, app, $config, $errorHandler, $avatar }, inject) => {
    const { asyncServicesManagerDebugModeEnabled } = $config;

    const {
        $abTests,
        $megatronClient,
        $httpClient,
        $cookies,
        $performanceRecorder,
        $configProvider,
        redirect,
        route,
    } = app;

    const helpers = {
        abTests: $abTests,
        $cookies,
        store,
        $avatar,
    };

    const normalizeMegatronResponse = getMegatronNormalizer(app, store);
    const normalizePrePaidResponse = getPrePaidNormalizer();

    const services = createServices(
        $errorHandler,
        $megatronClient,
        $httpClient,
        $configProvider,
        helpers,
        normalizeMegatronResponse,
        normalizePrePaidResponse
    );

    inject('services', services);

    inject(
        'asyncServices',
        createAsyncServices({
            servicesConfig: asyncServicesConfig,
            runtimeServicesHelpers: {
                megatronClient: $megatronClient,
                httpClient: $httpClient,
                $performanceRecorder,
                $errorHandler,
                $configProvider,
                helpers,
                normalizeMegatronResponse,
                redirect,
                route,
            },
            debugModeEnabled: asyncServicesManagerDebugModeEnabled,
        })
    );
};

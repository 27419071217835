import { v5 as uuidV5, v4 as uuidV4 } from 'uuid';
import cookies from '@services/cookies';
import { ERROR_ACTION_TAG_NAME } from '@types/Errors';
import { CLIENT_IDENTITY_ERROR_DOMAIN } from '@errors/feature-domain-names';
import { AVATAR_ID, IS_NEW_AVATAR_ID, SECONDS_IN_YEAR, FALLBACK_AVATAR_ID } from '@configs/storage';

const COOKIE_OPTIONS = {
    path: '/',
    maxAge: SECONDS_IN_YEAR,
    sameSite: 'lax',
    secure: process.env.NODE_ENV !== 'development',
};
const AVATAR_ID_COOKIE_NAME = cookies.createCookieWithPrefix(AVATAR_ID);
const IS_NEW_AVATAR_ID_COOKIE_NAME = cookies.createCookieWithPrefix(IS_NEW_AVATAR_ID);
const X_INSTANCE_ID_HEADER_NAME = 'x-instance-id';
const AVATAR_UUID_NAMESPACE = '3afd7176-f7d6-5d8c-a312-e003fc3c24eb';

const createXInstanceIdHeader = avatarId => ({
    [X_INSTANCE_ID_HEADER_NAME]: avatarId,
});

export default class Avatar {
    id = null;

    constructor({ $cookies, $errorHandler, $storage }) {
        const avatarIdFromCookie = $cookies.get(AVATAR_ID_COOKIE_NAME);
        const avatarIdFromLocalStorage = process.client
            ? $storage?.getItem(FALLBACK_AVATAR_ID) || null
            : null;

        if (process.client) {
            if (!avatarIdFromCookie && !!avatarIdFromLocalStorage) {
                $cookies.set(AVATAR_ID_COOKIE_NAME, avatarIdFromLocalStorage, COOKIE_OPTIONS);
            }

            if (!!$storage && !!avatarIdFromCookie && !avatarIdFromLocalStorage) {
                $storage.setItem(FALLBACK_AVATAR_ID, avatarIdFromCookie);
            }

            if (
                !!avatarIdFromCookie &&
                !!avatarIdFromLocalStorage &&
                avatarIdFromCookie !== avatarIdFromLocalStorage
            ) {
                $cookies.set(AVATAR_ID_COOKIE_NAME, avatarIdFromLocalStorage, COOKIE_OPTIONS);

                $errorHandler.captureDomainError(
                    CLIENT_IDENTITY_ERROR_DOMAIN,
                    new Error('avatarId in cookies is different than in localStorage'),
                    {
                        [ERROR_ACTION_TAG_NAME]: 'Avatar.constructor',
                    },
                    {
                        avatarIdFromCookie,
                        avatarIdFromLocalStorage,
                    }
                );
            }
        }

        this.$cookies = $cookies;
        this.$errorHandler = $errorHandler;
        this.id = avatarIdFromLocalStorage || avatarIdFromCookie || null;
        this.$storage = $storage || null;
    }

    get isNewAvatarId() {
        return (
            !!this.$cookies.get(IS_NEW_AVATAR_ID_COOKIE_NAME) ||
            !!this.$storage?.getItem(IS_NEW_AVATAR_ID)
        );
    }

    get avatarId() {
        if (this.id) {
            return this.id;
        }

        const idFromCookies = this.avatarIdFromCookies;

        if (idFromCookies) {
            this.id = idFromCookies;

            return this.id;
        }

        if (process.client && this.$storage) {
            const idFromLocalStorage = this.avatarIdFromLocalStorage;

            if (idFromLocalStorage) {
                this.id = idFromLocalStorage;
            }
        }

        return this.id;
    }

    get avatarIdFromCookies() {
        return this.$cookies.get(AVATAR_ID_COOKIE_NAME) || null;
    }

    get avatarIdFromLocalStorage() {
        if (process.server || !this.$storage) {
            this.$errorHandler.captureDomainError(
                CLIENT_IDENTITY_ERROR_DOMAIN,
                new Error('Error with trying access avatarId from localStorage'),
                {
                    [ERROR_ACTION_TAG_NAME]: 'Avatar.avatarIdFromLocalStorage',
                },
                {
                    isServerSide: process.server,
                    isStorage: !!this.$storage,
                }
            );

            return null;
        }

        return this.$storage.getItem(FALLBACK_AVATAR_ID) || null;
    }

    createAvatarId() {
        const avatarId = this.generateAvatarId();

        if (!avatarId) {
            return null;
        }

        this.id = avatarId;
        this.setAvatarIdInCookie(this.id);

        if (process.client && this.$storage) {
            this.setAvatarIdInLocalStorage(this.id);
        }

        return this.id;
    }

    getInstanceIdHeader() {
        if (this.avatarId) {
            return createXInstanceIdHeader(this.avatarId);
        }

        const newAvatarId = this.createAvatarId();

        if (!newAvatarId) {
            return {};
        }

        return createXInstanceIdHeader(this.avatarId);
    }

    generateAvatarId() {
        try {
            const generatedUuid = uuidV5(uuidV4(), AVATAR_UUID_NAMESPACE);

            if (!generatedUuid) {
                this.$errorHandler.captureDomainError(
                    CLIENT_IDENTITY_ERROR_DOMAIN,
                    new Error('Problem with generate uuidV5 for avatarID'),
                    {
                        [ERROR_ACTION_TAG_NAME]: 'Avatar.generateAvatarId',
                    }
                );
            }

            return generatedUuid;
        } catch (err) {
            this.$errorHandler.captureDomainError(CLIENT_IDENTITY_ERROR_DOMAIN, err, {
                [ERROR_ACTION_TAG_NAME]: 'Avatar.generateAvatarId',
            });
        }

        return null;
    }

    setAvatarIdInCookie = avatarId => {
        if (!avatarId) {
            this.$errorHandler.captureDomainError(
                CLIENT_IDENTITY_ERROR_DOMAIN,
                new Error('Missing avatarId in setAvatarIdInCookie method'),
                {
                    [ERROR_ACTION_TAG_NAME]: 'Avatar.setAvatarIdInCookie',
                },
                {
                    avatarId,
                }
            );

            return;
        }

        this.$cookies.set(AVATAR_ID_COOKIE_NAME, avatarId, COOKIE_OPTIONS);
        this.$cookies.set(IS_NEW_AVATAR_ID_COOKIE_NAME, '1', COOKIE_OPTIONS);
    };

    setAvatarIdInLocalStorage = avatarId => {
        // localStorage is a fallback if avatarId in the cookies can't be saved
        if (!avatarId) {
            this.$errorHandler.captureDomainError(
                CLIENT_IDENTITY_ERROR_DOMAIN,
                new Error('Missing avatarId in setAvatarIdInLocalStorage method'),
                {
                    [ERROR_ACTION_TAG_NAME]: 'Avatar.setAvatarIdInLocalStorage',
                },
                {
                    avatarId,
                }
            );

            return;
        }

        this.$storage?.setItem(FALLBACK_AVATAR_ID, avatarId);
        this.$storage?.setItem(IS_NEW_AVATAR_ID, '1');
    };

    onConnectClientSuccess() {
        this.$cookies.remove(IS_NEW_AVATAR_ID_COOKIE_NAME);
        this.$storage?.removeItem(IS_NEW_AVATAR_ID);
    }
}

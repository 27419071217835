import { prePaidNormalizersRegistry } from '@normalizers/requests/prepaid-registry';

export const getPrePaidNormalizer = () => {
    return (response, operationName) => {
        if (!operationName) {
            return response;
        }

        const normalizer = prePaidNormalizersRegistry[operationName];

        if (!normalizer) {
            return response;
        }

        return normalizer(response);
    };
};

import { Wallet, Delivery, Clock, Guarantee, Return } from '@modivo-ui/icons/v2/other';

export const PSB_RETURN_POLICY = 'psb_return_policy';

export const ADDRESS = 'ul. Nowy Kisielin - Naukowa 15,\n66-002 Zielona Góra\nPOLAND';

export const PRIVACY_ACCORDION_KEY = 'privacy';

export const GET_PARTNER_QUALITY = ($t, daysForReturnsText) => [
    {
        icon: Guarantee,
        text: $t('Guarantee of originality of products'),
    },
    {
        icon: Wallet,
        text: $t('Safe payments'),
    },
    {
        icon: Return,
        text: daysForReturnsText,
    },
    {
        icon: Clock,
        text: $t('Fast shipping'),
    },
];

export const GET_MODIVO_QUALITY = ($t, daysForReturnsText) => [
    {
        icon: Guarantee,
        text: $t('Guarantee of originality of products'),
    },
    {
        icon: Wallet,
        text: $t('Safe payments'),
    },
    {
        icon: Delivery,
        text: $t('Free delivery from PLN 149'),
    },
    {
        icon: Return,
        text: daysForReturnsText,
    },
    {
        icon: Clock,
        text: $t('Shipping in 24 hours'),
    },
];

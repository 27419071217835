import {
    HTTP_CLIENT_ENDPOINT_NAME_PREPAID,
    GET_ASSIGNED_PRE_PAID_CARDS_OPERATION_NAME,
    ASSIGN_PRE_PAID_TO_CART_OPERATION_NAME,
    UPDATE_ORDER_AMOUNT_OPERATION_NAME,
    CHANGE_CARD_VISIBILITY_OPERATION_NAME,
    DETACH_PRE_PAID_FROM_CART_OPERATION_NAME,
    EXTRA_PAYMENT_METHOD_SET_OPERATION_NAME,
    EXTRA_PAYMENT_METHOD_UN_SET_OPERATION_NAME,
} from '@configs/http-client';

const ENDPOINT_PARAMETER = { endpoint: HTTP_CLIENT_ENDPOINT_NAME_PREPAID };

export default (httpClient, { normalizePrePaidResponse }) => ({
    async getAssignedPrePaidCards({ cartId }) {
        const response = await httpClient.get(`/prepaid/${cartId}`, {
            ...ENDPOINT_PARAMETER,
        });

        return normalizePrePaidResponse(response, GET_ASSIGNED_PRE_PAID_CARDS_OPERATION_NAME);
    },

    async assignPrePaidToCart({ cartId, cardNumber, cardPin, orderAmount, amountToCover }) {
        const response = await httpClient.post(`/prepaid/${cartId}`, {
            ...ENDPOINT_PARAMETER,
            payload: {
                orderAmount: {
                    amount: orderAmount?.amount,
                    currency: orderAmount?.currency,
                },
                ...(amountToCover && { amountToCover }),
                cardNumber,
                cardPin,
            },
        });

        return normalizePrePaidResponse(response, ASSIGN_PRE_PAID_TO_CART_OPERATION_NAME);
    },

    async detachPrePaidFromCart({ cartId, cardNumber }) {
        const response = await httpClient.delete(`/prepaid/${cartId}/${cardNumber}`, {
            ...ENDPOINT_PARAMETER,
        });

        return normalizePrePaidResponse(response, DETACH_PRE_PAID_FROM_CART_OPERATION_NAME);
    },

    async changeCardVisibility({ cartId, prePaidCards = [] }) {
        const response = await httpClient.patch(`/prepaid/${cartId}/visibility`, {
            ...ENDPOINT_PARAMETER,
            payload: {
                prePaidCards,
            },
        });

        return normalizePrePaidResponse(response, CHANGE_CARD_VISIBILITY_OPERATION_NAME);
    },

    async updateOrderAmount({ cartId, orderAmount }) {
        const response = await httpClient.put(`/prepaid/${cartId}/order-amount`, {
            ...ENDPOINT_PARAMETER,
            payload: {
                orderAmount: {
                    amount: orderAmount?.amount,
                    currency: orderAmount?.currency,
                },
            },
        });

        return normalizePrePaidResponse(response, UPDATE_ORDER_AMOUNT_OPERATION_NAME);
    },

    async extraPaymentMethodSet({ cartId, paymentMethodCode }) {
        const response = await httpClient.patch(`/prepaid/${cartId}/extra-payment-method`, {
            ...ENDPOINT_PARAMETER,
            payload: {
                paymentMethodCode,
            },
        });

        return normalizePrePaidResponse(response, EXTRA_PAYMENT_METHOD_SET_OPERATION_NAME);
    },

    async extraPaymentMethodUnSet({ cartId }) {
        const response = await httpClient.patch(`/prepaid/${cartId}/extra-payment-method/unset`, {
            ...ENDPOINT_PARAMETER,
        });

        return normalizePrePaidResponse(response, EXTRA_PAYMENT_METHOD_UN_SET_OPERATION_NAME);
    },

    async getWallet() {
        return httpClient.get('/prepaid/wallet', {
            ...ENDPOINT_PARAMETER,
        });
    },
});

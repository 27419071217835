<script>
import { createNamespacedHelpers } from 'vuex';

import {
    STORAGE_TYPE_SESSION,
    LOYALTY_CLUB_MODAL_AFTER_LOGIN,
    LOYALTY_CLUB_REFERENCE_ID,
} from '@configs/storage';
import {
    SHARE_ID_PREFIX,
    LOYALTY_CLUB_STATUSES,
    LOYALTY_CLUB_DISCOUNTS,
    LOYALTY_CLUB_POINT_RATES,
    STORES_WITH_STATIONARY_SHOPS,
} from '@configs/loyalty-club';
import { MODAL_CUSTOMER_ACCOUNT_NAME } from '@configs/modals';

import { LOYALTY_CLUB_PAGE } from '@router/paths';
import { PREFERENCES_FORM_PAGE_NAME } from '@router/names';

import cookies from '@services/cookies';

const {
    mapGetters: mapCustomerGetters,
    mapState: mapCustomerState,
    mapActions: mapCustomerActions,
} = createNamespacedHelpers('customer');

const { mapGetters: mapLoyaltyClubGetters } = createNamespacedHelpers('loyaltyClub');
const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

const LOYALTY_CLUB_MODAL_AFTER_LOGIN_COOKIE_NAME = cookies.createCookieWithPrefix(
    LOYALTY_CLUB_MODAL_AFTER_LOGIN
);

export default {
    name: 'LoyaltyClubProvider',

    computed: {
        ...mapCustomerGetters(['loyaltyClub', 'isLoggedIn']),
        ...mapCustomerState([
            'fetchingCustomerInProgress',
            'shouldOpenModalLoyaltyClubRegistration',
            'wasModalAfterLoginClosed',
        ]),
        ...mapLoyaltyClubGetters(['pointPrice', 'statusesData']),
        ...mapConfigGetters(['storeCode']),

        showQrCode() {
            return STORES_WITH_STATIONARY_SHOPS.includes(this.storeCode);
        },

        isLoyaltyClubDataLoading() {
            return this.fetchingCustomerInProgress;
        },

        shouldDisplayModalAfterLogin() {
            const isPreferencesPage = this.$route.name === PREFERENCES_FORM_PAGE_NAME;
            const isCustomerAccountModalOpen = this.$modals.isOpen(MODAL_CUSTOMER_ACCOUNT_NAME);

            return (
                this.isLoggedIn &&
                !this.fetchingCustomerInProgress &&
                !this.isSubscribed &&
                !this.wasModalAfterLoginClosed &&
                !this.shouldOpenModalLoyaltyClubRegistration &&
                !isPreferencesPage &&
                !isCustomerAccountModalOpen
            );
        },

        shouldDisplayLoyaltyClubNewStatus() {
            return this.isLoggedIn && this.isSubscribed && this.isStatusChanged;
        },

        isSubscribed() {
            return this.loyaltyClub?.isSubscribed || false;
        },

        cardNumber() {
            return this.loyaltyClub?.cardNumber || '';
        },

        renewDate() {
            return this.loyaltyClub?.renewDate || '';
        },

        isStatusChanged() {
            const { loyaltyClub } = this;

            return loyaltyClub && loyaltyClub.previousStatus.length > 0
                ? loyaltyClub.status !== loyaltyClub.previousStatus
                : false;
        },

        status() {
            return this.loyaltyClub?.status || '';
        },

        previousStatus() {
            return this.loyaltyClub?.previousStatus || '';
        },

        points() {
            return this.loyaltyClub?.points || 0;
        },

        shareId() {
            const { loyaltyClub } = this;

            if (loyaltyClub) {
                const shareParam = `?${SHARE_ID_PREFIX}=${loyaltyClub.shareId}`;

                return `${window.location.origin}${LOYALTY_CLUB_PAGE}${shareParam}`;
            }

            return '';
        },

        pointPriceFactor() {
            return this.pointPrice || 1;
        },

        statusesWithDiscountRates() {
            const { statusesData } = this;

            if (statusesData.length) {
                return statusesData;
            }

            return LOYALTY_CLUB_STATUSES.map(status => {
                return {
                    code: status,
                    points: LOYALTY_CLUB_POINT_RATES[status],
                    discount_rate: LOYALTY_CLUB_DISCOUNTS[status],
                };
            });
        },

        statusesWithDiscounts() {
            const { statusesWithDiscountRates } = this;

            if (statusesWithDiscountRates.length) {
                return statusesWithDiscountRates.reduce((statuses, status) => {
                    return {
                        ...statuses,
                        [status.code]: status.discount_rate,
                    };
                }, {});
            }

            return LOYALTY_CLUB_STATUSES.reduce((statuses, status) => {
                return {
                    ...statuses,
                    [status]: LOYALTY_CLUB_DISCOUNTS[status],
                };
            }, {});
        },

        statuses() {
            const { statusesWithDiscountRates } = this;

            if (statusesWithDiscountRates.length) {
                return statusesWithDiscountRates.map(({ code }) => code);
            }

            return LOYALTY_CLUB_STATUSES;
        },

        statusColor() {
            const { status } = this;

            return status ? this.$theme.colors[`mfc-${status}`] : '';
        },

        statusDiscountValue() {
            const { status, statusesWithDiscounts } = this;

            return status ? statusesWithDiscounts[status] : 0;
        },

        qrCode() {
            return this.loyaltyClub?.cardNumberQrCode || '';
        },

        isLoyaltyClubActive() {
            return this.$loyaltyClub.isActive;
        },
    },

    beforeMount() {
        this.setWasModalAfterLoginClosed(
            !!this.$cookies.get(LOYALTY_CLUB_MODAL_AFTER_LOGIN_COOKIE_NAME)
        );

        const referenceId = this.$route.query[SHARE_ID_PREFIX];

        if (referenceId) {
            this.$storage.setItem(
                LOYALTY_CLUB_REFERENCE_ID,
                referenceId,
                null,
                STORAGE_TYPE_SESSION
            );
        }
    },

    methods: {
        ...mapCustomerActions([
            'setShouldOpenModalLoyaltyClubRegistrationFlag',
            'setShouldOpenModalAfterLoyaltyClubRegistrationFlag',
            'setShouldOpenModalShareLoyaltyClubFlag',
            'setWasModalAfterLoginClosed',
            'noticeLoyaltyClubStatus',
        ]),
        closeModalAfterLogin() {
            this.$cookies.set(LOYALTY_CLUB_MODAL_AFTER_LOGIN_COOKIE_NAME, '1', {
                path: '/',
            });

            this.setWasModalAfterLoginClosed(true);
        },

        openModalAfterLoyaltyClubRegistration() {
            this.setShouldOpenModalAfterLoyaltyClubRegistrationFlag(true);
        },

        closeModalAfterLoyaltyClubRegistration() {
            this.setShouldOpenModalAfterLoyaltyClubRegistrationFlag(false);
            this.setShouldOpenModalLoyaltyClubRegistrationFlag(false);
        },

        closeNewStatusModal() {
            this.noticeLoyaltyClubStatus(false);
        },

        openModalShareLoyaltyClub() {
            this.setShouldOpenModalShareLoyaltyClubFlag(true);
        },

        closeShareModal() {
            this.setShouldOpenModalShareLoyaltyClubFlag(false);
        },

        openLoyaltyClubRegistrationModal() {
            this.closeModalAfterLogin();
            this.setShouldOpenModalLoyaltyClubRegistrationFlag(true);
        },
        closeLoyaltyClubRegisterModal() {
            this.setShouldOpenModalLoyaltyClubRegistrationFlag(false);
        },
    },

    render() {
        const {
            isLoyaltyClubDataLoading,
            loyaltyClub,
            shouldDisplayModalAfterLogin,
            closeModalAfterLogin,
            shouldDisplayLoyaltyClubNewStatus,
            closeNewStatusModal,
            openModalAfterLoyaltyClubRegistration,
            closeModalAfterLoyaltyClubRegistration,
            previousStatus,
            status,
            isSubscribed,
            cardNumber,
            renewDate,
            points,
            statusesWithDiscounts,
            openLoyaltyClubRegistrationModal,
            closeLoyaltyClubRegisterModal,
            statusColor,
            statusDiscountValue,
            qrCode,
            statusesWithDiscountRates,
            openModalShareLoyaltyClub,
            closeShareModal,
            shareId,
            statuses,
            pointPriceFactor,
            showQrCode,
            isLoyaltyClubActive,
        } = this;

        return this.$scopedSlots.default({
            isLoyaltyClubDataLoading,
            loyaltyClub,
            shouldDisplayModalAfterLogin,
            closeModalAfterLogin,
            shouldDisplayLoyaltyClubNewStatus,
            closeNewStatusModal,
            openModalAfterLoyaltyClubRegistration,
            closeModalAfterLoyaltyClubRegistration,
            previousStatus,
            status,
            isSubscribed,
            cardNumber,
            renewDate,
            points,
            statusesWithDiscounts,
            openLoyaltyClubRegistrationModal,
            closeLoyaltyClubRegisterModal,
            statusColor,
            statusDiscountValue,
            qrCode,
            statusesWithDiscountRates,
            openModalShareLoyaltyClub,
            closeShareModal,
            shareId,
            statuses,
            pointPriceFactor,
            showQrCode,
            isLoyaltyClubActive,
        });
    },
};
</script>

export const MODIVO_CLUB_GOLD_SKU = '2230084961333-NOSIZE';
export const MODIVO_CLUB_GOLD_PARENT_SKU = '2230084961333';
export const MODIVO_CLUB_GOLD_OFFER_ID = '307cdcfb-436f-5120-b6d8-9107eab95cc2';
export const MODIVO_STORE_ID = '0556f5e3-a3bb-5fdc-9db0-9e00645acfdb';

export const IS_MODIVO_CLUB_ELEMENT_ENABLED = false;

export const MODIVO_CLUB_GIFTCARD_PAGE_CMS_BLOCK_ID = 'psb_modivo_club_giftcard_page';

export const MODIVO_CLUB_GOLD_ADD_TO_CART_DATA = {
    data: {
        quantity: 1,
        sku: MODIVO_CLUB_GOLD_SKU,
        offer_id: MODIVO_CLUB_GOLD_OFFER_ID,
        store_id: MODIVO_STORE_ID,
    },
    parent_sku: MODIVO_CLUB_GOLD_PARENT_SKU,
};

const PSB_MODIVO_CLUB_CUSTOMER_FAQ_TEXT_PREFIX = 'psb_modivo_club_customer_faq_text_';
const MODIVO_CLUB_CUSTOMER_FAQ_LIMIT = 5;

export const MODIVO_CLUB_CUSTOMER_FAQ_TEXT_BLOCKS_IDS = Array.from(
    { length: MODIVO_CLUB_CUSTOMER_FAQ_LIMIT },
    (element, index) => `${PSB_MODIVO_CLUB_CUSTOMER_FAQ_TEXT_PREFIX}${index + 1}`
);

export const MODIVO_CLUB_CUSTOMER_PAGE_CMS_BLOCKS_IDS = [
    ...MODIVO_CLUB_CUSTOMER_FAQ_TEXT_BLOCKS_IDS,
];

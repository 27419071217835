<template>
    <div class="copyright">
        {{ '©' }}
        {{ brandName }}
        {{ year }}
    </div>
</template>

<script>
import { BRAND_SITE_NAME } from '@localeConfig/keys';

export default {
    name: 'Copyright',

    computed: {
        brandName() {
            return this.$configProvider.getConfig(BRAND_SITE_NAME);
        },
    },

    beforeCreate() {
        this.year = new Date().getFullYear();
    },
};
</script>

<style lang="scss" scoped>
.copyright {
    @apply flex justify-center items-center;
    @apply font-ui-body-s;
    @apply text-ds-content-disabled;
}
</style>

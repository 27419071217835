import Vue from 'vue';

import { isModivoClubDevModeEnabled } from '@modivo-club-assets/modivo-club';

import DevPanel from '@modivo-club-organisms/DevPanel';

export default ({ app }) => {
    if (process.client && isModivoClubDevModeEnabled(app.$abTests)) {
        const DevPanelClass = Vue.extend(DevPanel);
        const instance = new DevPanelClass({ store: app.store });

        instance.$mount();
        document.body.appendChild(instance.$el);
    }
};

import {
    ATTRIBUTE_TYPE_SIMPLE_SELECT,
    ATTRIBUTE_TYPE_MULTI_SELECT,
    ATTRIBUTE_TYPE_REFERENCE_ENTITY,
    ATTRIBUTE_TYPE_REFERENCE_ENTITY_COLLECTION,
} from '@configs/product-attribute-types';

import { flatMap } from '@assets/async';

const normalizeFieldValue = async (
    fieldValue,
    { type },
    { dateHelper, timezone, dateFormat } = {}
) => {
    const defaultValue = null;

    if (typeof fieldValue === 'string' && type === Date && dateHelper) {
        const isValid = await dateHelper.isValid(fieldValue, dateFormat);

        if (!isValid) {
            return defaultValue;
        }

        return dateHelper?.createDateFromStringInTimezone(fieldValue, dateFormat, timezone);
    }

    if (typeof fieldValue === 'number' && type === Number) {
        const number = parseInt(fieldValue, 10);

        return Number.isNaN(number) ? defaultValue : number;
    }

    return fieldValue?.trim() || defaultValue;
};

export const getNormalizedAttributeModelValues = async (
    attributeModel,
    attributeValues,
    normalizerOptions,
    attributeValueCallback = null
) => {
    const mapped = await flatMap(Object.entries(attributeModel), async ([key, model]) => {
        let attributeValue = attributeValues[key];

        if (typeof attributeValueCallback === 'function') {
            attributeValue = attributeValueCallback(attributeValue, key, model, attributeValues);
        }

        const value = await normalizeFieldValue(attributeValue, model, normalizerOptions);

        const entries = [[key, value]];

        if (model.keepRaw === true) {
            entries.push([`${key}_raw`, attributeValue]);
        }

        return entries;
    });

    return Object.fromEntries(mapped);
};

const getSimpleSelectValue = item => {
    const {
        value,
        attribute: { filterable },
    } = item;

    return filterable ? [item] : value.label;
};

const getMultiSelectValue = item => {
    const {
        value,
        attribute,
        attribute: { filterable },
    } = item;

    const valuesArray = Object.values(value);

    if (filterable) {
        return valuesArray.map(itemValue => ({
            attribute,
            value: itemValue,
        }));
    }

    return valuesArray.map(({ label }) => label).join(', ');
};

const getReferenceEntityValue = (item, locale) => {
    const {
        value,
        attribute,
        attribute: { filterable },
    } = item;

    const valueForLocale = value?.[locale];

    if (!valueForLocale) {
        return null;
    }

    if (filterable) {
        return [
            {
                attribute,
                value: {
                    ...valueForLocale,
                    label: valueForLocale?.values?.label_text?.[locale] || valueForLocale?.label,
                },
            },
        ];
    }

    return valueForLocale?.values?.label_text?.[locale] || valueForLocale?.label;
};

const getReferenceEntityCollectionValue = (item, locale) => {
    return Object.values(item.value)
        .map(({ label, values = {} }) => values.label?.[locale] || label)
        .join(', ');
};

const getDefaultAttributeValue = (item, locale) => {
    const {
        value,
        formatted,
        attribute: { localizable },
    } = item;

    return localizable ? value[locale] : formatted || value;
};

export const getAttributeValue = (item, locale) => {
    const { type } = item.attribute || {};

    const TYPE_VALUE_FUNCTION_MAP = {
        [ATTRIBUTE_TYPE_SIMPLE_SELECT]: getSimpleSelectValue,
        [ATTRIBUTE_TYPE_MULTI_SELECT]: getMultiSelectValue,
        [ATTRIBUTE_TYPE_REFERENCE_ENTITY]: getReferenceEntityValue,
        [ATTRIBUTE_TYPE_REFERENCE_ENTITY_COLLECTION]: getReferenceEntityCollectionValue,
    };

    return (TYPE_VALUE_FUNCTION_MAP[type] || getDefaultAttributeValue)(item, locale);
};

import { BASE_ACCOUNT_LINKS, NEW_RMA_LIST_ACCOUNT_LINK } from '@configs/account-links';

export default ($t, $tPath) => {
    return [...BASE_ACCOUNT_LINKS, NEW_RMA_LIST_ACCOUNT_LINK]
        .map(item => {
            const { name, path, useTranslatePath = false, showBadgeNew = false } = item;

            return {
                ...item,
                showBadgeNew,
                name: $t(name),
                path: useTranslatePath ? $tPath(path) : path,
            };
        })
        .sort((a, b) => {
            if (typeof a.order !== 'number') {
                return 1;
            }

            if (typeof b.order !== 'number') {
                return -1;
            }

            return a.order - b.order;
        });
};

export const types = {
    SET_MEMBERSHIP_INFO: 'SET_MEMBERSHIP_INFO',
    UPDATE_CASHBACK_CLUB_CARD: 'UPDATE_CASHBACK_CLUB_CARD',
};

export default {
    [types.SET_MEMBERSHIP_INFO](state, { status, subscriptionExpiresAt }) {
        state.membershipStatus = status;
        state.membershipExpire = subscriptionExpiresAt;
    },

    [types.UPDATE_CASHBACK_CLUB_CARD](state, cashbackCardData) {
        state.cashbackCardData = cashbackCardData;
    },
};

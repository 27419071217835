<template>
    <Skeleton v-if="!logo" width="100%" height="36px" />
    <Icon v-else :icon="logo" width="100%" height="36" class="brand-logo" />
</template>

<script>
import { BRAND_LOGO } from '@localeConfig/keys';

import { Icon } from '@modivo-ui/components/Icon/v1';
import { Skeleton } from '@modivo-ui/components/Skeleton/v2';

export default {
    name: 'MobileAppQrCode',

    components: {
        Icon,
        Skeleton,
    },

    data() {
        return {
            logo: false,
        };
    },

    async mounted() {
        const svg = this.$configProvider.getConfig(BRAND_LOGO);
        const fileName = svg.split('/').pop();

        const logo = await import(
            `@static/brands/${this.$configProvider.brand}/logo/${fileName}?inline`
        );

        this.logo = logo.default;
    },
};
</script>

<style lang="scss" scoped>
.brand-logo {
    @apply w-ui-auto;

    &:deep(path) {
        fill: rgba(var(--ds-color-content-primary), 1);
    }
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/actions.js'), 'actions.js')
  resolveStoreModules(require('../store/getters.js'), 'getters.js')
  resolveStoreModules(require('../store/mutations.js'), 'mutations.js')
  resolveStoreModules(require('../store/validation/index.js'), 'validation/index.js')
  resolveStoreModules(require('../store/synerise/index.js'), 'synerise/index.js')
  resolveStoreModules(require('../store/recentlyViewedProducts/index.js'), 'recentlyViewedProducts/index.js')
  resolveStoreModules(require('../store/pwa/index.js'), 'pwa/index.js')
  resolveStoreModules(require('../store/product/index.js'), 'product/index.js')
  resolveStoreModules(require('../store/payments/index.js'), 'payments/index.js')
  resolveStoreModules(require('../store/modivoClub/index.js'), 'modivoClub/index.js')
  resolveStoreModules(require('../store/modal/index.js'), 'modal/index.js')
  resolveStoreModules(require('../store/messages/index.js'), 'messages/index.js')
  resolveStoreModules(require('../store/loyaltyClub/index.js'), 'loyaltyClub/index.js')
  resolveStoreModules(require('../store/layout/index.js'), 'layout/index.js')
  resolveStoreModules(require('../store/i18n/index.js'), 'i18n/index.js')
  resolveStoreModules(require('../store/gdpr/index.js'), 'gdpr/index.js')
  resolveStoreModules(require('../store/customer/index.js'), 'customer/index.js')
  resolveStoreModules(require('../store/consents/index.js'), 'consents/index.js')
  resolveStoreModules(require('../store/config/index.js'), 'config/index.js')
  resolveStoreModules(require('../store/cmsBlock/index.js'), 'cmsBlock/index.js')
  resolveStoreModules(require('../store/cart/index.js'), 'cart/index.js')
  resolveStoreModules(require('../store/avatar/index.js'), 'avatar/index.js')
  resolveStoreModules(require('../store/avatar/actions.js'), 'avatar/actions.js')
  resolveStoreModules(require('../store/avatar/getters.js'), 'avatar/getters.js')
  resolveStoreModules(require('../store/avatar/mutations.js'), 'avatar/mutations.js')
  resolveStoreModules(require('../store/cmsBlock/actions.js'), 'cmsBlock/actions.js')
  resolveStoreModules(require('../store/cmsBlock/getters.js'), 'cmsBlock/getters.js')
  resolveStoreModules(require('../store/cmsBlock/mutations.js'), 'cmsBlock/mutations.js')
  resolveStoreModules(require('../store/config/actions.js'), 'config/actions.js')
  resolveStoreModules(require('../store/config/getters.js'), 'config/getters.js')
  resolveStoreModules(require('../store/config/mutations.js'), 'config/mutations.js')
  resolveStoreModules(require('../store/config/state.js'), 'config/state.js')
  resolveStoreModules(require('../store/consents/actions.js'), 'consents/actions.js')
  resolveStoreModules(require('../store/consents/mutations.js'), 'consents/mutations.js')
  resolveStoreModules(require('../store/customer/actions.js'), 'customer/actions.js')
  resolveStoreModules(require('../store/customer/getters.js'), 'customer/getters.js')
  resolveStoreModules(require('../store/customer/mutations.js'), 'customer/mutations.js')
  resolveStoreModules(require('../store/gdpr/actions.js'), 'gdpr/actions.js')
  resolveStoreModules(require('../store/gdpr/mutations.js'), 'gdpr/mutations.js')
  resolveStoreModules(require('../store/i18n/actions.js'), 'i18n/actions.js')
  resolveStoreModules(require('../store/i18n/mutations.js'), 'i18n/mutations.js')
  resolveStoreModules(require('../store/i18n/state.js'), 'i18n/state.js')
  resolveStoreModules(require('../store/layout/actions.js'), 'layout/actions.js')
  resolveStoreModules(require('../store/layout/mutations.js'), 'layout/mutations.js')
  resolveStoreModules(require('../store/layout/state.js'), 'layout/state.js')
  resolveStoreModules(require('../store/loyaltyClub/actions.js'), 'loyaltyClub/actions.js')
  resolveStoreModules(require('../store/loyaltyClub/getters.js'), 'loyaltyClub/getters.js')
  resolveStoreModules(require('../store/loyaltyClub/mutations.js'), 'loyaltyClub/mutations.js')
  resolveStoreModules(require('../store/messages/actions.js'), 'messages/actions.js')
  resolveStoreModules(require('../store/messages/mutations.js'), 'messages/mutations.js')
  resolveStoreModules(require('../store/messages/state.js'), 'messages/state.js')
  resolveStoreModules(require('../store/modal/actions.js'), 'modal/actions.js')
  resolveStoreModules(require('../store/modal/mutations.js'), 'modal/mutations.js')
  resolveStoreModules(require('../store/modal/state.js'), 'modal/state.js')
  resolveStoreModules(require('../store/product/actions.js'), 'product/actions.js')
  resolveStoreModules(require('../store/product/getters.js'), 'product/getters.js')
  resolveStoreModules(require('../store/product/mutations.js'), 'product/mutations.js')
  resolveStoreModules(require('../store/pwa/actions.js'), 'pwa/actions.js')
  resolveStoreModules(require('../store/pwa/getters.js'), 'pwa/getters.js')
  resolveStoreModules(require('../store/pwa/mutations.js'), 'pwa/mutations.js')
  resolveStoreModules(require('../store/pwa/state.js'), 'pwa/state.js')
  resolveStoreModules(require('../store/recentlyViewedProducts/actions.js'), 'recentlyViewedProducts/actions.js')
  resolveStoreModules(require('../store/recentlyViewedProducts/mutations.js'), 'recentlyViewedProducts/mutations.js')
  resolveStoreModules(require('../store/recentlyViewedProducts/state.js'), 'recentlyViewedProducts/state.js')
  resolveStoreModules(require('../store/synerise/actions.js'), 'synerise/actions.js')
  resolveStoreModules(require('../store/synerise/mutations.js'), 'synerise/mutations.js')
  resolveStoreModules(require('../store/synerise/state.js'), 'synerise/state.js')
  resolveStoreModules(require('../store/validation/actions.js'), 'validation/actions.js')
  resolveStoreModules(require('../store/validation/getters.js'), 'validation/getters.js')
  resolveStoreModules(require('../store/validation/mutations.js'), 'validation/mutations.js')
  resolveStoreModules(require('../store/validation/state.js'), 'validation/state.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}

import {
    CUSTOMER_ADDRESS_KEY,
    LOGIN_METHOD,
    LOGIN_TOOLTIP_SHOWN_COUNTER,
    IS_LOGIN_TOOLTIP_SHOWN,
    WISHLIST_PRODUCT_TO_ADD_AFTER_LOGIN,
} from '@configs/storage';
import { LOCAL_WISHLIST_LIMIT } from '@configs/wishlist';
import {
    AVAILABLE_SOCIAL_PROVIDERS,
    FACEBOOK_PROVIDER,
    GOOGLE_PROVIDER,
} from '@configs/auth-providers';
import { isRequiredAccountForWishlist } from '@configs/abtest-required-account-for-wishlist';
import { SHOW_WISHLIST_LOGIN_HINT, MODAL_CUSTOMER_ACCOUNT_NAME, MODAL_TYPE } from '@configs/modals';
import { LIMIT_FOR_SHOW_TOOLTIP_LOGIN, TIME_TO_SHOW_TOOLTIP_WITH_LOGIN } from '@configs/tooltips';

import Deferred from '@core-assets/deferred';
import { saveUserIdForGTM } from '@assets/gtm-native-enhanced-conversion';

import { LOGIN_PAGE } from '@router/paths';

import { ERROR_ANALYTICS_TAG_NAME } from '@analytics-types/Errors';
import {
    INACTIVE_TOKEN_AUTO_LOGOUT,
    THRUD_ERROR_AUTO_LOGOUT,
    WISHLIST_ADD,
    WISHLIST_REMOVE,
} from '@analytics-types/Events';
import {
    USER_LOGIN_LABEL_EMAIL,
    USER_LOGIN_LABEL_FACEBOOK,
    USER_LOGIN_LABEL_GOOGLE,
} from '@analytics-types/Analytics';
import { ERROR_ACTION_TAG_NAME } from '@types/Errors';
import { LOGIN } from '@types/CustomerModal';

import DataLayerProduct from '@models/Analytics/DataLayerProduct';

import { shouldRefreshToken } from '@assets/auth';
import { isShowLimitExceeded, setTooltipDataInStorage } from '@assets/tooltip';
import { isModivoClubTestEnabled } from '@modivo-club-assets/modivo-club';

import { types } from './mutations';

const MODULE_NAME = 'customer';

const removeCustomerDataFromStorage = context =>
    context.app.$storage.removeItem(CUSTOMER_ADDRESS_KEY);

const emitWishlistEvent = async (eventName, { app, rootGetters, product }) => {
    const totalCountGetter = rootGetters['product/getProductReviewTotalCount'];
    const averageRating = rootGetters['product/getProductReviewAverageRating'];

    const productReviewTotalCount = totalCountGetter(product.sku);

    const productData = {
        product,
        variantId: product.variants?.[product.chosenSize]?.id || '',
    };

    if (productReviewTotalCount && averageRating) {
        productData.reviews = {
            totalCountAll: productReviewTotalCount,
            averageRatings: [{ value: averageRating }],
        };
    }

    const dataLayerProduct = new DataLayerProduct(productData).build();

    if (!dataLayerProduct) {
        return;
    }

    try {
        app.$analytics.emit(eventName, {
            sku: product.sku,
            currency: product.currency,
            product: dataLayerProduct,
            pageType: product.pageType,
            actionFieldList: product.actionField,
        });
    } catch (err) {
        app.$errorHandler.captureStoreError(
            MODULE_NAME,
            err,
            {
                [ERROR_ACTION_TAG_NAME]: 'analytics',
                [ERROR_ANALYTICS_TAG_NAME]: eventName,
            },
            {
                product,
            }
        );
    }
};

const emitAddToWishlistEvent = async context => {
    await emitWishlistEvent(WISHLIST_ADD, context);
};

const emitRemoveFromWishlistEvent = async context => {
    await emitWishlistEvent(WISHLIST_REMOVE, context);
};

let refreshTokenDeferred = null;

export default {
    async mergeLocalWishlist({ state, commit }) {
        const { wishlist } = state;

        if (wishlist.length === 0) {
            return;
        }

        const { isAdded } = await this.app.$services.customer.addWishlistProducts(wishlist);

        if (!isAdded) {
            return;
        }

        const { customerWishlist } = await this.$services.customer.getCustomerWishlist(
            LOCAL_WISHLIST_LIMIT,
            1
        );

        commit(types.SET_CUSTOMER_WISHLIST_COUNT, customerWishlist.length);
        this.app.$localWishlist.clear();
    },

    async clearCustomerWishlist({ commit }) {
        const { isCleared } = await this.app.$services.customer.clearWishlist();

        if (isCleared) {
            commit(types.SET_CUSTOMER_WISHLIST_COUNT, 0);
        }
    },

    clearLocalWishlist({ commit }) {
        commit(types.SET_WISHLIST, []);
        this.app.$localWishlist.clear();
    },

    initLocalWishlist({ commit }) {
        commit(types.SET_WISHLIST, this.app.$localWishlist.get());
    },

    syncLocalWishlist({ state }) {
        this.app.$localWishlist.set(state.wishlist.slice(0, LOCAL_WISHLIST_LIMIT));
    },

    addLocalWishlistPrice(_, wishlistPrice) {
        this.app.$localWishlist.addWishlistPrice(wishlistPrice);
    },

    removeLocalWishlistPriceBySku(_, sku) {
        this.app.$localWishlist.removeWishlistPriceBySku(sku);
    },

    getWishlistPrices() {
        return this.app.$localWishlist.getWishlistPrices();
    },

    setWishlist({ commit }, wishlist = []) {
        commit(types.SET_WISHLIST, wishlist);
    },

    async checkWishlist({ state, commit, getters }, skus = []) {
        if (!getters.isLoggedIn) {
            return;
        }

        const wishlistProducts = await this.app.$services.customer.wishlistProducts(skus);

        commit(types.SET_WISHLIST, [...state.wishlist, ...wishlistProducts]);
    },

    async addToWishlist({ commit, dispatch, rootGetters, getters }, product) {
        const { sku, price } = product;
        const emitAddToWishlist = emitAddToWishlistEvent.bind(this, {
            app: this.app,
            rootGetters,
            product,
        });

        if (!getters.isLoggedIn) {
            if (isRequiredAccountForWishlist(this.$abTests)) {
                this.$storage.setItem(WISHLIST_PRODUCT_TO_ADD_AFTER_LOGIN, product);

                await this.$modals.open(MODAL_CUSTOMER_ACCOUNT_NAME, {
                    [MODAL_TYPE]: LOGIN,
                    [SHOW_WISHLIST_LOGIN_HINT]: true,
                });

                return false;
            }

            commit(types.ADD_TO_WISHLIST, [sku]);

            dispatch('addLocalWishlistPrice', { sku, price });
            dispatch('syncLocalWishlist');

            emitAddToWishlist();

            dispatch('setInitLoginTooltip', true);
        } else {
            const { isAdded } = await this.app.$services.customer.addWishlistProducts([sku]);

            if (!isAdded) {
                dispatch(
                    'messages/addErrorMessage',
                    {
                        text: this.app.i18n.t(
                            'Adding to wishlist failed. Please try again in a moment.'
                        ),
                    },
                    { root: true }
                );

                return false;
            }

            commit(types.ADD_TO_WISHLIST, [sku]);
            commit(types.INCREMENT_CUSTOMER_WISHLIST_COUNT);
            emitAddToWishlist();
        }

        return true;
    },

    async removeFromWishlist({ commit, dispatch, rootGetters, getters }, product) {
        const { sku } = product;
        const emitRemoveFromWishlist = emitRemoveFromWishlistEvent.bind(this, {
            app: this.app,
            rootGetters,
            product,
        });

        if (!getters.isLoggedIn) {
            commit(types.REMOVE_FROM_WISHLIST, sku);

            dispatch('removeLocalWishlistPriceBySku', sku);
            dispatch('syncLocalWishlist');

            emitRemoveFromWishlist();
        } else {
            const { isDeleted } = await this.app.$services.customer.deleteWishlistProducts([sku]);

            if (!isDeleted) {
                dispatch(
                    'messages/addErrorMessage',
                    {
                        text: this.app.i18n.t(
                            'Removal from wishlist failed. Please try again in a moment.'
                        ),
                    },
                    { root: true }
                );

                return;
            }

            commit(types.REMOVE_FROM_WISHLIST, sku);
            commit(types.DECREMENT_CUSTOMER_WISHLIST_COUNT);
            emitRemoveFromWishlist();
        }
    },

    async handleLoggedCustomer({ dispatch, rootGetters, getters }, type) {
        const { $errorHandler, $storage } = this.app;

        saveUserIdForGTM({ userId: getters.customerUuid, $errorHandler, $storage });
        removeCustomerDataFromStorage(this);

        dispatch('setLoginTooltipVisibility', false);

        if (rootGetters['config/isAccountVerificationEnabled']) {
            await dispatch(
                'avatar/checkAccountVerificationStatus',
                { callPlace: 'handleLoggedCustomer' },
                {
                    root: true,
                }
            );
        }

        await dispatch('mergeLocalWishlist');

        await dispatch('cart/handleCustomerCart', null, {
            root: true,
        });

        this.$storage.setItem(LOGIN_METHOD, type);

        if (!this.$avatar.avatarId) {
            this.$avatar.createAvatarId();
        }

        dispatch('avatar/connectClient', this.$avatar.avatarId, { root: true });
    },

    async login({ dispatch, getters, rootGetters }, { email, password, isCartRoute = false }) {
        const ERROR_TAG = 'customer.login';
        let result = true;

        dispatch('setFetchingCustomerInProgressFlag', true);

        const showErrorMessage = () => {
            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t('Some error occured during logging in with email.'),
                    timeout: 10000,
                },
                { root: true }
            );
        };

        try {
            const {
                accessToken,
                refreshToken,
                accessTokenExpirationISO,
                refreshTokenExpirationISO,
            } = await this.app.$services.customer.generateAuthToken(email, password);

            if (
                !accessToken ||
                !refreshToken ||
                !accessTokenExpirationISO ||
                !refreshTokenExpirationISO
            ) {
                result = false;

                showErrorMessage();

                dispatch('setFetchingCustomerInProgressFlag', false);

                return result;
            }

            await dispatch('saveAuthTokens', {
                accessToken,
                refreshToken,
                accessTokenExpirationISO,
                refreshTokenExpirationISO,
            });

            const customerData = await this.app.$services.customer.getCustomer();

            await dispatch('setCustomerData', customerData);

            if (!getters.isLoggedIn) {
                throw new Error('Unsuccessful get customer data in login');
            }

            const results = await Promise.allSettled([
                dispatch('setIsCustomerSubscribedToNewsletter'),
                dispatch('handleLoggedCustomer', USER_LOGIN_LABEL_EMAIL),
            ]);

            results.forEach(({ status, reason }) => {
                if (status === 'rejected') {
                    this.app.$errorHandler.captureStoreError(MODULE_NAME, reason, {
                        [ERROR_ACTION_TAG_NAME]: ERROR_TAG,
                    });
                }
            });

            dispatch(
                'messages/addSuccessMessage',
                { text: this.app.i18n.t('Successful login.') },
                { root: true }
            );

            if (isModivoClubTestEnabled(this.$abTests)) {
                await dispatch('modivoClub/handleFetchMembershipAndWallet', null, { root: true });

                if (!rootGetters['cart/isModivoClubGoldCardInCart'] || !isCartRoute) {
                    dispatch('modivoClub/handleRegistrationModal', null, { root: true });
                }
            }
        } catch (err) {
            this.app.$errorHandler.captureStoreError(MODULE_NAME, err, {
                [ERROR_ACTION_TAG_NAME]: ERROR_TAG,
            });

            showErrorMessage();

            result = false;
        }

        dispatch('setFetchingCustomerInProgressFlag', false);

        return result;
    },

    async socialLogin(
        { dispatch, getters, rootGetters },
        { token, provider, isCartRoute = false }
    ) {
        const ERROR_TAG = 'customer.socialLogin';
        let result = {
            loginSuccessful: false,
            isRegistration: false,
        };

        if (!provider || !token || !AVAILABLE_SOCIAL_PROVIDERS.includes(provider)) {
            return result;
        }

        const {
            generateAuthTokenWithFacebook,
            generateAuthTokenWithGoogle,
        } = this.app.$services.customer;
        const { i18n } = this.app;

        const AUTH = {
            [FACEBOOK_PROVIDER]: generateAuthTokenWithFacebook,
            [GOOGLE_PROVIDER]: generateAuthTokenWithGoogle,
        };
        const PROVIDERS_DATA = {
            [FACEBOOK_PROVIDER]: {
                errorMessage: i18n.t('Some error occured during logging in with Facebook'),
                loginMethod: USER_LOGIN_LABEL_FACEBOOK,
                successMessage: i18n.t('Successful Facebook login.'),
            },
            [GOOGLE_PROVIDER]: {
                errorMessage: i18n.t('Some error occured during logging in with Google'),
                loginMethod: USER_LOGIN_LABEL_GOOGLE,
                successMessage: i18n.t('Successful Google login.'),
            },
        };

        dispatch('setFetchingCustomerInProgressFlag', true);

        const providerData = PROVIDERS_DATA[provider];

        try {
            const {
                accessToken,
                refreshToken,
                accessTokenExpirationISO,
                refreshTokenExpirationISO,
                isRegistration,
            } = await AUTH[provider](token);

            result = {
                loginSuccessful:
                    !!accessToken &&
                    !!refreshToken &&
                    !!accessTokenExpirationISO &&
                    !!refreshTokenExpirationISO,
                isRegistration,
            };

            if (result.loginSuccessful) {
                await dispatch('saveAuthTokens', {
                    accessToken,
                    refreshToken,
                    accessTokenExpirationISO,
                    refreshTokenExpirationISO,
                });

                const customerData = await this.app.$services.customer.getCustomer();

                await dispatch('setCustomerData', customerData);

                if (!getters.isLoggedIn) {
                    throw new Error(`Unsuccessful get customer data in login with ${provider}`);
                }

                const results = await Promise.allSettled([
                    dispatch('setIsCustomerSubscribedToNewsletter'),
                    dispatch('handleLoggedCustomer', providerData.loginMethod),
                ]);

                results.forEach(({ status, reason }) => {
                    if (status === 'rejected') {
                        this.app.$errorHandler.captureStoreError(MODULE_NAME, reason, {
                            [ERROR_ACTION_TAG_NAME]: ERROR_TAG,
                        });
                    }
                });

                dispatch(
                    'messages/addSuccessMessage',
                    { text: providerData.successMessage },
                    { root: true }
                );

                if (isModivoClubTestEnabled(this.$abTests)) {
                    await dispatch('modivoClub/handleFetchMembershipAndWallet', null, {
                        root: true,
                    });

                    if (!rootGetters['cart/isModivoClubGoldCardInCart'] || !isCartRoute) {
                        dispatch('modivoClub/handleRegistrationModal', null, { root: true });
                    }
                }
            } else {
                throw new Error(`Unsuccessful ${provider} login.`);
            }
        } catch (err) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: ERROR_TAG,
                },
                {
                    provider,
                }
            );

            dispatch(
                'messages/addErrorMessage',
                {
                    text: providerData.errorMessage,
                },
                { root: true }
            );
            result = {
                loginSuccessful: false,
                isRegistration: false,
            };
        }

        dispatch('setFetchingCustomerInProgressFlag', false);

        return result;
    },

    async loginWithMagicLink({ dispatch, getters }, magicLink) {
        const ERROR_TAG = 'customer.generateAuthTokenWithMagicLink';
        let result = true;

        dispatch('setFetchingCustomerInProgressFlag', true);

        try {
            const {
                success,
                accessToken,
                refreshToken,
                accessTokenExpirationISO,
                refreshTokenExpirationISO,
            } = await this.app.$services.customer.generateAuthTokenWithMagicLink(magicLink);

            if (!success) {
                throw new Error('Unsuccessful login with magicLink');
            }

            await dispatch('saveAuthTokens', {
                accessToken,
                refreshToken,
                accessTokenExpirationISO,
                refreshTokenExpirationISO,
            });

            await dispatch('setCustomerData', await this.app.$services.customer.getCustomer());

            if (!getters.isLoggedIn) {
                throw new Error('Unsuccessful login with magicLink');
            }

            const results = await Promise.allSettled([
                dispatch('setIsCustomerSubscribedToNewsletter'),
                dispatch('handleLoggedCustomer', USER_LOGIN_LABEL_EMAIL),
            ]);

            results.forEach(({ status, reason }) => {
                if (status === 'rejected') {
                    this.app.$errorHandler.captureStoreError(MODULE_NAME, reason, {
                        [ERROR_ACTION_TAG_NAME]: ERROR_TAG,
                    });
                }
            });
        } catch (err) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: ERROR_TAG,
                },
                {
                    error: JSON.stringify(err),
                }
            );

            result = false;
        }

        dispatch('setFetchingCustomerInProgressFlag', false);

        return result;
    },

    async registerCustomer({ dispatch }, registerData) {
        const result = await this.app.$services.customer.createCustomer(registerData);

        if (!result) {
            dispatch(
                'messages/addErrorMessage',
                { text: this.app.i18n.t('Customer register error occured') },
                { root: true }
            );

            return false;
        }

        dispatch(
            'messages/addSuccessMessage',
            {
                title: this.app.i18n.t('Successful register.'),
                text: this.app.i18n.t('We have sent a verification link to your e-mail address.'),
            },
            { root: true }
        );

        return true;
    },

    async saveAuthTokens(
        { dispatch },
        { accessToken, refreshToken, accessTokenExpirationISO, refreshTokenExpirationISO }
    ) {
        if (!accessToken) {
            return;
        }

        await this.$services.auth.saveAuthTokens({
            accessToken,
            refreshToken,
            accessTokenExpirationISO,
            refreshTokenExpirationISO,
        });

        await dispatch('setAuthorizationData', {
            accessToken,
            refreshToken,
            accessTokenExpirationISO,
            refreshTokenExpirationISO,
        });
    },

    async removeAuthTokens({ dispatch }) {
        await this.$services.auth.removeAuthTokens();

        await dispatch('setAuthorizationData', {
            accessToken: null,
            refreshToken: null,
            accessTokenExpirationISO: null,
            refreshTokenExpirationISO: null,
        });
    },

    async logout({ dispatch, rootGetters }, params) {
        const { showSuccessMessage = true, withRevokeTokenRequest = true } = params || {};

        try {
            const { isWebView, hasAuthorizationTokensFromMobileApp } = this.$mobileApp;
            const isMobileToken = isWebView && hasAuthorizationTokensFromMobileApp;

            if (withRevokeTokenRequest && !isMobileToken) {
                await this.app.$services.customer.revokeAuthToken();
            }

            await dispatch('removeAuthTokens');

            dispatch('initLocalWishlist');

            removeCustomerDataFromStorage(this);
        } catch (err) {
            this.app.$errorHandler.captureStoreError(MODULE_NAME, err, {
                [ERROR_ACTION_TAG_NAME]: 'customer.revokeAuthToken',
            });

            dispatch('messages/addErrorMessage', { text: err }, { root: true });
        }

        dispatch('setShouldOpenModalLoyaltyClubRegistrationFlag', false);
        await dispatch('setCustomerData', null);
        await dispatch('setIsCustomerSubscribedToNewsletter');
        dispatch('setIsRegistrationBySocialMedia', false);

        if (isModivoClubTestEnabled(this.$abTests)) {
            dispatch('modivoClub/clearMembershipInfo', null, { root: true });
            dispatch('modivoClub/clearWallet', null, { root: true });
            dispatch('cart/clearGiftCards', null, { root: true });
        }

        if (rootGetters['config/isAccountVerificationEnabled']) {
            dispatch('avatar/clearAccountVerificationData', null, {
                root: true,
            });
        }

        this.$storage.removeItem(LOGIN_METHOD);

        await dispatch('cart/clearCart', null, {
            root: true,
        });

        if (showSuccessMessage) {
            dispatch(
                'messages/addSuccessMessage',
                { text: this.app.i18n.t('Successful logout.') },
                { root: true }
            );
        }

        // redirect to nuxt loggin page if user is not in nuxt app
        if (!this.app.nuxt) {
            window.location.href = `${window.location.origin}/${LOGIN_PAGE}`;
        }
    },

    async getCustomerData({ dispatch, state, rootGetters, getters }) {
        const ERROR_TAG = 'customer.getCustomer';

        try {
            if (!state.accessToken) {
                await dispatch('setAuthorizationDataFromCookies');
            }

            const customerData = await this.app.$services.customer.getCustomer();

            await dispatch('setCustomerData', customerData);

            if (getters.customerUuid) {
                const { $errorHandler, $storage } = this.app;

                saveUserIdForGTM({ userId: getters.customerUuid, $errorHandler, $storage });
            }

            const promises = [dispatch('setIsCustomerSubscribedToNewsletter')];

            if (rootGetters['config/isAccountVerificationEnabled'] && getters.isLoggedIn) {
                promises.push(
                    dispatch(
                        'avatar/checkAccountVerificationStatus',
                        { callPlace: 'getCustomerData' },
                        {
                            root: true,
                        }
                    )
                );
            }

            const results = await Promise.allSettled(promises);

            results.forEach(({ status, reason }) => {
                if (status === 'rejected') {
                    this.app.$errorHandler.captureStoreError(MODULE_NAME, reason, {
                        [ERROR_ACTION_TAG_NAME]: ERROR_TAG,
                    });
                }
            });
        } catch (err) {
            this.app.$errorHandler.captureStoreError(MODULE_NAME, err, {
                [ERROR_ACTION_TAG_NAME]: ERROR_TAG,
            });

            dispatch(
                'messages/addErrorMessage',
                { text: this.app.i18n.t('Fetching customer error occurred') },
                { root: true }
            );
        }
    },

    setCustomerData({ commit }, customerData = null) {
        commit(types.SET_CUSTOMER_DATA, customerData);
    },

    async setIsCustomerSubscribedToNewsletter({ commit, getters }) {
        let isCustomerSubscribedToNewsletter = false;

        if (getters.isLoggedIn) {
            // eslint-disable-next-line max-len
            isCustomerSubscribedToNewsletter = await this.app.$services.customer.getCustomerIsSubscribedToNewsletter();
        }

        commit(types.SET_IS_CUSTOMER_SUBSCRIBED_TO_NEWSLETTER, isCustomerSubscribedToNewsletter);
    },

    async setAuthorizationData(
        { commit },
        {
            accessToken = null,
            refreshToken = null,
            refreshTokenExpirationISO = null,
            accessTokenExpirationISO = null,
        } = {}
    ) {
        if (!process.client) {
            return;
        }

        commit(types.SET_ACCESS_TOKEN, accessToken);
        commit(types.SET_REFRESH_TOKEN, refreshToken);
        commit(types.SET_ACCESS_TOKEN_EXPIRATION_ISO, accessTokenExpirationISO);
        commit(types.SET_REFRESH_TOKEN_EXPIRATION_ISO, refreshTokenExpirationISO);
    },

    setFetchingCustomerInProgressFlag({ commit }, fetchingInProgress) {
        commit(types.SET_FETCHING_CUSTOMER_DATA_IN_PROGRESS, fetchingInProgress);
    },

    setShouldOpenModalShareLoyaltyClubFlag({ commit }, shouldOpenModalShareLoyaltyClubFlag) {
        commit(
            types.SET_SHOULD_OPEN_MODAL_SHARE_LOYALTY_CLUB_FLAG,
            shouldOpenModalShareLoyaltyClubFlag
        );
    },

    setShouldOpenModalLoyaltyClubRegistrationFlag(
        { commit },
        shouldOpenModalLoyaltyClubRegistration
    ) {
        commit(
            types.SET_SHOULD_OPEN_MODAL_LOYALTY_CLUB_REGISTRATION_FLAG,
            shouldOpenModalLoyaltyClubRegistration
        );
    },

    setShouldOpenModalAfterLoyaltyClubRegistrationFlag(
        { commit },
        shouldOpenModalAfterLoyaltyClubRegistration
    ) {
        commit(
            types.SET_SHOULD_OPEN_MODAL_AFTER_LOYALTY_CLUB_REGISTRATION_FLAG,
            shouldOpenModalAfterLoyaltyClubRegistration
        );
    },

    setWasModalAfterLoginClosed({ commit }, wasModalAfterLoginClosed) {
        commit(types.SET_WAS_MODAL_AFTER_LOGIN_CLOSED, wasModalAfterLoginClosed);
    },

    async setCustomerBirthDate({ dispatch }, birthDate) {
        const { result, message } = await this.app.$services.customer.setCustomerBirthDate(
            birthDate
        );

        if (!result) {
            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t(message),
                },
                { root: true }
            );

            return false;
        }

        await dispatch('getCustomerData');

        dispatch(
            'messages/addSuccessMessage',
            {
                text: this.app.i18n.t('The date of birth has been saved.'),
            },
            { root: true }
        );

        return true;
    },

    async updateCustomer({ dispatch, commit }, customerInput) {
        const customer = await this.app.$services.customer.updateCustomer(customerInput);

        if (!customer) {
            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t('Updating customer details error occured'),
                },
                { root: true }
            );

            return;
        }

        commit(types.UPDATE_CUSTOMER_DATA, customer);

        dispatch(
            'messages/addSuccessMessage',
            { text: this.app.i18n.t('Successful details update') },
            { root: true }
        );
    },

    async updateCustomerNonSensitiveData({ dispatch, commit }, customerInput) {
        const updatedFields = await this.app.$services.customer.updateCustomerNonSensitiveData(
            customerInput
        );

        if (!updatedFields) {
            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t('Updating customer details error occured'),
                },
                { root: true }
            );

            return;
        }

        commit(types.UPDATE_CUSTOMER_DATA, updatedFields);

        dispatch(
            'messages/addSuccessMessage',
            { text: this.app.i18n.t('Successful details update') },
            { root: true }
        );
    },

    async changeCustomerPassword({ dispatch }, { currentPassword, newPassword }) {
        const result = await this.app.$services.customer.changeCustomerPassword(
            currentPassword,
            newPassword
        );

        if (!result) {
            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t('Changing customer password error occured'),
                },
                { root: true }
            );

            return false;
        }

        dispatch(
            'messages/addSuccessMessage',
            { text: this.app.i18n.t('Password changed successfuly') },
            { root: true }
        );

        return true;
    },

    async createCustomerAddress({ dispatch }, customerAddressData) {
        const result = await this.app.$services.customer.createCustomerAddress(customerAddressData);

        if (!result) {
            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t('Creating address failed. Please try again in a moment.'),
                },
                { root: true }
            );

            return;
        }

        dispatch(
            'messages/addSuccessMessage',
            { text: this.app.i18n.t('Address has been added.') },
            { root: true }
        );

        dispatch('getCustomerData');
    },

    async deleteCustomerAddress({ dispatch }, addressId) {
        const result = await this.app.$services.customer.deleteCustomerAddress(addressId);

        if (!result) {
            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t('Removing address failed. Please try again in a moment.'),
                },
                { root: true }
            );

            return;
        }

        dispatch(
            'messages/addSuccessMessage',
            { text: this.app.i18n.t('Address has been removed.') },
            { root: true }
        );

        dispatch('getCustomerData');
    },

    async updateCustomerAddress({ dispatch }, customerAddressData) {
        const { addressId, ...addressData } = customerAddressData;

        const result = await this.app.$services.customer.updateCustomerAddress(
            addressData,
            addressId
        );

        if (!result) {
            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t('Changing address failed. Please try again in a moment.'),
                },
                { root: true }
            );

            return;
        }

        dispatch(
            'messages/addSuccessMessage',
            { text: this.app.i18n.t('Address has been changed.') },
            { root: true }
        );

        dispatch('getCustomerData');
    },

    async registerCustomerInLoyaltyClub({ dispatch, rootState }, loyaltyClubRegisterData) {
        const {
            success,
            message,
        } = await this.app.$services.customer.registerCustomerInLoyaltyClub(
            loyaltyClubRegisterData
        );

        if (success) {
            dispatch(
                'messages/addSuccessMessage',
                {
                    text: this.app.i18n.t('Successful register in MODIVO Club.'),
                },
                { root: true }
            );

            try {
                await dispatch('getCustomerData');

                await dispatch(
                    'cart/getCart',
                    {
                        cartId: rootState.cart.cartId,
                    },
                    { root: true }
                );
            } catch (err) {
                this.app.$errorHandler.captureStoreError(MODULE_NAME, err, {
                    [ERROR_ACTION_TAG_NAME]: 'customer.registerCustomerInLoyaltyClub',
                });
            }

            dispatch('setShouldOpenModalAfterLoyaltyClubRegistrationFlag', true);

            return true;
        }

        dispatch(
            'messages/addErrorMessage',
            {
                text: this.app.i18n.t(message),
            },
            { root: true }
        );

        return false;
    },

    async noticeLoyaltyClubStatus({ dispatch }) {
        await this.app.$services.customer.noticeLoyaltyClubStatus();
        dispatch('getCustomerData');
    },

    async handleExpiredSession({ dispatch }, withRevokeTokenRequest = true) {
        if (withRevokeTokenRequest) {
            this.$analytics.emit(INACTIVE_TOKEN_AUTO_LOGOUT);
        }

        await dispatch('logout', {
            showSuccessMessage: false,
            withRevokeTokenRequest,
        });

        dispatch(
            'messages/addErrorMessage',
            {
                text: this.app.i18n.t('Your session has expired. Please log in again.'),
            },
            { root: true }
        );
    },

    async refreshAuthToken({ dispatch }, refreshTokenValue) {
        if (refreshTokenDeferred?.promise) {
            return refreshTokenDeferred.promise;
        }

        refreshTokenDeferred = new Deferred();

        const {
            success,
            accessToken,
            refreshToken,
            accessTokenExpirationISO,
            refreshTokenExpirationISO,
        } = await this.app.$services.customer.refreshAuthToken(refreshTokenValue);

        if (!success) {
            this.$analytics.emit(THRUD_ERROR_AUTO_LOGOUT);

            await dispatch('handleExpiredSession', false);
        } else {
            await dispatch('saveAuthTokens', {
                accessToken,
                refreshToken,
                accessTokenExpirationISO,
                refreshTokenExpirationISO,
            });
        }

        refreshTokenDeferred.resolve();

        refreshTokenDeferred = null;
    },

    async setAuthorizationDataFromCookies({ dispatch }) {
        const {
            refreshTokenExpirationISO = null,
            accessTokenExpirationISO = null,
            refreshToken = null,
            accessToken = null,
        } = await this.$services.auth.readAuthTokens();

        if (
            !shouldRefreshToken(
                accessToken,
                accessTokenExpirationISO,
                refreshToken,
                refreshTokenExpirationISO
            )
        ) {
            await dispatch('setAuthorizationData', {
                accessToken,
                refreshToken,
                accessTokenExpirationISO,
                refreshTokenExpirationISO,
            });

            return;
        }

        await dispatch('refreshAuthToken', refreshToken);
    },

    setResetPasswordEmail({ commit }, email = '') {
        commit(types.SET_RESET_PASSWORD_EMAIL, email);
    },

    setInitLoginTooltip({ commit }, init) {
        if (
            init &&
            isShowLimitExceeded({
                storage: this.$storage,
                limit: LIMIT_FOR_SHOW_TOOLTIP_LOGIN,
                isShownStorageName: IS_LOGIN_TOOLTIP_SHOWN,
                counterStorageName: LOGIN_TOOLTIP_SHOWN_COUNTER,
            })
        ) {
            return;
        }

        commit(types.SET_INIT_TOOLTIP_WITH_LOGIN, init);
    },

    setLoginTooltipTimeoutId({ dispatch, commit }, id = null) {
        dispatch('setInitLoginTooltip', false);

        commit(types.SET_LOGIN_TOOLTIP_TIMEOUT_ID, id);
    },

    setLoginTooltipVisibility({ commit }, isShow) {
        if (
            isShow &&
            isShowLimitExceeded({
                storage: this.$storage,
                limit: LIMIT_FOR_SHOW_TOOLTIP_LOGIN,
                isShownStorageName: IS_LOGIN_TOOLTIP_SHOWN,
                counterStorageName: LOGIN_TOOLTIP_SHOWN_COUNTER,
            })
        ) {
            return;
        }

        if (isShow) {
            setTooltipDataInStorage({
                storage: this.$storage,
                isShownStorageName: IS_LOGIN_TOOLTIP_SHOWN,
                counterStorageName: LOGIN_TOOLTIP_SHOWN_COUNTER,
            });
        }

        commit(types.SET_LOGIN_TOOLTIP_VISIBILITY, isShow);
    },

    initLoginTooltipWithDelay({ dispatch, state }) {
        if (state.loginTooltipVisibility) {
            dispatch('setInitLoginTooltip', false);

            return;
        }

        const id = setTimeout(() => {
            dispatch('setLoginTooltipVisibility', true);
            dispatch('setInitLoginTooltip', false);
            dispatch('setLoginTooltipTimeoutId', null);
        }, TIME_TO_SHOW_TOOLTIP_WITH_LOGIN);

        dispatch('setLoginTooltipTimeoutId', id);
    },

    setShouldOpenLoyaltyClubRegistrationOnLoginSuccess({ commit }, shouldOpen) {
        commit(types.SET_SHOULD_OPEN_LOYALTY_CLUB_REGISTRATION_ON_LOGIN_SUCCESS, shouldOpen);
    },

    setIsRegistrationBySocialMedia({ commit }, isRegistrationBySocialMedia) {
        commit(types.SET_IS_REGISTRATION_BY_SOCIAL_MEDIA, isRegistrationBySocialMedia);
    },

    setShouldOpenModalNewsletterConfirmation({ commit }, shouldOpenModalNewsletterConfirmation) {
        commit(
            types.SET_SHOULD_OPEN_MODAL_NEWSLETTER_CONFIRMATION,
            shouldOpenModalNewsletterConfirmation
        );
    },

    setShouldShowNewsletterConfirmationSection(
        { commit },
        shouldShowNewsletterConfirmationSection
    ) {
        commit(
            types.SET_SHOULD_SHOW_NEWSLETTER_CONFIRMATION_SECTION,
            shouldShowNewsletterConfirmationSection
        );
    },

    setNewsletterConfirmationEmail({ commit }, newsletterConfirmationEmail) {
        commit(types.SET_NEWSLETTER_CONFIRMATION_EMAIL, newsletterConfirmationEmail);
    },
};

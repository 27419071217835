export const HTTP_CLIENT_ENDPOINT_NAME_AUTH = 'auth';
export const HTTP_CLIENT_ENDPOINT_NAME_AVATAR = 'avatar';
export const HTTP_CLIENT_ENDPOINT_NAME_ADS = 'ads';
export const HTTP_CLIENT_ENDPOINT_NAME_ADS_DATA = 'adsData';
export const HTTP_CLIENT_ENDPOINT_NAME_PARTNER = 'partner';
export const HTTP_CLIENT_ENDPOINT_NAME_SMAUG = 'smaug';
export const HTTP_CLIENT_ENDPOINT_NAME_SEARCH_SERVICE = 'searchService';
export const HTTP_CLIENT_ENDPOINT_NAME_SEOMATIC = 'seomatic';
export const HTTP_CLIENT_ENDPOINT_NAME_SEO_LINK_SERVICE = 'seoLinksService';
export const HTTP_CLIENT_ENDPOINT_NAME_SEARCH = 'search';
export const HTTP_CLIENT_ENDPOINT_NAME_PREPAID = 'prepaid';

export const GET_ASSIGNED_PRE_PAID_CARDS_OPERATION_NAME = 'getAssignedPrePaidCards';
export const ASSIGN_PRE_PAID_TO_CART_OPERATION_NAME = 'assignPrePaidToCart';
export const DETACH_PRE_PAID_FROM_CART_OPERATION_NAME = 'detachPrePaidFromCart';
export const CHANGE_CARD_VISIBILITY_OPERATION_NAME = 'changeCardVisibility';
export const UPDATE_ORDER_AMOUNT_OPERATION_NAME = 'updateOrderAmount';
export const EXTRA_PAYMENT_METHOD_SET_OPERATION_NAME = 'extraPaymentMethodSet';
export const EXTRA_PAYMENT_METHOD_UN_SET_OPERATION_NAME = 'extraPaymentMethodUnSet';

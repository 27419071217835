import {
    TYPES as MESSAGES_TYPES,
    PARAMS as MESSAGES_PARAMS,
} from '@configs/defaults/messages/config';
import { v4 as uuid } from 'uuid';
import { types } from './mutations';

export default {
    addSuccessMessage({ dispatch }, payload) {
        dispatch('addMessage', {
            ...payload,
            type: MESSAGES_TYPES.SUCCESS,
        });
    },
    addErrorMessage({ dispatch }, payload) {
        dispatch('addMessage', {
            ...payload,
            type: MESSAGES_TYPES.ERROR,
        });
    },
    addInfoMessage({ dispatch }, payload) {
        dispatch('addMessage', {
            ...payload,
            type: MESSAGES_TYPES.INFO,
        });
    },
    addMessage({ commit, dispatch, state }, messagePayload) {
        const { messages } = state;
        const count = messages.length;
        const { type, title, text, label, handler, timeout, messageId = uuid() } = messagePayload;

        if (timeout !== false) {
            const time = parseInt(timeout, 10) || MESSAGES_PARAMS.DEFAULT_TIMEOUT_MS;

            setTimeout(dispatch, time, 'removeMessage', messageId);
        }

        if (count >= MESSAGES_PARAMS.DISPLAY_LIMIT) {
            dispatch('removeOldest');
        }

        commit(types.ADD_MESSAGE, {
            type,
            text,
            title,
            id: messageId,
            action: {
                handler,
                label,
            },
        });
    },
    removeOldest({ dispatch, state }) {
        const { messages } = state;
        const { length } = messages;

        if (length) {
            const { id: idToRemove } = messages[0];

            dispatch('removeMessage', idToRemove);
        }
    },
    removeMessages({ commit }) {
        commit(types.REMOVE_MESSAGES);
    },
    removeMessage({ commit }, messageId) {
        commit(types.REMOVE_MESSAGE, messageId);
    },
};

export const BRAND_CHANNEL = 'BRAND_CHANNEL';
export const MEGATRON_SUFFIX_PATH = 'MEGATRON_SUFFIX_PATH';
export const SEARCH_SUFFIX_PATH = 'SEARCH_SUFFIX_PATH';
export const PRODUCT_SEARCH_SUFFIX_PATH = 'PRODUCT_SEARCH_SUFFIX_PATH';
export const MAGENTO_SUFFIX_PATH = 'MAGENTO_SUFFIX_PATH';
export const THEME_COLOR = 'THEME_COLOR';
export const IS_MENU_CONTEXTUAL = 'IS_MENU_CONTEXTUAL';
export const DEFAULT_NAVIGATION_CONTEXT_CODE = 'DEFAULT_NAVIGATION_CONTEXT_CODE';
export const LOADER_COLOR = 'LOADER_COLOR';
export const REVIEWS_ENGINE = 'REVIEWS_ENGINE';

<template>
    <div class="header-wrapper">
        <template v-if="!isWebViewMobile">
            <header class="header">
                <HeaderTopBar v-if="!isMobile" class="desktop-top-bar" />

                <HeaderMainBar class="main-bar" />

                <div class="search-button-wrapper">
                    <HeaderSearchButton />
                </div>

                <NavigationBar :class="{ 'invisible-state': isNavigationHidden }" class="nav-bar" />

                <HeaderMainCategories v-if="isMainCategoryPage" class="main-cat" />

                <Divider :class="{ 'on-main-cat': isMainCategoryPage }" class="header-divider" />
            </header>

            <Scrim v-show="isDesktopMenuOpen" class="backdrop" />
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import { DESKTOP_MENU } from '@header/configs/modals';

import { HOME_PAGE_NAME, MAIN_CATEGORY_PAGE_NAME } from '@router/names';

import HeaderMainCategories from '@header-atoms/HeaderMainCategories/HeaderMainCategories';

import HeaderTopBar from '@header-organisms/HeaderTopBar/HeaderTopBar';
import HeaderSearchButton from '@header-organisms/HeaderSearchButton/HeaderSearchButton';
import NavigationBar from '@header-organisms/NavigationBar/NavigationBar';
import HeaderMainBar from '@header-organisms/HeaderMainBar/HeaderMainBar';

import { Divider } from '@modivo-ui/components/Divider/v2';
import { Scrim } from '@modivo-ui/components/Scrim/v1';

export default {
    name: 'HeaderWrapper',

    components: {
        HeaderTopBar,
        HeaderMainBar,
        HeaderSearchButton,
        HeaderMainCategories,
        Divider,
        NavigationBar,
        Scrim,
    },

    props: {
        isWebViewMobile: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapState(['isMobile']),

        isNavigationHidden() {
            return this.$route.name === HOME_PAGE_NAME && this.$navigationContext.isMenuContextual;
        },

        isMainCategoryPage() {
            return this.$route.name === MAIN_CATEGORY_PAGE_NAME;
        },

        isDesktopMenuOpen() {
            return this.$modals.isOpen(DESKTOP_MENU);
        },
    },
};
</script>

<style scoped lang="scss">
.header-wrapper {
    transition: top 0.3s, opacity 0.5s;
    @apply sticky top-ui-0 z-3;

    .header {
        @apply relative bg-ds-container-primary;
        @apply z-3;
        @apply max-h-[160px];
    }

    .main-bar {
        @apply opacity-100;

        transition: top 0.3s, opacity 0.5s;
    }

    .backdrop {
        @apply z-2 fixed;
    }

    .desktop-top-bar {
        @apply hidden;
    }

    .search-button-wrapper {
        @apply px-ui-4;
    }

    .header-divider {
        @apply mt-ui-4;

        &.on-main-cat {
            @apply mt-ui-0 relative top-[-2px];
        }
    }

    .nav-bar {
        @apply hidden;
    }

    .main-cat {
        @apply px-ui-4;
    }

    @screen lg {
        @apply top-[-48px];

        .header {
            @apply max-h-none;
        }

        .main-cat {
            @apply hidden;
        }

        .desktop-top-bar {
            @apply block;
        }

        .nav-bar {
            @apply block;
            transition: 0.3s;
        }

        .search-button-wrapper {
            @apply hidden;
        }

        .header-divider {
            @apply mt-ui-0;

            &.on-main-cat {
                @apply static;
            }
        }

        .invisible-state {
            @apply opacity-0 h-ui-6;

            :deep(.navigation) {
                @apply pointer-events-none;
            }
        }
    }
}
</style>

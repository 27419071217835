export const HOME_PAGE_NAME = 'homepage';
export const MAIN_CATEGORY_PAGE_NAME = 'main-category';
export const WISHLIST_PAGE_NAME = 'wishlist';
export const CHECKOUT_PAGE_NAME = 'checkout';
export const CHECKOUT_SUCCESS_PAGE_NAME = 'checkout-success';
export const CHECKOUT_ACCOUNT_PAGE_NAME = 'checkout-account';
export const CHECKOUT_CART_PAGE_NAME = 'checkout-cart';
export const LOGIN_PAGE_NAME = 'login';
export const REGISTER_PAGE_NAME = 'register';
export const FORGOT_PASSWORD_PAGE_NAME = 'forgot-password';
export const PACKAGE_TRACKING_PAGE_NAME = 'package-tracking';
export const ADD_RETURN_PAGE_NAME = 'add-return';
export const SUCCESS_ADD_PAGE_RETURN_NAME = 'success-add-return';
export const FAILED_ADD_RETURN_NAME = 'failed-add-return';
export const NEWSLETTER_PAGE_NAME = 'newsletter';
export const NEWSLETTER_SMS_UNSUBSCRIBE_PAGE_NAME = 'sms-unsubscribe';
export const NOT_FOUND_NAME = 'not-found';
export const NOT_SUPPORTED_PAGE_NAME = 'not-supported';
export const RESET_PASSWORD_PAGE_NAME = 'reset-password';
export const ORDER_PAGE_NAME = 'order';
export const CUSTOMER_PAGE_NAME = 'customer';
export const CUSTOMER_DETAILS_NAME = 'customer/details';
export const CUSTOMER_ADDRESSES_NAME = 'customer/addresses';
export const CUSTOMER_ACCOUNT_ORDERS_NAME = 'customer/orders';
export const CUSTOMER_ACCOUNT_ORDER_VIEW_NAME = 'customer/orders/order-view';
export const CUSTOMER_ACCOUNT_AGREEMENTS_NAME = 'customer/agreements';
export const CUSTOMER_ACCOUNT_RETURNS_NAME = 'customer/returns';
export const CUSTOMER_ACCOUNT_RETURN_NAME = 'customer/add-return';
export const CUSTOMER_ACCOUNT_SUCCESS_RETURN_NAME = 'customer/success-return';
export const CUSTOMER_ACCOUNT_FAILED_RETURN_NAME = 'customer/failed-return';
export const CUSTOMER_ACCOUNT_MODIVO_CLUB_NAME = 'customer/modivo-club';
export const CUSTOMER_ACCOUNT_CARDS_NAME = 'customer/cards';
export const CHECKOUT_REPAYMENT_NAME = 'checkout-repayment';
export const CMS_PAGE_NAME = 'cms';
export const CONTACT_PAGE_NAME = 'contact';
export const PAGE_BUILDER_NAME = 'pb';
export const LIVE_PAGE_NAME = 'live';
export const LIVESTREAM_PAGE_NAME = 'livestream';
export const LOYALTY_CLUB_NAME = 'fashion-club';
export const MODIVO_CLUB_NAME = 'modivo-club';
export const MODIVO_CLUB_GIFTCARD_PAGE_NAME = 'modivoClubGiftCard';
export const WEB_LINKS_PAGE_NAME = 'web-links';
export const PREFERENCES_FORM_PAGE_NAME = 'preferences-form';
export const MOBILE_TOKEN_EXPIRED_PAGE_NAME = 'token-expired';

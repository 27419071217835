<template>
    <HeaderTooltip class="minicart-tooltip" :is-enabled="!isMobile">
        <HeaderActionButton
            :path="cartPath"
            :icon="Bag"
            :label="$t('Cart')"
            :digit="cartItemsCount"
        />

        <template #tooltipContent>
            <div class="tooltip-content-wrapper">
                <MinicartContent :items-count="cartItemsCount" />
            </div>
        </template>
    </HeaderTooltip>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import { CHECKOUT_CART_PAGE } from '@router/paths';

import HeaderTooltip from '@header-atoms/HeaderTooltip/HeaderTooltip';
import Loader from '@atoms/Loader/Loader';
import HeaderActionButton from '@header-atoms/HeaderActionButton/HeaderActionButton';

import { Bag } from '@modivo-ui/icons/v2/action';

const { mapGetters: mapCartGetters } = createNamespacedHelpers('cart');

export default {
    name: 'MinicartTooltip',

    components: {
        HeaderTooltip,
        HeaderActionButton,
        MinicartContent: () => ({
            component: import(
                /* webpackChunkName: "minicart-content" */
                '@molecules/MinicartContent/MinicartContent'
            ),
            delay: 0,
            loading: Loader,
        }),
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapCartGetters(['cartItemsCount']),
    },

    beforeCreate() {
        this.cartPath = `/${CHECKOUT_CART_PAGE}`;
        this.Bag = Bag;
    },
};
</script>

<style lang="scss" scoped>
.tooltip-content-wrapper {
    @apply w-[352px];
}
</style>

<template>
    <div class="download-app">
        <HeaderText
            :size="HEADER_SIZE_S"
            :size-desktop="HEADER_SIZE_M"
            :align="CENTER"
            :align-desktop="LEFT"
            :tag="HEADER_TAG"
        >
            {{ $t('Download application') }}
        </HeaderText>

        <div class="wrapper">
            <MobileAppQrCode class="qr-code" />

            <MobileAppLinks
                :play-url="playUrl"
                :app-store-url="appStoreUrl"
                :app-gallery-url="appGalleryUrl"
                class="apps"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import MobileAppQrCode from '@molecules/MobileAppQrCode/MobileAppQrCode';
import MobileAppLinks from '@molecules/MobileAppLinks/MobileAppLinks';

import {
    HeaderText,
    HEADER_TEXT_SIZES,
    HEADER_TEXT_ALIGNS,
    HEADER_TEXT_TAGS,
} from '@modivo-ui/components/HeaderText/v1';

export default {
    name: 'DownloadApp',

    components: {
        MobileAppQrCode,
        HeaderText,
        MobileAppLinks,
    },

    props: {
        playUrl: {
            type: String,
            default: '',
        },

        appStoreUrl: {
            type: String,
            default: '',
        },

        appGalleryUrl: {
            type: String,
            default: '',
        },
    },

    computed: {
        ...mapState(['isMobile']),
    },

    beforeCreate() {
        this.HEADER_SIZE_M = HEADER_TEXT_SIZES.M;
        this.HEADER_SIZE_S = HEADER_TEXT_SIZES.S;
        this.HEADER_TAG = HEADER_TEXT_TAGS.H5;
        this.CENTER = HEADER_TEXT_ALIGNS.CENTER;
        this.LEFT = HEADER_TEXT_ALIGNS.LEFT;
    },
};
</script>

<style lang="scss" scoped>
.download-app {
    .wrapper {
        @apply flex items-center justify-center mt-3;
    }

    .apps {
        @apply flex flex-wrap;
        @apply justify-center items-center;
        @apply gap-ui-2;
    }

    .qr-code {
        @apply shrink-0 hidden;
    }

    @screen lg {
        .apps {
            @apply flex-col;
        }

        .qr-code {
            @apply block;
        }

        .wrapper {
            @apply justify-start;
            @apply gap-ui-4;
            @apply flex-wrap;
        }
    }
}
</style>

export const types = {
    SET_OPEN_ADD_PRODUCT_TO_CART_MODAL: 'SET_OPEN_ADD_PRODUCT_TO_CART_MODAL',
    SET_RESET_ADD_PRODUCT_TO_CART_MODAL: 'SET_RESET_ADD_PRODUCT_TO_CART_MODAL',
    SET_OPEN_ADD_PRODUCT_TO_CART_ERROR_MODAL: 'SET_OPEN_ADD_PRODUCT_TO_CART_ERROR_MODAL',
    SET_RESET_ADD_PRODUCT_TO_CART_ERROR_MODAL: 'SET_RESET_ADD_PRODUCT_TO_CART_ERROR_MODAL',
    SET_IS_SIZE_MODAL_OPEN: 'SET_IS_SIZE_MODAL_OPEN',
    // eslint-disable-next-line max-len
    SET_SHOULD_REDIRECT_TO_CHECKOUT_ON_JOIN_MODIVO_CLUB_MODAL_CLOSE:
        'SET_SHOULD_REDIRECT_TO_CHECKOUT_ON_JOIN_MODIVO_CLUB_MODAL_CLOSE',
};

export default {
    [types.SET_OPEN_ADD_PRODUCT_TO_CART_MODAL](state, addedCartItemData) {
        state.isOpenAddToCartModal = true;
        state.addedCartItemData = addedCartItemData;
    },

    [types.SET_RESET_ADD_PRODUCT_TO_CART_MODAL](state) {
        state.isOpenAddToCartModal = false;
        state.addedCartItemData = null;
    },

    [types.SET_OPEN_ADD_PRODUCT_TO_CART_ERROR_MODAL](state, addedCartItemData) {
        state.isOpenAddToCartErrorModal = true;
        state.addedCartItemData = addedCartItemData;
    },

    [types.SET_RESET_ADD_PRODUCT_TO_CART_ERROR_MODAL](state) {
        state.isOpenAddToCartErrorModal = false;
        state.addedCartItemData = null;
    },

    [types.SET_IS_SIZE_MODAL_OPEN](state, isSizeModalOpen) {
        state.isSizeModalOpen = isSizeModalOpen;
    },

    [types.SET_SHOULD_REDIRECT_TO_CHECKOUT_ON_JOIN_MODIVO_CLUB_MODAL_CLOSE](
        state,
        shouldRedirectToCheckoutOnJoinModivoClubModalClose
    ) {
        // eslint-disable-next-line max-len
        state.shouldRedirectToCheckoutOnJoinModivoClubModalClose = shouldRedirectToCheckoutOnJoinModivoClubModalClose;
    },
};

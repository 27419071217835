<template>
    <div class="messages">
        <Snackbar
            v-for="message in messagesMapped"
            :id="message.id"
            :key="`snackbar-${message.id}`"
            :title="message.title"
            :description="message.text"
            :variant="message.variant"
            :show="true"
            :button-label="message.action.label"
            class="messages-alert"
            @button-click="message.hasAction ? message.action.handler() : null"
        />
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { TYPES as MESSAGES_TYPES } from '@configs/defaults/messages/config';

import { Snackbar, SNACKBAR_VARIANTS } from '@modivo-ui/components/Snackbar/v1';

const { mapState: mapStateMessages } = createNamespacedHelpers('messages');

const MESSAGE_TYPES_MAP = {
    [MESSAGES_TYPES.INFO]: SNACKBAR_VARIANTS.INFORMATION,
    [MESSAGES_TYPES.SUCCESS]: SNACKBAR_VARIANTS.SUCCESS,
    [MESSAGES_TYPES.ERROR]: SNACKBAR_VARIANTS.ERROR,
};

export default {
    name: 'Messages',

    components: {
        Snackbar,
    },

    computed: {
        ...mapStateMessages(['messages']),

        messagesMapped() {
            return this.messages.map(message => {
                const hasAction = message.action.label && message.action.handler;

                const variant = MESSAGE_TYPES_MAP[message.type];

                return {
                    ...message,
                    hasAction,
                    variant,
                };
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.messages {
    @apply fixed w-ds-percent-100 left-ds-0 bottom-ds-0 z-ds-6;
}

.messages-alert {
    @apply mt-ds-4;
}

@screen lg {
    .messages {
        @apply flex items-center flex-col w-auto left-1/2 bottom-ds-0 mb-ds-7;
        transform: translateX(-50%);
    }

    .messages-alert {
        @apply shrink-0;
        min-width: theme('customVariables.messages.minWidthDesktop');
    }
}
</style>

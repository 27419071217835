export const normalizePrePaidCards = prePaidCards =>
    prePaidCards.map(prePaidCard => ({
        ...prePaidCard,
        balance: {
            ...prePaidCard.balance,
            amount: parseFloat(prePaidCard.balance.amount),
        },
        covered: {
            ...prePaidCard.covered,
            amount: parseFloat(prePaidCard.covered.amount),
        },
    }));

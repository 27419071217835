var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-footer"},[_c('div',[_c('FooterContent'),_vm._v(" "),(_vm.isNotBot && !_vm.isFetchingConsentsData)?[(_vm.isConsentsModalConfigured)?_c('ConsentsDataProvider',{scopedSlots:_vm._u([{key:"default",fn:function({
                    agreeAllConsents,
                    categories,
                    closeModal,
                    isModalOpen,
                    openModal,
                    providers,
                    setStoredCategories,
                    isTrustedPartnersModalOpen,
                    isModalCookieSettingsOpen,
                    openModalTrustedPartners,
                    closeModalTrustedPartners,
                    openModalCookieSettingsOpen,
                    closeModalCookieSettingsOpen,
                }){return [_c('LoadCookieModal',{attrs:{"categories":categories,"is-modal-consents-open":isModalOpen,"is-trusted-partners-modal-open":isTrustedPartnersModalOpen,"is-modal-cookie-settings-open":isModalCookieSettingsOpen,"providers":providers},on:{"agree-all-consents":function($event){return agreeAllConsents()},"open-consents-modal":function($event){return openModal()},"close-consents-modal":function($event){return closeModal()},"open-modal-trusted-partners":function($event){return openModalTrustedPartners()},"close-modal-trusted-partners":function($event){return closeModalTrustedPartners()},"open-modal-cookie-settings":function($event){return openModalCookieSettingsOpen()},"close-modal-cookie-settings":function($event){return closeModalCookieSettingsOpen()},"set-stored-categories":function($event){return setStoredCategories($event)}}})]}}],null,false,550405480)}):_c('CheckCookieExist',{attrs:{"cookie-name":_vm.COOKIES_MODAL_COOKIE_NAME},scopedSlots:_vm._u([{key:"default",fn:function({ isCookieExist }){return [(_vm.shouldDisplayCookiesModal(isCookieExist))?_c('CookiesApprovals'):_vm._e()]}}],null,false,1444514907)})]:_vm._e(),_vm._v(" "),_c('LoyaltyClubProvider',{scopedSlots:_vm._u([{key:"default",fn:function({
                shouldDisplayModalAfterLogin,
                closeModalAfterLogin,
                openLoyaltyClubRegistrationModal,
                shouldDisplayLoyaltyClubNewStatus,
                closeNewStatusModal,
                previousStatus,
                status,
                shareId,
                closeShareModal,
                closeLoyaltyClubRegisterModal,
                isLoyaltyClubActive,
            }){return [(isLoyaltyClubActive)?_c('div',[(
                        shouldDisplayModalAfterLogin &&
                        !_vm.isMagicLinkAuthModalOpen &&
                        !_vm.isRegistrationBySocialMedia &&
                        !_vm.shouldOpenModalNewsletterConfirmation
                    )?_c('ModalLoyaltyClubAfterLogin',{on:{"open-loyalty-club-registration-modal":function($event){return openLoyaltyClubRegistrationModal()},"close":function($event){return closeModalAfterLogin()}}}):_vm._e(),_vm._v(" "),(shouldDisplayLoyaltyClubNewStatus)?_c('ModalLoyaltyClubStatus',{attrs:{"previous-status":previousStatus,"status":status},on:{"close":function($event){return closeNewStatusModal()}}}):_vm._e(),_vm._v(" "),(_vm.shouldOpenModalShareLoyaltyClubFlag)?_c('ModalShareLoyaltyClub',{attrs:{"share-id":shareId},on:{"close":function($event){return closeShareModal()}}}):_vm._e(),_vm._v(" "),_c('LoyaltyClubRegistrationWrapper',{on:{"open-loyalty-club-registration-modal":function($event){return openLoyaltyClubRegistrationModal()},"close-loyalty-club-registration-modal":function($event){return closeLoyaltyClubRegisterModal()}}})],1):_vm._e()]}}])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }
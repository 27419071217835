<template>
    <DialogSide
        :show="isAdvertisementInformationModalOpen"
        :label="headerText"
        class="information-modal"
        @close="$emit('close')"
        @button-trailing-click="$emit('close')"
    >
        <template #icon-trailing>
            <Close />
        </template>
        <div class="information-content">
            <section class="modal-section">
                <HeaderText :tag="HEADER_TEXT_TAG" :size="HEADER_TEXT_SIZE" class="section-title">
                    {{ whatIsThisQuestion }}
                </HeaderText>
                <BodyText :size="BODY_TEXT_SIZE" class="section-paragraph">
                    <span v-html="whatIsThisText" />
                </BodyText>
            </section>
            <Divider :variant="DIVIDER_VARIANT" />
            <section class="modal-section">
                <HeaderText :tag="HEADER_TEXT_TAG" :size="HEADER_TEXT_SIZE" class="section-title">
                    {{ fromWhereQuestion }}
                </HeaderText>
                <BodyText :size="BODY_TEXT_SIZE" class="section-paragraph">
                    {{ fromWhereText }}
                </BodyText>
                <div v-if="isLoading" class="skeleton-wrapper">
                    <Skeleton width="75%" height="24px" />
                    <Skeleton width="50%" height="10px" />
                </div>
                <div v-else-if="companyId" class="company-information">
                    <p class="text-bold">{{ companyName }}</p>
                    <p class="company-id">{{ $t('VAT id') }}:{{ companyId }}</p>
                </div>
            </section>
            <Divider :variant="DIVIDER_VARIANT" />
            <section class="modal-section">
                <HeaderText :tag="HEADER_TEXT_TAG" :size="HEADER_TEXT_SIZE" class="section-title">
                    {{ whyAmISeeingQuestion }}
                </HeaderText>
                <BodyText :size="BODY_TEXT_SIZE" class="section-paragraph">
                    {{ whyAmISeeingText }}
                </BodyText>
                <ul class="section-list">
                    <li>
                        <span v-html="$t('page context, e.g. the category you are currently in')" />
                    </li>
                    <li>
                        <span
                            v-html="
                                $t(
                                    // eslint-disable-next-line max-len
                                    'parameter of the product you are viewing, e.g. color, material'
                                )
                            "
                        />
                    </li>
                    <li>
                        <span v-html="$t('profile, e.g. the categories you like to browse')" />
                    </li>
                </ul>
            </section>
        </div>
    </DialogSide>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { PREVENT_SCROLL_CLASS_NAME } from '@configs/class-names';
import { MODAL_TYPE, MODAL_ADVERTISEMENT_INFORMATION } from '@configs/modals';

import { OFFER } from '@types/AdvertisementModal';

import { ASYNC_AD_TECH_SERVICE_CONFIG } from '@async-services/adTech/meta';

import { Close } from '@modivo-ui/icons/v2/navigation';

import { BodyText, BODY_TEXT_SIZES } from '@modivo-ui/components/BodyText/v1';
import { DialogSide } from '@modivo-ui/components/DialogSide/v1';
import { Divider, DIVIDER_VARIANTS } from '@modivo-ui/components/Divider/v2';
import {
    HeaderText,
    HEADER_TEXT_SIZES,
    HEADER_TEXT_TAGS,
} from '@modivo-ui/components/HeaderText/v1';
import { Skeleton } from '@modivo-ui/components/Skeleton/v2';

const { mapActions: mapLayoutActions } = createNamespacedHelpers('layout');

const {
    NAME: AD_TECH_SERVICE_NAME,
    METHODS: AD_TECH_SERVICE_METHODS,
} = ASYNC_AD_TECH_SERVICE_CONFIG;

const masLink =
    'https://advertising.modivo.com/?utm_source=onsite&utm_medium=modal&utm_id=sponsored_tooltip';

export default {
    name: 'InformationModal',

    components: {
        BodyText,
        Close,
        DialogSide,
        Divider,
        HeaderText,
        Skeleton,
    },

    data() {
        return {
            companyName: '',
            companyId: '',
            isLoading: true,
        };
    },

    computed: {
        modalType() {
            return this.$modals.getConfig(MODAL_ADVERTISEMENT_INFORMATION)[MODAL_TYPE] || OFFER;
        },

        isAdvertisementInformationModalOpen() {
            return this.$modals.isOpen(MODAL_ADVERTISEMENT_INFORMATION);
        },

        headerText() {
            return this.modalType === OFFER
                ? this.$t('Sponsored offers')
                : this.$t('Sponsored formats');
        },

        whatIsThisQuestion() {
            return this.modalType === OFFER
                ? this.$t('What are sponsored offers?')
                : this.$t('What are sponsored formats?');
        },

        whatIsThisText() {
            if (this.modalType === OFFER) {
                return this.$t(
                    // eslint-disable-next-line max-len
                    'Sponsored offers are contents that functions as part of Modivo Advertising Services.',
                    {
                        masLink,
                    }
                );
            }

            return this.$t(
                // eslint-disable-next-line max-len
                'Sponsored formats are contents that functions as part of Modivo Advertising Services.',
                {
                    masLink,
                }
            );
        },

        fromWhereQuestion() {
            return this.modalType === OFFER
                ? this.$t('Where does this offer come from?')
                : this.$t('Where does this advertisement come from?');
        },

        fromWhereText() {
            return this.modalType === OFFER
                ? this.$t('This offer comes from:')
                : this.$t('This advertisement comes from:');
        },

        whyAmISeeingQuestion() {
            return this.modalType === OFFER
                ? this.$t('Why am I seeing this offer?')
                : this.$t('Why am I seeing this advertisement?');
        },

        whyAmISeeingText() {
            return this.modalType === OFFER
                ? this.$t('The sponsored offer is conformed to:')
                : this.$t('The sponsored format is conformed to:');
        },
    },

    watch: {
        isAdvertisementInformationModalOpen: {
            immediate: true,
            async handler(isOpen) {
                if (isOpen) {
                    this.isLoading = true;
                    this.addHTMLClasses([PREVENT_SCROLL_CLASS_NAME]);
                    await this.getCompanyInformation();
                    this.isLoading = false;

                    return;
                }

                this.removeHTMLClasses([PREVENT_SCROLL_CLASS_NAME]);
            },
        },
    },

    beforeCreate() {
        this.DIVIDER_VARIANT = DIVIDER_VARIANTS.M;
        this.HEADER_TEXT_SIZE = HEADER_TEXT_SIZES.M;
        this.HEADER_TEXT_TAG = HEADER_TEXT_TAGS.H3;
        this.BODY_TEXT_SIZE = BODY_TEXT_SIZES.M;
    },

    beforeDestroy() {
        this.removeHTMLClasses([PREVENT_SCROLL_CLASS_NAME]);
    },

    methods: {
        ...mapLayoutActions(['addHTMLClasses', 'removeHTMLClasses']),

        async getCompanyInformation() {
            const accountId = this.$modals.getConfig(MODAL_ADVERTISEMENT_INFORMATION)
                .companyAccountId;

            if (!accountId) {
                return;
            }

            const { companyName, companyId } = await this.$asyncServices.use(
                AD_TECH_SERVICE_NAME,
                AD_TECH_SERVICE_METHODS.GET_COMPANY_INFORMATION,
                {
                    accountId,
                    type: this.modalType,
                }
            );

            this.companyName = companyName;
            this.companyId = companyId;
        },
    },
};
</script>

<style scoped lang="scss">
.information-modal {
    :deep(.scrim) {
        @apply z-3;
    }

    :deep(.dialog) {
        @apply z-5;
    }

    .modal-section {
        @apply py-ui-6 px-ui-4;
    }

    .section-paragraph {
        @apply mt-ui-4;
    }

    :deep(.link) {
        @apply underline underline-offset-4;
    }

    .information-content {
        @apply w-full;
    }

    .section-list {
        @apply list-disc list-inside flex flex-col gap-ui-2 mt-ui-4;
    }

    .company-id {
        @apply font-ui-body-s text-ds-content-disabled;
    }

    .company-information {
        @apply mt-ui-2;
    }

    .skeleton-wrapper {
        @apply mt-ui-2 flex flex-col gap-y-ui-2;
    }

    :deep(.text-bold) {
        @apply font-ui-body-m-bold;
    }

    @screen lg {
        .modal-section {
            @apply py-ui-7 px-ui-6;
        }
    }
}
</style>

<template>
    <div class="footer-simple">
        <StaticBlock
            v-if="footerSimpleStaticBlocks.footerSimpleTop"
            :content="footerSimpleStaticBlocks.footerSimpleTop.content"
        />
        <div class="bottom-section">
            <div class="left">
                <BaseLink :path="footerLinks.goBackPath" class="return-link">
                    <SvgIcon width="16px" height="16px">
                        <Arrow1Left />
                    </SvgIcon>
                    {{ footerLinks.goBackTitle }}
                </BaseLink>
            </div>
            <div class="center">
                <div class="item">
                    <SvgIcon class="item-svg" width="16px" height="16px">
                        <Gift />
                    </SvgIcon>
                    {{ freeDeliveryText }}
                </div>
                <div class="item">
                    <SvgIcon class="item-svg" width="16px" height="16px">
                        <Return1 />
                    </SvgIcon>
                    {{ daysForReturnsText }}
                </div>
            </div>
            <div class="right">
                <StaticBlock
                    v-if="footerSimpleStaticBlocks.footerSimpleBottomRight"
                    :content="footerSimpleStaticBlocks.footerSimpleBottomRight.content"
                    class="items-wrapper"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex';

import { PSB_FOOTER_SIMPLE_TOP, PSB_FOOTER_SIMPLE_BOTTOM_RIGHT } from '@configs/footer';

import Arrow1Left from '@static/icons/16px/arrow1-left.svg?inline';
import Gift from '@static/icons/16px/gift.svg?inline';
import Return1 from '@static/icons/16px/return1.svg?inline';

import BaseLink from '@atoms/BaseLink/BaseLink';
import StaticBlock from '@atoms/StaticBlock/StaticBlock';
import SvgIcon from '@atoms/SvgIcon/SvgIcon';

import WithDaysForReturnsText from '@mixins/WithDaysForReturnsText';

const { mapGetters: mapCmsBlockGetters } = createNamespacedHelpers('cmsBlock');
const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'FooterSimpleV2',

    components: {
        BaseLink,
        StaticBlock,
        SvgIcon,
        Arrow1Left,
        Gift,
        Return1,
    },

    mixins: [WithDaysForReturnsText],

    props: {
        footerLinks: {
            type: Object,
            default: () => ({}),
        },
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapCmsBlockGetters(['staticBlockById']),
        ...mapConfigGetters(['freeDeliveryText']),

        footerSimpleStaticBlocks() {
            return {
                footerSimpleTop: this.staticBlockById(PSB_FOOTER_SIMPLE_TOP),
                footerSimpleBottomRight: this.staticBlockById(PSB_FOOTER_SIMPLE_BOTTOM_RIGHT),
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.footer-simple {
    @apply block px-20p;
    @apply bg-ds-container-secondary;

    &:deep() {
        .headline {
            @apply mb-2 pt-3 text-center font-semibold text-heading text-m leading-m
            border-border border-t-1;
        }

        .svg {
            @apply inline-block mr-2;
        }

        .links {
            @apply flex flex-row flex-wrap justify-center mx-auto;

            .text-link {
                @apply block w-full m-0 text-s text-center;

                &.phone.desktop {
                    @apply hidden;
                }

                .footer-img {
                    @apply inline-block mr-10p w-3;
                }
            }
        }

        .bottom-section {
            @apply flex justify-between border-t-1 border-border mt-3 py-3;

            .left {
                .text-link {
                    @apply font-semibold;
                    transition: color 0.5s;

                    .svg-icon {
                        transition: stroke 0.5s;
                    }

                    &:hover {
                        @apply no-underline text-ds-content-accent;

                        .svg-icon {
                            @apply stroke-primary;
                        }
                    }

                    .svg {
                        @apply align-text-top;
                    }
                }
            }

            .center,
            .right {
                @apply hidden;
            }
        }
    }

    .return-link {
        @apply flex items-center justify-end;
    }
}

@screen md {
    .footer-simple {
        &:deep() {
            .links {
                @apply flex flex-row flex-wrap justify-start mx-auto;
                max-width: 370px;

                .text-link {
                    @apply w-1/2 m-0 text-left;

                    &.phone {
                        &.mobile {
                            @apply hidden;
                        }

                        &.desktop {
                            @apply block;
                        }
                    }
                }
            }
        }
    }
}

@screen lg {
    .footer-simple {
        &:deep() {
            .headline {
                @apply mb-6 pt-7 leading-xl;
            }

            .links {
                @apply justify-center max-w-none;

                .text-link {
                    @apply mx-3 text-r w-auto;

                    .footer-img {
                        @apply w-auto align-top;
                    }
                }
            }

            .bottom-section {
                @apply mt-7 py-7;

                .left {
                    @apply w-3/10;
                }

                .center {
                    @apply block w-2/5 text-center;

                    .item {
                        @apply inline-block px-3 text-text text-s;

                        .item-svg {
                            @apply mr-0 align-text-top;
                        }

                        .svg-icon {
                            @apply stroke-text;
                        }
                    }
                }

                .right {
                    @apply block w-3/10;

                    .items-wrapper p {
                        @apply flex;
                    }

                    .text-link {
                        @apply text-s text-text px-3;
                    }
                }
            }
        }
    }
}
</style>

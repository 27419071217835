import {
    DAYS_FOR_RETURNS,
    STANDARD_DAYS_FOR_RETURNS,
    MODIVO_CLUB_DAYS_FOR_RETURNS,
} from '@localeConfig/keys';

import { isModivoClubTestEnabled } from '@modivo-club-assets/modivo-club';

export default {
    data() {
        return {
            daysForReturnsKey: '{days} days for free return',
        };
    },

    computed: {
        isModivoClubTestEnabled() {
            return isModivoClubTestEnabled(this.$abTests);
        },

        daysForReturnsText() {
            if (!this.isModivoClubTestEnabled) {
                return this.$t(this.daysForReturnsKey, {
                    days: this.$configProvider.getConfig(DAYS_FOR_RETURNS),
                });
            }

            const standardDaysReturns = this.$t(this.daysForReturnsKey, {
                days: this.$configProvider.getConfig(STANDARD_DAYS_FOR_RETURNS),
            });

            const modivoClubDaysReturns = this.$tModivoClub('{days} days for MODIVOclub members', {
                days: this.$configProvider.getConfig(MODIVO_CLUB_DAYS_FOR_RETURNS),
            });

            return `${standardDaysReturns}, ${modivoClubDaysReturns}`;
        },
    },
};

import { v4 as uuid } from 'uuid';
import { createNamespacedHelpers } from 'vuex';

import { CATALOG_PAGE_NAME } from '@search/routing/names';

import { USER_FIRST_VISIT_KEY } from '@configs/storage';
import { THEME_COLOR } from '@configs/x3/config-keys';
import { PAGES_WITH_WIDGET as FIT_ANALYTICS_PAGES_WITH_WIDGET } from '@configs/fit-analytics';
import { PSB_FOOTER_CONFIGURATION } from '@configs/footer';

import { CMS_FOOTER_CONFIGURATION } from '@modules/page-builder/page-builder.config';

import TitleBaseDomain from '@mixins/TitleBaseDomain';
import WithCanonicalUrl from '@mixins/WithCanonicalUrl';

import { formatDate } from '@assets/date';

import OgImage from '@static/img/og-logo-modivo.jpg';

const { mapState: mapConfigState, mapGetters: mapConfigGetters } = createNamespacedHelpers(
    'config'
);

const { mapState: mapStateLayout } = createNamespacedHelpers('layout');

const { mapActions: mapActionsMessages } = createNamespacedHelpers('messages');

const { mapGetters: mapCmsBlockGetters } = createNamespacedHelpers('cmsBlock');

export default {
    mixins: [WithCanonicalUrl, TitleBaseDomain],

    middleware: ['global/set-error-handler-page-context', 'global/normalized-path-redirect'],

    data() {
        return {
            offlineMessageId: null,
        };
    },

    computed: {
        ...mapStateLayout(['htmlClasses']),
        ...mapConfigState(['baseUrl', 'globalConfig']),
        ...mapConfigGetters(['locale']),
        ...mapCmsBlockGetters(['getComponentFromPageBuilderBlock']),

        pageUrl() {
            return this.$absoluteUrl(this.$route.fullPath);
        },

        ogLogoPath() {
            return this.baseUrl + OgImage;
        },

        languageCode() {
            return this.locale.slice(0, 2);
        },

        socialMediaLinks() {
            const blockComponent = this.getComponentFromPageBuilderBlock(
                PSB_FOOTER_CONFIGURATION,
                CMS_FOOTER_CONFIGURATION
            );

            return Object.values(blockComponent.props || {}).filter(link => !!link);
        },
    },

    methods: {
        ...mapActionsMessages(['addInfoMessage', 'removeMessage']),

        captureFirstVisitData() {
            const firstVisitDate = this.$storage.getItem(USER_FIRST_VISIT_KEY);

            if (!firstVisitDate) {
                this.$storage.setItem(USER_FIRST_VISIT_KEY, formatDate(new Date()));
            }
        },

        isOffline(offline) {
            if (offline) {
                this.offlineMessageId = uuid();

                this.addInfoMessage({
                    text: this.$t(
                        // eslint-disable-next-line max-len
                        'You are currently offline. Please check your internet connection and try again.'
                    ),
                    timeout: false,
                    messageId: this.offlineMessageId,
                    handler: () => window.location.reload(),
                    label: this.$t('Reconnect'),
                });
            } else if (this.offlineMessageId !== null) {
                this.removeMessage(this.offlineMessageId);
                this.offlineMessageId = null;
            }
        },
    },

    beforeMount() {
        this.captureFirstVisitData();
    },

    watch: {
        '$nuxt.isOffline': 'isOffline',
    },

    head() {
        const { baseUrl, titleBaseDomain } = this;

        const script = [
            {
                hid: 'ldjson-schema-organization',
                innerHTML: JSON.stringify({
                    '@context': 'http://schema.org/',
                    '@type': 'WebSite',
                    name: titleBaseDomain,
                    url: baseUrl,
                    sameAs: this.socialMediaLinks,
                    potentialAction: {
                        '@type': 'SearchAction',
                        target: `${baseUrl}${decodeURIComponent(this.$createSearchPath('{q}'))}`,
                        'query-input': 'required name=q',
                    },
                }),
                type: 'application/ld+json',
            },
        ];

        let canonicalLink = [
            {
                hid: 'canonical',
                rel: 'canonical',
                href: this.canonicalUrl,
            },
        ];

        if (this.$route.name === CATALOG_PAGE_NAME) {
            canonicalLink = [];
        }

        const brand = this.$configProvider.getConfig('brand');

        const link = [
            ...canonicalLink,
            ...this.$hashedFiles.getCssLinksForBrand(brand),
            ...this.$xPwa.getMetaLinksForBrand(brand, this.locale),
        ];

        if (
            this.globalConfig.isFitAnalyticsWidgetEnabled &&
            FIT_ANALYTICS_PAGES_WITH_WIDGET.includes(this.$route.name)
        ) {
            link.push({
                rel: 'preconnect',
                href: '//integrations.fitanalytics.com',
            });
        }

        const { appName, [THEME_COLOR]: themeColor } = this.$configProvider.getConfig([
            'appName',
            THEME_COLOR,
        ]);

        return {
            titleTemplate: titleChunk =>
                titleChunk ? `${titleChunk} | ${titleBaseDomain}` : titleBaseDomain,
            link,
            meta: [
                {
                    hid: 'og:url',
                    property: 'og:url',
                    content: this.pageUrl,
                },
                {
                    hid: 'og:site_name',
                    property: 'og:site_name',
                    content: titleBaseDomain,
                },
                {
                    hid: 'og:image',
                    property: 'og:image',
                    content: this.ogLogoPath,
                },
                {
                    hid: 'og:image:secure_url',
                    property: 'og:image:secure_url',
                    content: this.ogLogoPath,
                },
                {
                    hid: 'og:image:width',
                    property: 'og:image:width',
                    content: '1200',
                },
                {
                    hid: 'og:image:height',
                    property: 'og:image:height',
                    content: '630',
                },
                {
                    hid: 'og:image:alt',
                    property: 'og:image:alt',
                    content: titleBaseDomain,
                },
                {
                    hid: 'og:image:type',
                    property: 'og:image:type',
                    content: 'image/jpg',
                },
                {
                    hid: 'og:locale',
                    property: 'og:locale',
                    content: this.locale,
                },
                {
                    hid: 'application-name',
                    name: 'application-name',
                    content: appName,
                },
                {
                    hid: 'apple-mobile-web-app-title',
                    name: 'apple-mobile-web-app-title',
                    content: appName,
                },
                {
                    hid: 'og:site_name',
                    property: 'og:site_name',
                    content: appName,
                },
                {
                    hid: 'og:title',
                    property: 'og:title',
                    content: appName,
                },
                {
                    hid: 'theme-color',
                    name: 'theme-color',
                    content: themeColor,
                },
            ],
            htmlAttrs: {
                class: this.htmlClasses.join(' '),
                lang: this.languageCode,
            },
            script,
            __dangerouslyDisableSanitizersByTagID: {
                'ldjson-schema-organization': ['innerHTML'],
            },
        };
    },
};

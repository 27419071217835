const { namespace } = require('./index');

function tModivoClub(translationKey, variables) {
    return this.t(`${namespace}.${translationKey}`, variables);
}

function tModivoClubPath(translationKey, variables) {
    const path = this.t(`__paths.${namespace}.${translationKey}`, variables);

    return `/${path}`;
}

export default function ({ app }, inject) {
    app.i18n.tModivoClub = tModivoClub.bind(app.i18n);
    app.i18n.tModivoClubPath = tModivoClubPath.bind(app.i18n);

    app.$tModivoClub = app.i18n.tModivoClub.bind(app.i18n);
    app.$tModivoClubPath = app.i18n.tModivoClubPath.bind(app.i18n);

    inject('tModivoClub', app.$tModivoClub);
    inject('tModivoClubPath', app.$tModivoClubPath);
}

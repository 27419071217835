export default () => ({
    cartId: null,
    cartData: null,
    complexProductDataItems: [],
    isCartDataValid: false,
    isCartDataLoading: true,
    isAddToCartPending: false,
    padData: null,
    invalidCouponData: {},
    wishlistProducts: [],
    giftCards: [],
    storedGiftCards: [],
    additionalPaymentMethodId: '',
    loader: { count: 0, text: '' },
});

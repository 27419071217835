<template>
    <div class="checkout">
        <MarketingBarDataProvider />
        <LoyaltyClubProvider #default="{ isSubscribed, status, statusColor, isLoyaltyClubActive }">
            <HeaderSimpleWrapper>
                <StepProgressBar :steps="STEPS" :current-step="CURRENT_STEP" />

                <template v-if="isLoyaltyClubActive && isSubscribed" #right-start>
                    <div class="loyalty-status">
                        <SvgIcon class="item-icon" :fill-color="statusColor">
                            <Mfc />
                        </SvgIcon>

                        <div>
                            {{ $t('Status') }}:
                            <span :style="{ color: statusColor }" class="status">
                                {{ status }}
                            </span>
                        </div>
                    </div>
                </template>
            </HeaderSimpleWrapper>
        </LoyaltyClubProvider>
        <main class="checkout-wrapper">
            <nuxt />
        </main>
        <Messages />
        <FooterSimple :footer-links="FOOTER_LINKS" />
        <ChatWrapper />
        <GlobalModals />
        <SmartBannerWrapper />
    </div>
</template>

<script>
import { CHECKOUT_CART_PAGE as cartPath } from '@router/paths';

import BaseLayout from '@layouts/mixins/BaseLayout';

import LoyaltyClubProvider from '@functionals/LoyaltyClubProvider/LoyaltyClubProvider';

import Mfc from '@static/icons/24px/mfc.svg?inline';

import StepProgressBar from '@atoms/StepProgressBar/StepProgressBar';
import SvgIcon from '@atoms/SvgIcon/SvgIcon';

import ChatWrapper from '@molecules/ChatWrapper/ChatWrapper';
import HeaderSimpleWrapper from '@molecules/HeaderSimpleWrapper/HeaderSimpleWrapper';
import FooterSimple from '@molecules/FooterSimple/FooterSimple';
import Messages from '@molecules/Messages/Messages';
import SmartBannerWrapper from '@molecules/SmartBanner/SmartBannerWrapper';

import GlobalModals from '@organisms/GlobalModals/GlobalModals';
import MarketingBarDataProvider from '@marketing-bar-components/organisms/MarketingBarDataProvider/MarketingBarDataProvider';

export default {
    name: 'CheckoutLayoutV2',

    components: {
        SmartBannerWrapper,
        ChatWrapper,
        LoyaltyClubProvider,
        HeaderSimpleWrapper,
        Messages,
        FooterSimple,
        StepProgressBar,
        Mfc,
        SvgIcon,
        GlobalModals,
        MarketingBarDataProvider,
    },

    mixins: [BaseLayout],

    beforeCreate() {
        this.FOOTER_LINKS = {
            goBackTitle: this.$t('Back to cart'),
            goBackPath: `/${cartPath}`,
        };

        this.STEPS = [
            { name: this.$t('Products'), path: `/${cartPath}` },
            { name: this.$t('Delivery and payment'), path: '' },
            { name: this.$t('Confirmation'), path: '' },
        ];

        this.CURRENT_STEP = 2;
    },
};
</script>

<style lang="scss" scoped>
.checkout {
    &:deep() {
        .header-simple {
            @apply bg-light;
        }
    }

    @screen lg {
        &:deep() {
            .header-simple {
                @apply bg-transparent;
            }
        }

        .status {
            @apply uppercase font-semibold;
        }

        .loyalty-status {
            @apply flex mr-6 whitespace-nowrap;
        }

        .item-icon {
            @apply mr-2 text-text;
        }
    }
}
</style>

import { HTTP_CLIENT_ENDPOINT_NAME_AVATAR } from '@configs/http-client';

const ENDPOINT_PARAMETER = { endpoint: HTTP_CLIENT_ENDPOINT_NAME_AVATAR };

export default httpClient => ({
    verifyPhoneNumber({ phoneNumber }) {
        return httpClient.post('/loyalty/phone-verification', {
            ...ENDPOINT_PARAMETER,
            payload: {
                phoneNumber,
            },
        });
    },

    verifyPin({ pin }) {
        return httpClient.post('/loyalty/register', {
            ...ENDPOINT_PARAMETER,
            payload: {
                pin,
            },
        });
    },

    /*
     * for PIN 1111 returns 201
     */
    resendPin() {
        return httpClient.post('/loyalty/resend-pin', {
            ...ENDPOINT_PARAMETER,
        });
    },

    memberInfo() {
        return httpClient.get('/loyalty/member', {
            ...ENDPOINT_PARAMETER,
        });
    },
});

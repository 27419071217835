<template>
    <div>
        <MarketingBarDataProvider />
        <HeaderWrapper />
        <main>
            <ContainerContent>
                <div class="error">
                    <HeaderText
                        :tag="HEADER_TEXT_TAG"
                        :size="HEADER_TEXT_SIZE"
                        :align="HEADER_TEXT_ALIGN"
                    >
                        {{ $t('Oops... this page no longer exists') }}
                    </HeaderText>
                    <BaseParagraph>
                        {{ $t('It appears to have been moved or removed.') }}
                    </BaseParagraph>
                    <BaseParagraph>
                        {{
                            $t(
                                // eslint-disable-next-line max-len
                                'Check out our proposals below and maybe you will find what you are looking for!'
                            )
                        }}
                    </BaseParagraph>

                    <ButtonLarge :variant="SECONDARY" to="/" class="button">
                        {{ $t('Go to homepage') }}
                    </ButtonLarge>
                </div>
            </ContainerContent>

            <RecommendedProductsSimpleSliderWrapperNew
                :recommendation-type="WEB_ERROR_PAGE_1"
                :placement-page-name="ERROR_PAGE_PLACEMENT_PAGE_NAME"
            />
        </main>
        <Messages />
        <BaseFooter />
        <ChatWrapper />
    </div>
</template>

<script>
import { ERROR_PAGE_PLACEMENT_PAGE_NAME } from '@configs/synerise';

import { WEB_ERROR_PAGE_1 } from '@types/Synerise';

import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';

import ChatWrapper from '@molecules/ChatWrapper/ChatWrapper';
import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import Messages from '@molecules/Messages/Messages';

import BaseFooter from '@organisms/BaseFooter/BaseFooter';
import RecommendedProductsSimpleSliderWrapperNew from '@organisms/RecommendedProductsSimpleSliderWrapperNew/RecommendedProductsSimpleSliderWrapperNew';
import HeaderWrapper from '@header-organisms/HeaderWrapper/HeaderWrapper';

import MarketingBarDataProvider from '@marketing-bar-components/organisms/MarketingBarDataProvider/MarketingBarDataProvider';

import { ButtonLarge, BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';
import {
    HeaderText,
    HEADER_TEXT_TAGS,
    HEADER_TEXT_SIZES,
    HEADER_TEXT_ALIGNS,
} from '@modivo-ui/components/HeaderText/v1';

export default {
    name: 'ErrorPage404',

    components: {
        RecommendedProductsSimpleSliderWrapperNew,
        ChatWrapper,
        BaseFooter,
        ContainerContent,
        HeaderText,
        BaseParagraph,
        ButtonLarge,
        HeaderWrapper,
        Messages,
        MarketingBarDataProvider,
    },

    beforeCreate() {
        this.SECONDARY = BUTTON_LARGE_VARIANTS.SECONDARY;

        this.WEB_ERROR_PAGE_1 = WEB_ERROR_PAGE_1;
        this.ERROR_PAGE_PLACEMENT_PAGE_NAME = ERROR_PAGE_PLACEMENT_PAGE_NAME;

        this.HEADER_TEXT_TAG = HEADER_TEXT_TAGS.H1;
        this.HEADER_TEXT_SIZE = HEADER_TEXT_SIZES.M;
        this.HEADER_TEXT_ALIGN = HEADER_TEXT_ALIGNS.CENTER;
    },
};
</script>

<style lang="scss" scoped>
.error {
    @apply text-center pt-ds-6 pb-ds-7 mb-ds-6;

    &:deep() {
        ._heading-h5 {
            @apply pb-4;
        }

        .paragraph {
            @apply text-center;
        }
    }

    .button {
        @apply mt-ds-7;
    }

    @screen lg {
        @apply pt-10 pb-7;

        ._heading-h5 {
            @apply text-xxl leading-xxl;
        }
    }
}
</style>

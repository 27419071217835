export const BAD_REQUEST = 'BAD_REQUEST';

export const PIN_NOT_MATCHING_OR_CARD_NOT_FOUND = 'PIN_NOT_MATCHING_OR_CARD_NOT_FOUND';
export const CARD_EXPIRED = 'CARD_EXPIRED';
export const CARD_BALANCE_IS_ZERO = 'CARD_BALANCE_IS_ZERO';

export const ERROR_MAP = {
    [PIN_NOT_MATCHING_OR_CARD_NOT_FOUND]: 'Card data is invalid',
    [CARD_EXPIRED]: 'Gift card has expired',
    [CARD_BALANCE_IS_ZERO]: 'The card has already been fully used',
};

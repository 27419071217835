<template>
    <div class="base-footer">
        <div>
            <FooterContent />

            <template v-if="isNotBot && !isFetchingConsentsData">
                <ConsentsDataProvider
                    v-if="isConsentsModalConfigured"
                    #default="{
                        agreeAllConsents,
                        categories,
                        closeModal,
                        isModalOpen,
                        openModal,
                        providers,
                        setStoredCategories,
                        isTrustedPartnersModalOpen,
                        isModalCookieSettingsOpen,
                        openModalTrustedPartners,
                        closeModalTrustedPartners,
                        openModalCookieSettingsOpen,
                        closeModalCookieSettingsOpen,
                    }"
                >
                    <LoadCookieModal
                        :categories="categories"
                        :is-modal-consents-open="isModalOpen"
                        :is-trusted-partners-modal-open="isTrustedPartnersModalOpen"
                        :is-modal-cookie-settings-open="isModalCookieSettingsOpen"
                        :providers="providers"
                        @agree-all-consents="agreeAllConsents()"
                        @open-consents-modal="openModal()"
                        @close-consents-modal="closeModal()"
                        @open-modal-trusted-partners="openModalTrustedPartners()"
                        @close-modal-trusted-partners="closeModalTrustedPartners()"
                        @open-modal-cookie-settings="openModalCookieSettingsOpen()"
                        @close-modal-cookie-settings="closeModalCookieSettingsOpen()"
                        @set-stored-categories="setStoredCategories($event)"
                    />
                </ConsentsDataProvider>
                <CheckCookieExist
                    v-else
                    #default="{ isCookieExist }"
                    :cookie-name="COOKIES_MODAL_COOKIE_NAME"
                >
                    <CookiesApprovals v-if="shouldDisplayCookiesModal(isCookieExist)" />
                </CheckCookieExist>
            </template>

            <LoyaltyClubProvider
                #default="{
                    shouldDisplayModalAfterLogin,
                    closeModalAfterLogin,
                    openLoyaltyClubRegistrationModal,
                    shouldDisplayLoyaltyClubNewStatus,
                    closeNewStatusModal,
                    previousStatus,
                    status,
                    shareId,
                    closeShareModal,
                    closeLoyaltyClubRegisterModal,
                    isLoyaltyClubActive,
                }"
            >
                <div v-if="isLoyaltyClubActive">
                    <ModalLoyaltyClubAfterLogin
                        v-if="
                            shouldDisplayModalAfterLogin &&
                            !isMagicLinkAuthModalOpen &&
                            !isRegistrationBySocialMedia &&
                            !shouldOpenModalNewsletterConfirmation
                        "
                        @open-loyalty-club-registration-modal="openLoyaltyClubRegistrationModal()"
                        @close="closeModalAfterLogin()"
                    />
                    <ModalLoyaltyClubStatus
                        v-if="shouldDisplayLoyaltyClubNewStatus"
                        :previous-status="previousStatus"
                        :status="status"
                        @close="closeNewStatusModal()"
                    />
                    <ModalShareLoyaltyClub
                        v-if="shouldOpenModalShareLoyaltyClubFlag"
                        :share-id="shareId"
                        @close="closeShareModal()"
                    />
                    <LoyaltyClubRegistrationWrapper
                        @open-loyalty-club-registration-modal="openLoyaltyClubRegistrationModal()"
                        @close-loyalty-club-registration-modal="closeLoyaltyClubRegisterModal()"
                    />
                </div>
            </LoyaltyClubProvider>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { COOKIES_MODAL_COOKIE_NAME } from '@configs/storage';
import { MODAL_MAGIC_LINK_AUTH } from '@configs/modals';

import LoyaltyClubProvider from '@functionals/LoyaltyClubProvider/LoyaltyClubProvider';
import CheckCookieExist from '@functionals/CheckCookieExist/CheckCookieExist';

import LoyaltyClubRegistrationWrapper from '@organisms/LoyaltyClubRegistrationWrapper/LoyaltyClubRegistrationWrapper';
import FooterContent from '@organisms/FooterContent/FooterContent';

const { mapState: mapCustomerState } = createNamespacedHelpers('customer');
const { mapState: mapConsentsState, mapActions: mapConsentsActions } = createNamespacedHelpers(
    'consents'
);

export default {
    name: 'BaseFooter',

    components: {
        FooterContent,
        LoyaltyClubProvider,
        CheckCookieExist,
        LoyaltyClubRegistrationWrapper,
        LoadCookieModal: () => ({
            component: import(
                /* webpackChunkName: "load-cookie-modal" */
                '@functionals/LoadCookieModal/LoadCookieModal'
            ),
        }),
        ModalLoyaltyClubStatus: () => ({
            component: import(
                /* webpackChunkName: "modal-loyalty-club-status" */
                '@organisms/ModalLoyaltyClubStatus/ModalLoyaltyClubStatus'
            ),
        }),
        ModalLoyaltyClubAfterLogin: () => ({
            component: import(
                /* webpackChunkName: "modal-loyalty-club-after-login" */
                '@organisms/ModalLoyaltyClubAfterLogin/ModalLoyaltyClubAfterLogin'
            ),
        }),
        ModalShareLoyaltyClub: () => ({
            component: import(
                /* webpackChunkName: "modal-share-loyalty-club" */
                '@organisms/ModalShareLoyaltyClub/ModalShareLoyaltyClub'
            ),
        }),
        CookiesApprovals: () => ({
            component: import(
                /* webpackChunkName: "cookies-approvals" */
                '@organisms/CookiesApprovals/CookiesApprovals'
            ),
        }),
        ConsentsDataProvider: () => ({
            component: import(
                /* webpackChunkName: "load-cookie-modal" */
                '@functionals/ConsentsDataProvider/ConsentsDataProvider'
            ),
        }),
    },

    data() {
        return {
            isFetchingConsentsData: true,
        };
    },

    computed: {
        ...mapCustomerState([
            'shouldOpenModalShareLoyaltyClubFlag',
            'isRegistrationBySocialMedia',
            'shouldOpenModalNewsletterConfirmation',
        ]),
        ...mapConsentsState(['isConsentsModalConfigured']),

        isNotBot() {
            return !this.$ua?.isBot;
        },

        isMagicLinkAuthModalOpen() {
            return this.$modals.isOpen(MODAL_MAGIC_LINK_AUTH);
        },
    },

    beforeCreate() {
        this.COOKIES_MODAL_COOKIE_NAME = COOKIES_MODAL_COOKIE_NAME;
    },

    async beforeMount() {
        if (this.isNotBot) {
            await this.fetchConfigForConsentsModal();
        }

        this.isFetchingConsentsData = false;
    },

    methods: {
        ...mapConsentsActions(['fetchConfigForConsentsModal']),

        shouldDisplayCookiesModal(cookieExists) {
            return typeof cookieExists === 'boolean' && cookieExists === false;
        },
    },
};
</script>

<style lang="scss" scoped>
.base-footer {
    margin-top: theme('customVariables.baseFooter.mobile.marginTop');

    @screen lg {
        @apply mt-8;
    }
}
</style>

import { createStorage, isStorageSupported } from '@services/storage';

export default ({ app, store }, inject) => {
    const { brand } = app.$configProvider;

    const { storage, sessionStorage, localStorage } = createStorage({
        errorHandler: app.$errorHandler,
        brand,
    });

    inject('storage', storage);
    inject('sessionStorage', sessionStorage);
    inject('localStorage', localStorage);

    if (!isStorageSupported) {
        store.dispatch('messages/addInfoMessage', {
            text: app.i18n.t(
                // eslint-disable-next-line max-len
                'The browser operating in the current mode does not allow our website to function properly. Go to the default mode or use a different browser'
            ),
        });
    }
};

const { resolve } = require('path');

const {
    GENERAL_TRANSLATIONS_KEY,
    PATH_TRANSLATIONS_KEY,
} = require('../../../../server/trans/config');

const MODIVO_CLUB_I18N_NAMESPACE = '__modivo-club';

module.exports = {
    namespace: MODIVO_CLUB_I18N_NAMESPACE,

    generateFilePaths: lang => ({
        [GENERAL_TRANSLATIONS_KEY]: resolve(__dirname, `./translations/${lang}.json`),
        [PATH_TRANSLATIONS_KEY]: resolve(__dirname, `./translations/paths/${lang}.json`),
    }),
};

import { EXTRA_PAYMENT_METHOD_SET_OPERATION_NAME } from '@configs/http-client';

import { normalizePrePaidCards } from '@normalizers/models/prePaidCards';
import { normalizeOrderAmount } from '@normalizers/models/orderAmount';

const normalizer = async response => {
    const { data, error } = response;

    if (error || !data) {
        return response;
    }

    response.data.prePaidCards = normalizePrePaidCards(data.prePaidCards);
    response.data.orderAmount = normalizeOrderAmount(data.orderAmount);

    return response;
};

export const extractPaymentMethodSet = {
    operationName: EXTRA_PAYMENT_METHOD_SET_OPERATION_NAME,
    normalizer,
};

<template>
    <Icon
        :icon="qrCode"
        :class="{ 'is-loaded': isQrCodeLoaded }"
        width="128"
        height="128"
        class="qr-code-icon"
    />
</template>

<script>
import { Icon } from '@modivo-ui/components/Icon/v1';
import { SpiningLoader } from '@modivo-ui/icons/v1/other';

export default {
    name: 'MobileAppQrCode',

    components: {
        Icon,
    },

    data() {
        return {
            qrCode: SpiningLoader,
            isQrCodeLoaded: false,
        };
    },

    async mounted() {
        const qrCode = await import(
            /* webpackChunkName: "qr-code-all" */
            // eslint-disable-next-line max-len
            `@static/brands/${this.$configProvider.brand}/mobile-app-qr-codes/qr-code-all.svg?inline`
        );

        this.qrCode = qrCode.default;
        this.isQrCodeLoaded = true;
    },
};
</script>

<style lang="scss" scoped>
.qr-code-icon {
    @apply rounded-ds-container-medium;
    @apply bg-ds-container-primary;
    @apply p-ui-9;

    &.is-loaded {
        @apply p-ui-2;
    }
}
</style>

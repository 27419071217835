<template>
    <div class="dev-panel" :class="classes">
        <button type="button" class="toggle-button" @click="toggleHidden()">
            <Icon :icon="toggleIcon" />
        </button>

        <ButtonFAB
            :variant="buttonVariant"
            @default-button-click="toggleMembership()"
            @second-button-click="toggleMembershipStatus()"
        >
            <template #label> {{ labelText }} </template>
            <template v-if="isModivoClubMember" #second-label> {{ secondaLabelText }} </template>
        </ButtonFAB>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { MODIVO_CLUB_STATUSES } from '@modivo-club-types/statuses';

import { BUTTON_FAB_VARIANTS, ButtonFAB } from '@modivo-ui/components/ButtonFAB/v1';
import { Icon } from '@modivo-ui/components/Icon/v1';

import { ChevronDown, ChevronUp } from '@modivo-ui/icons/v2/navigation';

const {
    mapState: mapModivoClubState,
    mapGetters: mapModivoClubGetters,
    mapActions: mapModivoClubActions,
} = createNamespacedHelpers('modivoClub');

export default {
    name: 'DevPanel',

    components: {
        ButtonFAB,
        Icon,
    },

    data() {
        return {
            hidden: false,
        };
    },

    computed: {
        ...mapModivoClubState(['membershipStatus']),
        ...mapModivoClubGetters(['isModivoClubMember', 'isModivoClubGoldMember']),

        labelText() {
            return this.isModivoClubMember ? 'You are club member' : 'Join to club';
        },

        secondaLabelText() {
            return `status: ${
                this.isModivoClubGoldMember
                    ? MODIVO_CLUB_STATUSES.GOLD
                    : MODIVO_CLUB_STATUSES.STANDARD
            }`;
        },

        buttonVariant() {
            return this.isModivoClubMember
                ? BUTTON_FAB_VARIANTS.TWO_ACTIONS
                : BUTTON_FAB_VARIANTS.BASIC;
        },

        toggleIcon() {
            return this.hidden ? ChevronUp : ChevronDown;
        },

        classes() {
            return {
                hidden: this.hidden,
            };
        },
    },

    beforeCreate() {
        this.BUTTON_FAB_TWO_BUTTONS = BUTTON_FAB_VARIANTS.TWO_ACTIONS;
    },

    methods: {
        ...mapModivoClubActions(['setMembershipInfo']),

        toggleMembership() {
            this.setMembershipInfo({
                status: this.isModivoClubMember ? '' : MODIVO_CLUB_STATUSES.STANDARD,
                subscriptionExpiresAt: null,
            });
        },

        toggleMembershipStatus() {
            this.setMembershipInfo({
                status: this.isModivoClubGoldMember
                    ? MODIVO_CLUB_STATUSES.STANDARD
                    : MODIVO_CLUB_STATUSES.GOLD,
                subscriptionExpiresAt: '2026-04-03T12:15:21.224Z',
            });
        },

        toggleHidden() {
            this.hidden = !this.hidden;
        },
    },
};
</script>

<style lang="scss" scoped>
.dev-panel {
    @apply fixed flex flex-col items-center;
    @apply left-1/2 bottom-ds-4;
    transform: translateX(-50%);
    @apply z-ds-8;

    &.hidden {
        @apply -bottom-ds-9;
    }

    .toggle-button {
        @apply rounded-t-ds-control-full border-ds-1 border-ds-border;
        @apply px-ds-2;
        @apply bg-ds-container-reverse;
        @apply text-ds-content-reverse;
    }
}
</style>

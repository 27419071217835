<template>
    <div class="navigation-content">
        <div class="nav-limiter">
            <div class="link-section" :class="{ 'without-banners': !isCmsDisplayed }">
                <section
                    v-for="(content, index) in categories"
                    :key="content.id"
                    class="link-container"
                    :style="getContainerWidth(content.categoryLinks.length)"
                >
                    <HeaderText
                        :tag="HEADER_TEXT_TAG_H3"
                        :size="HEADER_TEXT_SIZE_M"
                        class="category-title"
                    >
                        {{ content.title }}
                    </HeaderText>

                    <NavigationSectionLinks :section="content" />

                    <Divider
                        v-if="index !== categories.length && !!categories[index + 1]"
                        :variant="DIVIDER_VARIANT"
                        class="nav-divider"
                    />
                </section>
            </div>

            <div v-if="isCmsDisplayed" class="images-container">
                <WithLazyImages
                    :load-condition="isHovered"
                    img-attr-selector="data-psb-lazy-img"
                    img-src-attr="data-src"
                >
                    <StaticBlock :content="cmsBlock.content" />
                </WithLazyImages>
            </div>
        </div>
    </div>
</template>

<script>
import { NAVIGATION_SHOW_ALL_ITEM, NAVIGATION_NESTED_ITEM_LINK } from '@types/AutomaticTestIDs';

import WithLazyImages from '@molecules/WithLazyImages/WithLazyImages';
import StaticBlock from '@atoms/StaticBlock/StaticBlock';
import NavigationSectionLinks from '@header-molecules/NavigationSectionLinks/NavigationSectionLinks';

import { Divider, DIVIDER_VARIANTS } from '@modivo-ui/components/Divider/v2';
import {
    HeaderText,
    HEADER_TEXT_TAGS,
    HEADER_TEXT_SIZES,
} from '@modivo-ui/components/HeaderText/v1';

export default {
    name: 'NavigationContent',

    components: {
        StaticBlock,
        NavigationSectionLinks,
        Divider,
        WithLazyImages,
        HeaderText,
    },

    props: {
        categories: {
            type: Array,
            required: true,
        },

        cmsBlock: {
            type: Object,
            default: () => {},
        },

        isHovered: {
            type: Boolean,
            default: false,
        },

        isInMobileMenu: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        isCmsDisplayed() {
            return !!this.cmsBlock?.content;
        },
    },

    beforeCreate() {
        this.DIVIDER_VARIANT = DIVIDER_VARIANTS.M;
        this.NAVIGATION_SHOW_ALL_ITEM = NAVIGATION_SHOW_ALL_ITEM;
        this.NAVIGATION_NESTED_ITEM_LINK = NAVIGATION_NESTED_ITEM_LINK;
        this.HEADER_TEXT_TAG_H3 = HEADER_TEXT_TAGS.H3;
        this.HEADER_TEXT_SIZE_M = HEADER_TEXT_SIZES.M;
    },

    methods: {
        getContainerWidth(numberOfLinks) {
            if (this.isInMobileMenu) {
                return null;
            }

            if (numberOfLinks > 10) {
                return {
                    width: this.isCmsDisplayed ? '66.66%' : '40%',
                };
            }

            return {
                width: this.isCmsDisplayed ? '33.33%' : '20%',
            };
        },
    },
};
</script>

<style scoped lang="scss">
@import '@theme/resources/mixin/aspect-ratio.scss';

.navigation-content {
    .nav-limiter {
        @apply flex flex-col;
    }

    .category-title {
        @apply lowercase truncate pb-ui-4 px-ui-4;

        &:first-letter {
            @apply uppercase;
        }
    }

    .link-section {
        @apply my-ui-7;
    }

    .nav-divider {
        @apply my-ui-7;
    }

    @screen lg {
        .nav-limiter {
            @apply px-ui-4 w-max-ui-content mx-auto py-ui-5;
            @apply grid;
            grid-template-columns: repeat(5, minmax(0, 1fr));
        }

        .nav-divider {
            @apply hidden;
        }

        .category-title {
            @apply px-ui-0 pb-ui-2;
        }

        .link-section {
            @apply my-ui-0;
            @apply col-ui-span-3;
            @apply flex;

            &.without-banners {
                @apply col-ui-span-5;
            }
        }

        .link-container {
            @apply pr-ui-2;
        }

        .images-container {
            @apply col-ui-span-2;

            &:deep() {
                .w-3\/6 {
                    @apply w-1/2;
                }

                .aspect-ratio-box {
                    @include aspect-ratio;
                }

                .aspect-318x318 {
                    @include aspect-ratio-sizing(318, 318);
                }

                .aspect-318x144 {
                    @include aspect-ratio-sizing(318, 144);
                }

                .aspect-300x352 {
                    @include aspect-ratio-sizing(300, 352);
                }
            }
        }
    }
}
</style>

import { NOT_FOUND_HTTP_CODE } from '@configs/http-codes';
import {
    MODAL_MODIVO_CLUB_REGISTRATION,
    MODAL_MODIVO_CLUB_REGISTRATION_STEP,
} from '@configs/modals';

import { BALANCE_TYPE_CASHBACK } from '@modivo-club-configs/cards';

import { types } from './mutations';

export default {
    async getMembershipInfo({ dispatch }) {
        try {
            const { data, status, error } = await this.app.$services.modivoClub.memberInfo();

            if (status === NOT_FOUND_HTTP_CODE) {
                return;
            }

            if (error) {
                throw error;
            }

            await dispatch('setMembershipInfo', data);
            // eslint-disable-next-line no-empty
        } catch {}
    },

    setMembershipInfo({ commit }, { status, subscriptionExpiresAt }) {
        commit(types.SET_MEMBERSHIP_INFO, { status, subscriptionExpiresAt });
    },

    clearMembershipInfo({ commit }) {
        commit(types.SET_MEMBERSHIP_INFO, { status: '', subscriptionExpiresAt: null });
    },

    async getWallet({ commit }) {
        try {
            const {
                data: { walletBalances },
                error,
            } = await this.app.$services.prePaid.getWallet();

            if (error) {
                throw new Error(error, { cause: error });
            }

            const cashbackCardData = walletBalances.find(
                ({ balanceType }) => balanceType === BALANCE_TYPE_CASHBACK
            )?.walletCards[0];

            if (cashbackCardData) {
                commit(types.UPDATE_CASHBACK_CLUB_CARD, cashbackCardData);

                return true;
            }
            // eslint-disable-next-line no-empty
        } catch {}

        return false;
    },

    clearWallet({ commit }) {
        commit(types.UPDATE_CASHBACK_CLUB_CARD, {});
    },

    async handleFetchMembershipAndWallet({ dispatch, getters }) {
        await dispatch('getMembershipInfo');

        if (getters.isModivoClubGoldMember) {
            await dispatch('getWallet');
        }
    },

    handleRegistrationModal({ getters: { isModivoClubMember, isModivoClubGoldMember } }) {
        if (isModivoClubMember && isModivoClubGoldMember) {
            return;
        }

        this.$modals.open(MODAL_MODIVO_CLUB_REGISTRATION, {
            [MODAL_MODIVO_CLUB_REGISTRATION_STEP]:
                isModivoClubMember && !isModivoClubGoldMember ? 3 : 1,
        });
    },
};

import { isObject } from '@assets/object';

export default class ConfigProvider {
    constructor(brand, brandConfig, environmentConfig, domainConfigs) {
        this.brand = brand;

        this.brandConfig = brandConfig || {};
        this.environmentConfig = environmentConfig || {};

        this.configCache = {};

        if (domainConfigs) {
            this.domainConfigs = domainConfigs;
        } else {
            this.domainConfigs = new Map();
        }

        this.applyEnvironmentConfig(this.environmentConfig);
    }

    registerDomainConfig(domain, config) {
        if (typeof domain !== 'string') {
            throw new Error(ConfigProvider.createLogMessage('Passed domain name must be a string'));
        }

        if (!isObject(config)) {
            throw new Error(ConfigProvider.createLogMessage('Config must be an object'));
        }

        const domainConfig = this.domainConfigs.get(domain) || {};

        Object.keys(config).forEach(key => {
            if (domainConfig[key]) {
                throw new Error(
                    ConfigProvider.createLogMessage(
                        `Key "${key}" is already defined in domain config for domain: "${domain}"`
                    )
                );
            }

            domainConfig[key] = config[key];
        });

        this.domainConfigs.set(domain, domainConfig);

        return field => this.getDomainConfig(domain, field);
    }

    isDomainConfigRegistered(domain) {
        return this.domainConfigs.has(domain);
    }

    getDomainConfigs() {
        return this.domainConfigs;
    }

    getDomainConfig(domain, field = null) {
        if (typeof domain !== 'string') {
            throw new Error(ConfigProvider.createLogMessage('Passed domain name must be a string'));
        }

        const domainConfig = this.domainConfigs.get(domain);

        if (!field) {
            return domainConfig;
        }

        return ConfigProvider.getConfigField(domainConfig, field);
    }

    getConfig(field = null) {
        const config = this.configCache[this.brand];

        if (!field) {
            return config;
        }

        return ConfigProvider.getConfigField(config, field);
    }

    applyEnvironmentConfig(environmentConfig) {
        const { brand } = this;

        if (this.configCache[brand]) {
            return this.configCache[brand];
        }

        const config = { ...this.brandConfig };

        Object.entries(environmentConfig).forEach(([key, value]) => {
            if (config[key]) {
                throw new Error(
                    ConfigProvider.createLogMessage(
                        `Key "${key}" is already defined in brand config`
                    )
                );
            }

            config[key] = value;
        });

        this.configCache[brand] = config;
    }

    static createLogMessage(message) {
        return `[ConfigProvider]: ${message}`;
    }

    static getConfigForBrand(brand, field = null) {
        if (!brand) {
            throw new Error(ConfigProvider.createLogMessage('Brand must be passed'));
        }

        const config = ConfigProvider.getConfig(brand);

        if (!field) {
            return config;
        }

        return ConfigProvider.getConfigField(config, field);
    }

    static getConfigField(config, field) {
        if (!isObject(config)) {
            throw new Error(ConfigProvider.createLogMessage('Config must be an object'));
        }

        const isString = typeof field === 'string';
        const isArray = Array.isArray(field);

        if (!(isString || isArray)) {
            throw new Error(ConfigProvider.createLogMessage('Field must be a string or an array'));
        }

        if (isArray) {
            return field.reduce((acc, key) => {
                acc[key] = config[key];

                return acc;
            }, {});
        }

        return config[field];
    }
}

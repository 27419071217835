import { IMAGE_TYPE_PRODUCT_CARD, PRODUCT_IMAGE_TYPES } from '@types/Image';

import DataLayerProduct from '@models/Analytics/DataLayerProduct';

import { getPlaceholder } from '@assets/images';
import { getDiscount } from '@assets/discount';

export const mapProductToDataLayerProduct = ({ product, index }) => {
    const {
        sku,
        badges,
        brandName,
        name,
        fason,
        price: {
            promotional: { amount: promotionalPrice },
            regular: { amount: regularPrice },
            omnibus: { amount: omnibusPrice },
        },
        recommendationCampaignName,
        category,
    } = product;

    const badgesMapped = badges.map(({ label }) => label).join('/');

    const position = index + 1;

    return DataLayerProduct.buildResult({
        id: sku,
        index,
        position,
        price: promotionalPrice,
        badges: badgesMapped,
        brand: brandName,
        brandName,
        name,
        fason,
        promotionalPrice: `${promotionalPrice}-${promotionalPrice}`,
        regularPrice: `${regularPrice}-${regularPrice}`,
        productType: DataLayerProduct.productTypes.RECO,
        recommendationCampaignName: recommendationCampaignName || '',
        category,
        omnibusPrice,
        discountedPrice: promotionalPrice,
    });
};

export const hasMatchingLastTwoCategories = (category1, category2) => {
    const regexp = /\/([^/]+)\/([^/]+)$/;
    const match1 = category1.match(regexp);
    const match2 = category2.match(regexp);

    if (match1 === null || match2 === null) {
        return false;
    }

    const [, beforeLast1, last1] = match1;
    const [, beforeLast2, last2] = match2;

    return beforeLast1 === beforeLast2 || last1 === last2;
};

const getAttributeValue = (attributes = {}, name = '') => {
    return attributes[name] || null;
};

export const mapProduct = ({ product, currency, $createProductPath, isOmnibusStrictEnabled }) => {
    const {
        title = null,
        brand = '',
        link = null,
        description = '',
        category = '',
        itemId = null,
        price = null,
        salePrice = null,
        imageLink = getPlaceholder(IMAGE_TYPE_PRODUCT_CARD)?.url,
        attributes,
        additionalImageLinks = [],
    } = product;

    const { width, height } = PRODUCT_IMAGE_TYPES[IMAGE_TYPE_PRODUCT_CARD];

    const images = [imageLink];

    if (additionalImageLinks.length && additionalImageLinks[0]) {
        images.push(additionalImageLinks[0]);
    }

    const priceFormatted = getAttributeValue(attributes, 'price_formatted');

    const salePriceFormatted = getAttributeValue(attributes, 'sale_price_formatted');

    const omnibusPrice = getAttributeValue(attributes, 'omnibus_price_amount') || null;

    const omnibusFormattedValue = getAttributeValue(attributes, 'omnibus_price_formatted');

    const omnibusPriceFormatted = parseInt(omnibusPrice, 10) ? omnibusFormattedValue : null;

    const omnibusDiscountSynerise = parseInt(getAttributeValue(attributes, 'omnibus_discount'), 10);

    const discountSynerise = parseInt(getAttributeValue(attributes, 'discount'), 10);

    const priceAmount = price?.value || null;
    const salePriceAmount = salePrice?.value || null;

    const regularPriceAmount = parseFloat(priceAmount) || 0;
    const finalPriceAmount = parseFloat(salePriceAmount) || 0;
    const omnibusPriceAmount = parseFloat(omnibusPrice) || 0;

    const discountRegular = parseInt(
        discountSynerise || getDiscount(regularPriceAmount, finalPriceAmount),
        10
    );

    let discount;

    if (isOmnibusStrictEnabled) {
        discount = omnibusDiscountSynerise ?? null;
    } else {
        discount = discountRegular ?? null;
    }

    const badges = (getAttributeValue(attributes, 'etykieta') || [])
        .map(badge => ({
            code: badge,
            label: badge,
        }))
        .slice(0, 2);

    const quantity = getAttributeValue(attributes, 'quantity');

    const fason = getAttributeValue(attributes, 'fason');

    const urlKey = getAttributeValue(attributes, 'url_key');

    const categoryMapped = category
        .split('>')
        .map(item => item.trim())
        .filter(item => item.length)
        .join('/');

    if (
        !priceAmount ||
        !salePriceAmount ||
        !priceFormatted ||
        !salePriceFormatted ||
        !urlKey ||
        !link ||
        !title ||
        !itemId
    ) {
        return null;
    }

    const query = link
        .split('?')
        .pop()
        .split('&')
        .reduce((acc, param) => {
            const [name, value] = param.split('=');

            acc[name] = value;

            return acc;
        }, {});

    return {
        name: title.replace('  ', ' '),
        nameDisplay: title.replace(brand, '').replace('  ', ' ').trim(),
        sku: itemId,
        brandName: brand,
        badges,
        fason,
        quantity,
        categories: [categoryMapped].filter(item => item),
        currency,
        url: $createProductPath(urlKey, query),
        pictureWidth: width,
        pictureHeight: height,
        images: images.map(imageUrl => {
            return {
                [IMAGE_TYPE_PRODUCT_CARD]: {
                    url: imageUrl,
                    width,
                    height,
                    alt: description,
                    sources: {},
                },
            };
        }),
        price: {
            discount: discount > 0 ? String(discount) : null,
            regular: {
                amount: regularPriceAmount,
                formatted: priceFormatted,
            },
            promotional: {
                amount: finalPriceAmount,
                formatted: salePriceFormatted,
            },
            omnibus: {
                amount: omnibusPriceAmount,
                formatted: omnibusPriceFormatted,
            },
        },
        variants: {},
    };
};

export const matchBestCategory = (categories, ids) => {
    const categoriesIds = categories.map(({ id }) => id);

    const category = ids.reduce(
        (bestCategory, currentId) => {
            const depth = categoriesIds.indexOf(currentId);

            if (depth > bestCategory.depth) {
                bestCategory.id = currentId;
                bestCategory.depth = depth;
            }

            return bestCategory;
        },
        {
            id: null,
            depth: -1,
        }
    );

    return category.id;
};

export const RECO_TEST_NAME = 'mod_all_recommendations';

export const RECO_TEST_VARIANT_HIDE_RECOMMENDATIONS = 'hide';
export const RECO_TEST_VARIANT_GENERIC_RECOMMENDATIONS = 'generic';

export const isRecommendationsOff = abTests =>
    abTests.getVariant(RECO_TEST_NAME) === RECO_TEST_VARIANT_HIDE_RECOMMENDATIONS;
export const isRecommendationsGeneric = abTests =>
    abTests.getVariant(RECO_TEST_NAME) === RECO_TEST_VARIANT_GENERIC_RECOMMENDATIONS;

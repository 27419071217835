import { PREVENT_SCROLL_CLASS_NAME } from '@configs/class-names';
import { types } from './mutations';

export default {
    addHTMLClasses({ commit }, classNames) {
        commit(types.ADD_HTML_CLASSES, classNames);

        const html = document.documentElement;

        if (
            classNames.includes(PREVENT_SCROLL_CLASS_NAME) &&
            !html.classList.contains(PREVENT_SCROLL_CLASS_NAME)
        ) {
            html.style.top = `${-window.scrollY}px`;
        }
    },

    removeHTMLClasses({ commit, dispatch }, classNames) {
        commit(types.REMOVE_HTML_CLASSES, classNames);

        const html = document.documentElement;

        if (
            classNames.includes(PREVENT_SCROLL_CLASS_NAME) &&
            html.classList.contains(PREVENT_SCROLL_CLASS_NAME)
        ) {
            dispatch('blockScrollToTop');
        }
    },

    blockScrollToTop() {
        const html = document.documentElement;
        const scrollY = html.style.top;

        html.classList.remove(PREVENT_SCROLL_CLASS_NAME);
        html.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    },
};

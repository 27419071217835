<template>
    <div class="header-top-bar">
        <div class="top-bar-content">
            <HeaderMainCategories :clear-state="isHomePage" />

            <div class="header-helpful-links">
                <component
                    :is="component"
                    v-for="{ title, path, content, component, isOpenedInNewTab } in links"
                    :key="path"
                    :path="path"
                    :title="title"
                    :target="isOpenedInNewTab ? '_blank' : null"
                    class="link-item"
                >
                    {{ content }}
                </component>
            </div>
        </div>

        <Divider class="top-bar-divider" />
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { HOME_PAGE_NAME } from '@router/names';

import { MOBILE_APP_LINK, STATIONARY_SHOP, BLOG_LINK } from '@localeConfig/keys';

import { isHttpLink } from '@assets/link';

import BaseLink from '@atoms/BaseLink/BaseLink';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';

import HeaderMainCategories from '@header-atoms/HeaderMainCategories/HeaderMainCategories';

import { Divider } from '@modivo-ui/components/Divider/v2';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'TopBar',

    components: {
        HeaderMainCategories,
        Divider,
        BaseLink,
        BaseLinkStatic,
    },

    computed: {
        ...mapConfigGetters(['localeConfigByKey']),

        isHomePage() {
            return this.$route.name === HOME_PAGE_NAME;
        },

        links() {
            return [
                {
                    path: this.localeConfigByKey(BLOG_LINK),
                    content: this.$t('Blog'),
                    title: this.$t('Blog'),
                },
                {
                    path: this.localeConfigByKey(MOBILE_APP_LINK),
                    content: this.$t('Download the app'),
                    title: this.$t('Download the app'),
                },
                {
                    path: this.localeConfigByKey(STATIONARY_SHOP),
                    content: this.$t('Find the store'),
                    title: this.$t('Find the store'),
                },
                {
                    path: this.$tPath('help-center'),
                    content: this.$t('Help'),
                    title: this.$t('Help'),
                },
            ]
                .filter(({ path }) => path)
                .map(link => {
                    const isStaticLink = isHttpLink(link.path);

                    return {
                        ...link,
                        component: isStaticLink ? BaseLinkStatic : BaseLink,
                        isOpenedInNewTab: isStaticLink,
                    };
                });
        },
    },
};
</script>

<style scoped lang="scss">
.header-top-bar {
    @apply h-ui-11;

    .top-bar-content {
        @apply px-ui-4 mx-auto flex justify-between w-max-ui-content;
    }

    .top-bar-divider {
        @apply relative top-[-2px];
    }

    .header-helpful-links {
        @apply flex items-center;
        @apply font-ui-body-s;

        .link-item {
            @apply flex items-center no-underline;

            &:not(:last-child) {
                &:after {
                    @apply content-['·'] mx-ui-2;
                }
            }
        }
    }
}
</style>

<template>
    <HeaderTooltip class="account-tooltip" :is-enabled="!isMobile">
        <HeaderActionButton :path="accountIconPath" :label="accountButtonText" :icon="User" />

        <template #tooltipContent>
            <div class="tooltip-container-wrapper">
                <AccountMenu v-if="isLoggedIn" />

                <div v-else class="logged-out-container">
                    <Icon :icon="Announcement" :width="iconSize" :height="iconSize" />

                    <HeaderText class="header-text" :align="HEADER_ALIGN" :size="HEADER_M">
                        {{ $t('Welcome to {brandName}!', { brandName }) }}
                    </HeaderText>

                    <BodyText class="body-text" :align="BODY_ALIGN" :size="BODY_M">
                        {{
                            $t(
                                // eslint-disable-next-line max-len
                                'Log in and see your purchases, favorite products and notifications.'
                            )
                        }}
                    </BodyText>

                    <div class="buttons">
                        <ButtonSmall :variant="PRIMARY_VARIANT" @click="showLoginModal()">
                            {{ $t('Login') }}
                        </ButtonSmall>

                        <ButtonSmall :variant="SECONDARY_VARIANT" @click="showRegisterModal()">
                            {{ $t('Create an account') }}
                        </ButtonSmall>
                    </div>
                </div>
            </div>
        </template>
    </HeaderTooltip>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import { MODAL_CUSTOMER_ACCOUNT_NAME, MODAL_TYPE } from '@configs/modals';

import {
    HEADER_MY_ACCOUNT_MODAL_LOGIN_CLICK,
    HEADER_MY_ACCOUNT_MODAL_REGISTER_CLICK,
} from '@analytics-types/Events';

import { REGISTER, LOGIN } from '@types/CustomerModal';

import { CUSTOMER_PAGE, LOGIN_PAGE } from '@router/paths';

import HeaderActionButton from '@header-atoms/HeaderActionButton/HeaderActionButton';
import HeaderTooltip from '@header-atoms/HeaderTooltip/HeaderTooltip';

import AccountMenu from '@header-molecules/AccountMenu/AccountMenu';

import { User } from '@modivo-ui/icons/v2/other';
import { Announcement } from '@modivo-ui/icons/v2/illustrations';

import { ButtonSmall, BUTTON_SMALL_VARIANTS } from '@modivo-ui/components/ButtonSmall/v2';

import {
    HeaderText,
    HEADER_TEXT_SIZES,
    HEADER_TEXT_ALIGNS,
} from '@modivo-ui/components/HeaderText/v1';
import { BodyText, BODY_TEXT_SIZES, BODY_TEXT_ALIGNS } from '@modivo-ui/components/BodyText/v1';
import { Icon } from '@modivo-ui/components/Icon/v1';

import { BRAND_SITE_NAME } from '@localeConfig/keys';

const { mapGetters: mapCustomerGetters } = createNamespacedHelpers('customer');

export default {
    name: 'AccountTooltip',

    components: {
        ButtonSmall,
        HeaderTooltip,
        AccountMenu,
        HeaderActionButton,
        HeaderText,
        BodyText,
        Icon,
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapCustomerGetters(['isLoggedIn']),

        accountIconPath() {
            return this.isLoggedIn ? this.accountPath : this.loginPath;
        },

        accountButtonText() {
            return this.isLoggedIn ? this.$t('My account') : this.$t('Login');
        },

        brandName() {
            return this.$configProvider.getConfig(BRAND_SITE_NAME);
        },
    },

    beforeCreate() {
        const { PRIMARY, SECONDARY } = BUTTON_SMALL_VARIANTS;

        this.PRIMARY_VARIANT = PRIMARY;
        this.SECONDARY_VARIANT = SECONDARY;
        this.HEADER_M = HEADER_TEXT_SIZES.M;
        this.BODY_M = BODY_TEXT_SIZES.M;
        this.HEADER_ALIGN = HEADER_TEXT_ALIGNS.CENTER;
        this.BODY_ALIGN = BODY_TEXT_ALIGNS.CENTER;

        this.User = User;
        this.Announcement = Announcement;

        this.iconSize = 64;
        this.accountPath = `/${CUSTOMER_PAGE}`;
        this.loginPath = `/${LOGIN_PAGE}`;
    },

    methods: {
        async showRegisterModal() {
            await this.$modals.open(MODAL_CUSTOMER_ACCOUNT_NAME, {
                [MODAL_TYPE]: REGISTER,
            });

            this.$analytics.emit(HEADER_MY_ACCOUNT_MODAL_REGISTER_CLICK);
        },

        async showLoginModal() {
            await this.$modals.open(MODAL_CUSTOMER_ACCOUNT_NAME, {
                [MODAL_TYPE]: LOGIN,
            });

            this.$analytics.emit(HEADER_MY_ACCOUNT_MODAL_LOGIN_CLICK);
        },
    },
};
</script>

<style scoped lang="scss">
.account-tooltip {
    @apply z-ui-2;

    .tooltip-container-wrapper {
        @apply w-[360px];

        .logged-out-container {
            @apply flex flex-col items-center px-ui-4 py-ui-6;

            .header-text {
                @apply mt-ui-4;
            }

            .body-text {
                @apply mt-ui-1;
            }

            .buttons {
                @apply mt-ui-6 flex flex-row gap-ui-4;
            }
        }
    }
}
</style>

import { LOYALTY_CLUB_PAGE } from '@router/paths';

const isLoyaltyClubHiddenTestOn = abTests => abTests.getVariant('dev_hide_mfc') === 'on';

const isLoyaltyClubPagePath = link => link === LOYALTY_CLUB_PAGE;

export default ({ app }, inject) => {
    const { $abTests } = app;

    inject('loyaltyClub', {
        isActive: !isLoyaltyClubHiddenTestOn($abTests),
        isPagePath: isLoyaltyClubPagePath,
    });
};

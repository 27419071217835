export const BRAND_LOGO = 'BRAND_LOGO';
export const BRAND_SITE_NAME = 'BRAND_SITE_NAME';
export const LOGO_EOB_NAME = 'LOGO_EOB_NAME';
export const EOB_DOMAIN_NAME = 'EOB_DOMAIN_NAME';
export const MOBILE_APP_LINK = 'MOBILE_APP_LINK';
export const FOOTER_DOWNLOAD_APP = 'FOOTER_DOWNLOAD_APP';
export const FOOTER_DOWNLOAD_APP_GALLERY = 'FOOTER_DOWNLOAD_APP_GALLERY';
export const SUCCESS_PAGE_DOWNLOAD_APP = 'SUCCESS_PAGE_DOWNLOAD_APP';
export const SUCCESS_PAGE_DOWNLOAD_APP_GALLERY = 'SUCCESS_PAGE_DOWNLOAD_APP_GALLERY';
export const MESSENGER_LINK_NAME = 'MESSENGER_LINK_NAME';
export const MONEYTRANSFER_AB_TEST = 'MONEYTRANSFER_AB_TEST';
export const COUNTRY_CENTER_MAP = 'COUNTRY_CENTER_MAP';
export const EMAIL = 'EMAIL';
export const BANK_TRANSFER_IN_REPAYMENT = 'BANK_TRANSFER_IN_REPAYMENT';
export const SHIPPING_METHODS_DELIVERY_TIME_NAME = 'SHIPPING_METHODS_DELIVERY_TIME';
export const SHIPPING_METHODS_DELIVERY_TIME_DEFAULT_NAME =
    'SHIPPING_METHODS_DELIVERY_TIME_DEFAULT_NAME';
export const STATIONARY_SHOP = 'STATIONARY_SHOP';
export const BLOG_LINK = 'BLOG_LINK';
export const PAYU_CUSTOM_ICON_NAME = 'PAYU_CUSTOM_ICON';
export const IS_HREFLANG_ALLOWED = 'IS_HREFLANG_ALLOWED';
export const IS_LIVE_CHAT_ALLOWED = 'IS_LIVE_CHAT_ALLOWED';
export const MODAL_PAYPO_IFRAME_SRC = 'MODAL_PAYPO_IFRAME_SRC';
export const IS_MARKETPLACE_ENABLED = 'IS_MARKETPLACE_ENABLED';
export const LEGAL_INFORMATION_FIRST = 'LEGAL_INFORMATION_FIRST';
export const LEGAL_INFORMATION_SECOND = 'LEGAL_INFORMATION_SECOND';
export const IS_SPONSORED_SLIDER_ENABLED = 'IS_SPONSORED_SLIDER_ENABLED';
export const COMPLAINTS_STATIC_PAGE_PATH = 'COMPLAINTS_STATIC_PAGE_PATH';
export const IS_CUT_OFF_THE_CENTS_ENABLED = 'IS_CUT_OFF_THE_CENTS_ENABLED';
export const POSTCODE_VALIDATION_RULE_NAME = 'POSTCODE_VALIDATION_RULE';
export const NIP_VALIDATION_RULE_NAME = 'NIP_VALIDATION_RULE';
export const DAYS_FOR_RETURNS = 'DAYS_FOR_RETURNS';
export const STANDARD_DAYS_FOR_RETURNS = 'STANDARD_DAYS_FOR_RETURNS';
export const MODIVO_CLUB_DAYS_FOR_RETURNS = 'MODIVO_CLUB_DAYS_FOR_RETURNS';
export const IS_CURRENCY_BEFORE_ENABLED = 'IS_CURRENCY_BEFORE_ENABLED';

export const SHIPPING_METHODS_DELIVERY_TIME = {
    MIN_NAME: 'SHIPPING_METHODS_DELIVERY_TIME_MIN',
    MAX_NAME: 'SHIPPING_METHODS_DELIVERY_TIME_MAX',
};

export const DELIVERY_TIME_TEST_ENABLED = 'DELIVERY_TIME_TEST_ENABLED';
export const KLARNA_WIDGET_CLIENT_ID = 'KLARNA_WIDGET_CLIENT_ID';
export const IS_OMNIBUS_STRICT_ENABLED = 'IS_OMNIBUS_STRICT_ENABLED';
export const OMNIBUS_FORBIDDEN_BADGES = 'OMNIBUS_FORBIDDEN_BADGES';
export const IS_REGULAR_PRICE_ENABLED = 'IS_REGULAR_PRICE_ENABLED';
export const IS_GOOD_PRICE_ENABLED = 'IS_GOOD_PRICE_ENABLED';
export const DSA_LINK = 'DSA_LINK';
export const IS_NAME_HINT_VISIBLE = 'IS_NAME_HINT_VISIBLE';
export const IS_OUTLET_ENABLED = 'IS_OUTLET_ENABLED';
export const ROOT_CATEGORY_SLUG = 'ROOT_CATEGORY_SLUG';
export const DAYS_UNTIL_404_FOR_OUT_OF_STOCK = 'DAYS_UNTIL_404_FOR_OUT_OF_STOCK';
export const SEARCH_ENGINE = 'SEARCH_ENGINE';
export const USE_SEARCH_ENGINE_SPECIFIC_AUTOCOMPLETE = 'USE_SEARCH_ENGINE_SPECIFIC_AUTOCOMPLETE';
export const PHONE_NUMBER_PREFIX = 'PHONE_NUMBER_PREFIX';

export const LOCALE_CONFIG_KEYS_MAP = [
    BRAND_LOGO,
    BRAND_SITE_NAME,
    EOB_DOMAIN_NAME,
    LOGO_EOB_NAME,
    MOBILE_APP_LINK,
    FOOTER_DOWNLOAD_APP,
    FOOTER_DOWNLOAD_APP_GALLERY,
    SUCCESS_PAGE_DOWNLOAD_APP,
    SUCCESS_PAGE_DOWNLOAD_APP_GALLERY,
    MESSENGER_LINK_NAME,
    MONEYTRANSFER_AB_TEST,
    COUNTRY_CENTER_MAP,
    EMAIL,
    BANK_TRANSFER_IN_REPAYMENT,
    SHIPPING_METHODS_DELIVERY_TIME_NAME,
    STATIONARY_SHOP,
    BLOG_LINK,
    PAYU_CUSTOM_ICON_NAME,
    IS_LIVE_CHAT_ALLOWED,
    MODAL_PAYPO_IFRAME_SRC,
    DELIVERY_TIME_TEST_ENABLED,
    IS_MARKETPLACE_ENABLED,
    LEGAL_INFORMATION_FIRST,
    LEGAL_INFORMATION_SECOND,
    IS_SPONSORED_SLIDER_ENABLED,
    COMPLAINTS_STATIC_PAGE_PATH,
    KLARNA_WIDGET_CLIENT_ID,
    IS_OMNIBUS_STRICT_ENABLED,
    OMNIBUS_FORBIDDEN_BADGES,
    IS_REGULAR_PRICE_ENABLED,
    IS_CUT_OFF_THE_CENTS_ENABLED,
    POSTCODE_VALIDATION_RULE_NAME,
    NIP_VALIDATION_RULE_NAME,
    DAYS_FOR_RETURNS,
    STANDARD_DAYS_FOR_RETURNS,
    MODIVO_CLUB_DAYS_FOR_RETURNS,
    DSA_LINK,
    IS_NAME_HINT_VISIBLE,
    IS_CURRENCY_BEFORE_ENABLED,
    IS_OUTLET_ENABLED,
    ROOT_CATEGORY_SLUG,
    DAYS_UNTIL_404_FOR_OUT_OF_STOCK,
    PHONE_NUMBER_PREFIX,
];

export const COMMON_LOCALE_CONFIG_KEYS_MAP = [IS_HREFLANG_ALLOWED];

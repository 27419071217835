import { MODIVO_CLUB_STATUSES } from '@modivo-club/types/statuses';

export default {
    isModivoClubMember: state => {
        return !!state.membershipStatus;
    },

    isModivoClubGoldMember: state => {
        return state.membershipStatus === MODIVO_CLUB_STATUSES.GOLD;
    },

    isModivoClubStandardMember: state => {
        return state.membershipStatus === MODIVO_CLUB_STATUSES.STANDARD;
    },

    cashbackTotalFundsAmount: state => {
        return state.cashbackCardData.balance;
    },

    isCashbackAvailable: state => {
        return state.cashbackCardData.balance > 0;
    },
};

import { createNamespacedHelpers } from 'vuex';

import { GUEST_CART_ID_KEY, USER_CART_ID_KEY } from '@configs/storage';

import { isModivoClubTestEnabled } from '@modivo-club-assets/modivo-club';

const { mapActions: mapCustomerActions, mapGetters: mapCustomerGetters } = createNamespacedHelpers(
    'customer'
);

const { mapActions: mapModivoClubActions } = createNamespacedHelpers('modivoClub');

const { mapActions: mapCartActions, mapGetters: mapCartGetters } = createNamespacedHelpers('cart');

export default {
    computed: {
        ...mapCustomerGetters(['isLoggedIn']),
        ...mapCartGetters(['cartFetched']),
    },

    async mounted() {
        if (!this.isLoggedIn) {
            await this.getCustomerData();
        }

        if (this.isLoggedIn && isModivoClubTestEnabled(this.$abTests)) {
            await this.handleFetchMembershipAndWallet();
        }

        const storageCartKey = this.isLoggedIn ? USER_CART_ID_KEY : GUEST_CART_ID_KEY;
        const cart = this.$storage.getItem(storageCartKey);

        if (cart) {
            const cartId = cart.id;

            this.setCartId(cartId);

            if (!this.cartFetched) {
                await this.getCart({ cartId });
            }
        }

        if (!this.isLoggedIn) {
            this.initLocalWishlist();
        }

        this.setFetchingCustomerInProgressFlag(false);
    },

    methods: {
        ...mapCustomerActions([
            'getCustomerData',
            'setFetchingCustomerInProgressFlag',
            'initLocalWishlist',
        ]),
        ...mapCartActions(['setCartId', 'getCart']),
        ...mapModivoClubActions(['handleFetchMembershipAndWallet']),
    },
};

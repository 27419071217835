export const CREATED_HTTP_CODE = 201;
export const NO_CONTENT_HTTP_CODE = 204;
export const BAD_REQUEST_HTTP_CODE = 400;
export const NOT_FOUND_HTTP_CODE = 404;
export const REQUEST_TIMEOUT_HTTP_CODE = 408;
export const CONFLICT_HTTP_CODE = 409;
export const GONE_HTTP_CODE = 410;
export const UNPROCESSABLE_CONTENT = 422;
export const INTERNAL_SERVER_ERROR_HTTP_CODE = 500;
export const UNAUTHORIZED_CODE = 401;

export const MODAL_NEWSLETTER_NAME = 'modal_newsletter';
export const MODAL_HOW_OPINIONS_WORK_NAME = 'modal_how_opinions_work';
export const MODAL_CUSTOMER_ACCOUNT_NAME = 'modal_customer_account_name';
export const MODAL_MAGIC_LINK_AUTH = 'modal_magic_link_auth';
export const MODAL_AFTER_REGISTRATION_BY_SOCIAL_MEDIA = 'modal_after_registration_by_social_media';
export const MODAL_ACCOUNT_VERIFICATION = 'modal_account_verification';
export const MODAL_ADVERTISEMENT_INFORMATION = 'modal_advertisement_information';
export const MODAL_NEWSLETTER_CONFIRMATION = 'modal_newsletter_confirmation';
export const MODAL_SIMILAR_PRODUCTS_VS = 'modal_similar_products_vs';
export const MODAL_HEADER_MENU = 'header-menu';
export const MODAL_MODIVO_CLUB_REGISTRATION = 'modivo_club_registration';
export const MODAL_MODIVO_CLUB_REGISTRATION_STEP = 'modivo_club_registration_step';

export const MODAL_TYPE = 'modal_type';
export const SHOW_WISHLIST_LOGIN_HINT = 'show_wishlist_login_hint';
export const MODAL_GOOGLE_PAYMENT = 'modal_google_payment';
export const SHOULD_VALIDATE_ON_OPEN = 'should_validate_on_open';
export const SHOULD_OPEN_MODAL_ON_CLICK = 'should_open_modal_on_click';
export const EMAIL_VALUE = 'email_value';
export const MODAL_NEWSLETTER_RELEASER = 'modal_newsletter_releaser';
export const MODAL_ACCOUNT_VERIFICATION_TYPE = 'modal_account_verification_type';
export const GDPR_LAYOUT_HANDLE = 'gdpr_layout_handle';
export const SHOULD_REDIRECT_TO_CHECKOUT_ON_CLOSE = 'should_redirect_to_checkout_on_close';
export const SHOW_REDIRECT_TO_CHECKOUT_AS_GUEST = 'show_redirect_to_checkout_as_guest';

export const NEWSLETTER_RELEASER_FOOTER = 'footer';
export const NEWSLETTER_RELEASER_AUTO = 'auto';
export const NEWSLETTER_RELEASER_HEADER = 'header';
export const NEWSLETTER_RELEASER_WISHLIST_PRODUCT = 'wishlist_product';
export const NEWSLETTER_RELEASER_WISHLIST = 'wishlist';
export const NEWSLETTER_RELEASER_WISHLIST_HOVER = 'wishlist_hover';
export const NEWSLETTER_RELEASER_WISHLIST_LISTING = 'wishlist_listing';

export const NEWSLETTER_TOOLTIP = 'newsletter_tooltip';
export const NEWSLETTER_TOOLTIP_RELEASER = 'newsletter_tooltip_releaser';

<template>
    <div class="footer-content">
        <NewsletterFooterSectionWrapper
            v-if="isPageWithNewsletter && !isModivoClubEnabled"
            class="newsletter-section"
        />
        <NewsletterBanner v-else class="newsletter-section" />
        <Divider :variant="DIVIDER_VARIANT" />

        <ContainerContent v-if="Object.keys(config).length" class="footer-container">
            <div class="footer-wrapper">
                <LazyHydrate :when-visible="true">
                    <BrandLogo class="brand-logo" />
                </LazyHydrate>
                <SocialIcons
                    :facebook-url="config.configData.facebookUrl"
                    :instagram-url="config.configData.instagramUrl"
                    :youtube-url="config.configData.youtubeUrl"
                    :tiktok-url="config.configData.tiktokUrl"
                    :pinterest-url="config.configData.pinterestUrl"
                    class="social"
                />
                <ButtonComponent
                    :variant="BUTTON_COMPONENT_VARIANT"
                    @click="openLangVersionModal()"
                >
                    <template #icon>
                        <Icon :icon="Globe" />
                    </template>
                    {{ $t('Change the country') }}: {{ currentLocale.localeOriginName }} ({{
                        currentLocale.label
                    }})
                </ButtonComponent>
                <LangVersionModal
                    v-if="isLangVersionModalOpen"
                    :is-open="isLangVersionModalOpen"
                    @close="closeLangVersionModal()"
                />
            </div>

            <LazyHydrate v-if="!isMobile" :when-visible="true">
                <div class="menu-desktop">
                    <FooterMenu :menu-sections="config.footerSections" />
                </div>
            </LazyHydrate>

            <div class="menu-with-apps-wrapper">
                <LazyHydrate v-if="!isMobileEvaluated || isMobile" :when-visible="true">
                    <div class="menu-mobile">
                        <FooterMenuMobile :menu-sections="config.footerSections" />
                    </div>
                </LazyHydrate>
                <LazyHydrate :when-visible="true">
                    <MobileAppsLinksProvider
                        v-if="showMobileAppInfoForStoreCodes"
                        #default="{ footerAppUrl, footerAppGalleryUrl }"
                    >
                        <DownloadApp
                            v-if="footerAppUrl || footerAppGalleryUrl"
                            :play-url="footerAppUrl"
                            :app-store-url="footerAppUrl"
                            :app-gallery-url="footerAppGalleryUrl"
                            :is-mobile="isMobile"
                            class="download-app-wrapper"
                        />
                    </MobileAppsLinksProvider>
                </LazyHydrate>
            </div>
        </ContainerContent>

        <ContainerContent
            class="bottom-wrapper"
            :class="{ 'has-legal-info-links': hasLegalInfoLinks }"
        >
            <FooterBottom :base-footer-sections="config.baseFooterSections" />
            <LegalInformationLinks v-if="hasLegalInfoLinks" class="links" />
        </ContainerContent>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import { PRODUCT_ERROR_DOMAIN } from '@errors/feature-domain-names';

import { LEGAL_INFORMATION_FIRST, LEGAL_INFORMATION_SECOND } from '@localeConfig/keys';

import { MAX_COLUMNS_TO_DISPLAY, PSB_FOOTER_CONFIGURATION } from '@configs/footer';

import { CMS_FOOTER_CONFIGURATION } from '@modules/page-builder/page-builder.config';

import { CATALOG_PAGE_NAME, SEARCH_RESULTS_PAGE_NAME } from '@search/routing/names';
import { PRODUCT_PAGE_NAME } from '@product/routing/names';

import { HOME_PAGE_NAME, MAIN_CATEGORY_PAGE_NAME } from '@router/names';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import LazyHydrate from '@assets/vue-lazy-hydration/LazyHydrate';

import { isModivoClubTestEnabled } from '@modivo-club-assets/modivo-club';

import showMobileAppInfo from '@mixins/showMobileAppInfo';
import locales from '@mixins/locales';

import MobileAppsLinksProvider from '@functionals/MobileAppsLinksProvider/MobileAppsLinksProvider';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import SocialIcons from '@molecules/SocialIcons/SocialIcons';
import DownloadApp from '@molecules/DownloadApp/DownloadApp';
import FooterBottom from '@molecules/FooterBottom/FooterBottom';

import FooterMenu from '@organisms/FooterMenu/FooterMenu';
import FooterMenuMobile from '@organisms/FooterMenuMobile/FooterMenuMobile';
import NewsletterBanner from '@organisms/NewsletterBanner/NewsletterBanner';

import NewsletterFooterSectionWrapper from '@molecules/NewsletterFooterSectionWrapper/NewsletterFooterSectionWrapper';
import LegalInformationLinks from '@molecules/LegalInformationLinks/LegalInformationLinks';
import BrandLogo from '@organisms/FooterContent/BrandLogo';

import { Globe } from '@modivo-ui/icons/v2/other';

import { Divider, DIVIDER_VARIANTS } from '@modivo-ui/components/Divider/v2';
import { Icon } from '@modivo-ui/components/Icon/v1';
import {
    ButtonComponent,
    BUTTON_COMPONENT_VARIANTS,
} from '@modivo-ui/components/ButtonComponent/v1';

const { mapGetters: mapCmsBlockGetters } = createNamespacedHelpers('cmsBlock');

export default {
    name: 'FooterContent',

    components: {
        NewsletterFooterSectionWrapper,
        FooterMenu,
        ContainerContent,
        MobileAppsLinksProvider,
        DownloadApp,
        SocialIcons,
        LegalInformationLinks,
        LazyHydrate,
        Divider,
        Icon,
        ButtonComponent,
        FooterMenuMobile,
        NewsletterBanner,
        BrandLogo,
        FooterBottom,
        LangVersionModal: () => ({
            component: import(
                /* webpackChunkName: "lang-version-modal" */
                '@molecules/LangVersionModal/LangVersionModal'
            ),
            delay: 0,
        }),
    },

    mixins: [showMobileAppInfo, locales],

    data() {
        return {
            isLangVersionModalOpen: false,
        };
    },

    computed: {
        ...mapCmsBlockGetters(['pageBuilderBlockById']),
        ...mapState(['isMobile', 'isMobileEvaluated']),

        footerDataCmsPage() {
            return this.pageBuilderBlockById(PSB_FOOTER_CONFIGURATION);
        },

        isPageWithNewsletter() {
            return [
                CATALOG_PAGE_NAME,
                SEARCH_RESULTS_PAGE_NAME,
                HOME_PAGE_NAME,
                MAIN_CATEGORY_PAGE_NAME,
                PRODUCT_PAGE_NAME,
            ].includes(this.$route.name);
        },

        config() {
            try {
                const cmsFooterConfiguration = this.footerDataCmsPage.find(
                    ({ name }) => name === CMS_FOOTER_CONFIGURATION
                );

                if (!cmsFooterConfiguration) {
                    return {};
                }

                const {
                    slots: { default: rawFooterSections },
                } = cmsFooterConfiguration;

                const footerSections = rawFooterSections
                    .slice(0, MAX_COLUMNS_TO_DISPLAY)
                    .map(section => {
                        const {
                            props: { heading: sectionName },
                            slots: { default: footerLinks },
                        } = section;

                        const links = footerLinks.map(link => link.props);

                        return { sectionName, links: this.hideLoyaltyClubLink(links) };
                    });

                const baseFooterSections = rawFooterSections.slice(3, 4).map(section => {
                    const {
                        slots: { default: baseFooterLinks },
                    } = section;

                    const links = baseFooterLinks.map(link => link.props);

                    return { links };
                });

                return {
                    configData: cmsFooterConfiguration?.props || {},
                    footerSections,
                    baseFooterSections,
                };
            } catch (err) {
                this.$errorHandler.captureDomainError(PRODUCT_ERROR_DOMAIN, err, {
                    [ERROR_ACTION_TAG_NAME]: 'Footer.config',
                });

                return {};
            }
        },

        hasLegalInfoLinks() {
            return (
                [
                    this.$configProvider.getConfig(LEGAL_INFORMATION_FIRST),
                    this.$configProvider.getConfig(LEGAL_INFORMATION_SECOND),
                ].filter(legalInformation => !!legalInformation).length > 0
            );
        },

        isModivoClubEnabled() {
            return isModivoClubTestEnabled(this.$abTests);
        },
    },

    beforeCreate() {
        this.DIVIDER_VARIANT = DIVIDER_VARIANTS.S;
        this.Globe = Globe;
        this.BUTTON_COMPONENT_VARIANT = BUTTON_COMPONENT_VARIANTS.PRIMARY;
    },

    methods: {
        openLangVersionModal() {
            this.isLangVersionModalOpen = true;
        },

        closeLangVersionModal() {
            this.isLangVersionModalOpen = false;
        },

        hideLoyaltyClubLink(links) {
            if (this.$loyaltyClub.isActive) {
                return links;
            }

            return links.filter(link => !this.$loyaltyClub.isPagePath(link.path));
        },
    },
};
</script>

<style lang="scss" scoped>
.footer-content {
    @apply pb-ui-6;
    @apply bg-ds-container-secondary;

    .footer-wrapper {
        @apply text-center;
        @apply py-ui-9;
    }

    .footer-container {
        @apply flex-col flex-nowrap justify-between;
    }

    .newsletter-section {
        @apply py-ui-7;
        @apply flex justify-center;
    }

    .brand-logo {
        @apply pt-ui-1;
        @apply mx-ui-auto;
        @apply max-w-[300px];
    }

    .menu-desktop {
        @apply hidden;
    }

    .social {
        @apply py-ui-4;
    }
    .download-app-wrapper {
        @apply my-ui-6;
    }

    .bottom-wrapper {
        @apply flex;
        @apply gap-y-ui-4;
    }

    .bottom-wrapper {
        @apply items-center;
    }

    .links {
        @apply mb-3;
    }

    @screen lg {
        @apply pb-ui-7;

        .newsletter-section {
            @apply py-ui-8;
        }

        .footer-container {
            @apply flex-row;
            @apply gap-ui-4;
            @apply py-ui-10;
        }

        .footer-wrapper {
            @apply py-ui-0;
            @apply text-left;
        }

        .brand-logo {
            @apply m-ui-0;
            @apply max-w-ui-percent-100;
        }

        .menu-mobile {
            @apply hidden;
        }

        .menu-desktop {
            @apply block;
            @apply w-3/5;

            &:deep() .expand-collapse {
                @apply w-ui-percent-100;
            }
        }

        .menu-with-apps-wrapper,
        .footer-wrapper {
            @apply w-1/5;
            @apply text-left;
        }

        .social {
            @apply py-ui-6;
        }

        .download-app-wrapper {
            @apply my-ui-0;
        }

        .links {
            @apply mb-0;
        }

        .bottom-wrapper {
            @apply flex justify-between;
        }
    }
}
</style>
